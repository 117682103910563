.SmartLinks-table-image {
    width: fit-content;
    height: auto;
    margin-right: 24px;
    max-width: 120px;
}

.SmartLinks-table-view h5 {
    font-size: 38px;
    font-weight: 600;
    line-height: 26px;
    text-align: center;
}

.SmartLinks-table-view h5 span {
    font-size: 24px;
    font-weight: 600;
}

.SmartLinks-table-view h6 {
    font-size: 20px;
    font-weight: 600;
    padding-left: 16px;
    margin-bottom: 12px;
    width: max-content;
}

span.clr-theme {
    color: #7367f0;
}

button.clr-theme {
    color: #7367f0;
    font-size: 22px;
    background-color: transparent;
    border: none;
    padding: 0px;
    margin: 0px;
}

.Smartlink01 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
}

.SmartLink-list {
    display: flex;
    margin-left: 24px;
    align-items: center;
}

.Qr-table-image {
    width: 120px;
    height: auto;
    margin-right: 24px;
}

.brand-table-image {
    min-width: 170px;
    max-width: 170px;
    height: auto;
    margin-right: 24px;
}

.views01 {
    display: flex;
    align-items: center;
}

.views04 {
    display: flex;
    align-items: center;
}


.VanityVisits {
    display: none;
}

.VanityVisits h4 {
    font-size: 18px;
}

.VanityVisits p {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 0px;
    margin-right: 16px;
    color: #6D687A;
}

.button-group1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
}

.smartlink-view-card {
    background-color: #fff;
    padding: 1.4rem;
    margin-top: 14px;
}

.smartlink-mobile-show {
    padding: 0px !important;
}

.brand-link h3 {
    font-size: 22px;
    font-weight: 700;
}

.brand-link h4 {
    font-size: 24px;
    font-weight: 700;
    color: #7367f0;
}

.brand-link-item {
    align-items: baseline;
}

.smartlink-count-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 15px 0px;
}

.long-link-url svg {
    font-weight: 900;
    width: 24px;
    height: 24px;
}

.long-link-url {
    display: flex;
    align-items: center;
}

.long-link-url h5 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0px;
    padding: 0 0.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.qr-code-item {
    padding: 2rem 0;
}

.mid-section-title {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.orb-typography {
    color: #2c2c2c;
    font-weight: 700;
    line-height: normal;
    font-size: 22px;
}

.search {
    width: 100%;
    position: relative;
    display: flex;
}

.searchTerm {
    width: 100%;
    border: 2px solid #c7c7c7;
    padding: 5px 12px;
    height: 45px;
    border-radius: 20px;
    outline: none;
    color: #9DBFAF;
    background: #fff;
}

.searchTerm:focus {
    color: #00B4CC;
}

.searchButton {
    width: 45px;
    height: 45px;
    border: 1px solid #7367f0;
    background: #7367f0;
    text-align: center;
    color: #fff;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
    font-size: 20px;
}

.wrap {
    margin: 20px 0px;
    width: 25%;
}

.popup-image {
    width: 100%;
    height: auto;
}

.qr-code-modal .modal-dialog.modal-dialog-centered {
    min-width: 500px;
}

.qr-btn-list {
    margin: 14px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.btn.qr-modal-btn {
    background-color: #7367f0 !important;
    color: #fff;
    width: 100%;
    height: 50px;
}

.campagin-link {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.campaign-action {
    margin: 30px 0px 0px 0px !important;
}

.domain-box {
    display: none;
}

.domain-box.active {
    display: block;
}

.url-type-deisgn1,
.url-type-deisgn {
    border-radius: 6px;
    padding: 10px;
    background: transparent;
}

.url-type-deisgn1.active,
.url-type-deisgn.active {
    border-radius: 6px;
    padding: 10px;
    background: #f1f0ff;
}

.url-type-deisgn1.active .form-check-label,
.url-type-deisgn.active .form-check-label {
    color: #7367f0;
}

.form-inline-btn {
    width: 140px;
    margin-left: 8px;
    /* background-image: var(--btncolor); */
    border-radius: 4px;
    font-size: 14px;
    border: none;
}

.image-preview-edit {
    /* display: grid; */
    width: 50%;
}

.form-inline-btn2 {
    width: 145px;
    margin-left: 8px;
    color: #fff !important;
}

select.custom-drop-downbtn {
    height: -moz-fit-content;
    height: fit-content;
    width: 200px;
    border-radius: 0;
    border: none;
    color: #000;
}

select.custom-drop-downbtn:focus {
    box-shadow: none;
}

.image-preview-edit2 {
    width: 10%;
}

.smartlink-mobile-show {
    display: none;
}

/* .smartlink-tag h4:first-child {
    margin-left: 0px;
} */




@media (max-width: 1199.99px) {
    .SmartLinks-table-image {
        width: 100px;
        margin-right: 10px;
    }

    .SmartLink-list {
        margin-left: 10px;
    }

    .SmartLinks-table-view h5 {
        font-size: 32px;
        line-height: 20px;
    }

    .SmartLinks-table-view h5 span {
        font-size: 21px;
    }

    .SmartLinks-table-view h6 {
        font-size: 18px;
        margin-bottom: 6px;
    }

    .wrap {
        margin: 20px 0px;
        width: 50%;
    }
}

@media (max-width: 991.99px) {
    .views01 {
        display: none;
    }

    .SmartLinks-table-image {
        width: 120px;
        margin-right: 20px;
    }
}

@media (max-width: 767px) {
    .SmartLinks-table-image {
        width: 140px;
        margin-right: 10px;
    }

    .VanityVisits div.d-flex {
        flex-direction: column;
    }

    /* .button-group1 .new-M {
        margin-top: 10px;
    } */

    .modal-dialog.modal-dialog-centered {
        min-width: 544px;
    }

    .mobile-view-hide {
        display: none;
    }

    .smartlink-mobile-show {
        display: block;
    }

    form.search-form {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .card-content-header-right .add-user-btn {
        padding: 0px 13px;
        margin: 0px 0px;
        display: flex;
        align-items: center;
    }

    .qr-code-modal .modal-dialog.modal-dialog-centered {
        min-width: auto;
    }

    .wrap {
        margin: 20px 0px 0px;
        width: 100%;
    }

    .campaign-table-image {
        width: 40px;
        height: auto;
        margin-right: 0px;
    }

    .campaign-table-image2 {
        width: 40px;
        height: auto;
        margin-right: 0px;
    }

    .brand-link h4 {
        font-size: 18px;
    }
}

@media (max-width: 374px) {
    .main-card-title {
        font-size: 18px;
    }

    .main-card-title svg {
        width: 18px;
        height: 18px;
    }

    .card-content-header-right .add-user-btn {
        padding: 0px 8px;
        margin: 0px 0px;
        display: flex;
        align-items: center;
        font-size: 14px;
        height: 40px;
    }

    .campaign-table-image {
        width: 30px;
        height: auto;
        margin-right: 0px;
    }
}

@media (min-width: 768px) and (max-width: 1200px) {
    .VanityVisits {
        display: block;
    }

    .views01 {
        display: none;
    }
}

@media (min-width: 768px) and (max-width: 1920px) {
    .smartlink-mobile-show {
        display: none;
    }
}

@media (min-width: 320px) and (max-width: 340px) {
    .smartlink-view-card {
        background-color: #fff;
        padding: 1rem;
        margin-top: 14px;
    }
}




input[type="search"] {
    -webkit-appearance: none !important;
    background-clip: padding-box;
    background-color: white;
    vertical-align: middle;
    border-radius: 0.25rem;
    border: 1px solid #e0e0e5;
    font-size: 1rem;
    width: 100%;
    line-height: 2;
    padding: 0.375rem 1.25rem;
    -webkit-transition: border-color 0.2s;
    -moz-transition: border-color 0.2s;
    transition: border-color 0.2s;
}

input[type="search"]:focus {
    transition: all 0.5s;
    /* box-shadow: 0 0 40px #f9d442b9; */
    border-color: #7367f0;
    outline: none;
}

form.search-form {
    display: flex;
    justify-content: center;
}

label {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: auto;
    align-self: center;
    margin-bottom: 0;
}

input.search-field {
    color: #000;
}

input.search-field {
    margin-bottom: 0;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: auto;
    align-self: center;
    height: 42px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

input.search-submit {
    height: 51px;
    margin: 0;
    padding: 1rem 1.3rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    font-family: "Font Awesome 5 Free";
    font-size: 1rem;
}

.screen-reader-text {
    clip: rect(1px, 1px, 1px, 1px);
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
}

.button {
    display: inline-block;
    font-weight: 600;
    font-size: 15px;
    line-height: 1.15;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    background: #0c15e7;
    color: #ffffff;
    border: 1px solid transparent;
    vertical-align: middle;
    text-shadow: none;
    transition: all 0.2s;
    padding: 0px 20px;
}

.button:hover,
.button:active,
.button:focus {
    cursor: pointer;
    background: #0c15e7;
    color: #ffffff;
    outline: 0;
}

.download-qr-btn {
    padding: 8px 16px !important;
}

.long-url-tooltip {
    display: none;
    position: absolute;
    background: #f9e5f5;
    color: #cb3ed8;
    padding: 10px;
    border-radius: 5px;
}

.long-url-tooltip p {
    margin-bottom: 0;
}

.smartlink-destination a {
    position: relative;
}

.smartlink-destination a:hover + .long-url-tooltip {
    display: block;
}

.qr-code-similer {
    width: 35%;
}

.profile-user-image img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.profile-user-image {
    padding-right: 10px;
    margin-right: 10px;
    position: relative;
}

.profile-user-image::after {
    content: "";
    position: absolute;
    top: 5px;
    right: 0;
    width: 2px;
    height: 80%;
    background: #e6e6e6;
}

.user-details h6 {
    margin-bottom: 4px;
}

.user-details span {
    font-size: 14px;
}

.details-box-smartlink {
    margin-bottom: 16px;
}