.pd-26 {
    padding-bottom: 26px;
}

.dashboard-card {
    border: none;
    background: #fff;
    padding: 24px 16px;
    height: 100%;
    box-shadow: 0px 4px 18px rgba(75, 70, 92, 0.1);
    border-radius: 6px;
}

.plan-card {
    border: none;
    padding: 0px 14px;
    box-shadow: 0px 4px 18px rgba(75, 70, 92, 0.1);
    border-radius: 0px;
    position: fixed;
    top: 0px;
    bottom: 0;
    position: sticky;
    width: 100%;
    z-index: 999;
    height: 50px;
    align-items: center;
    justify-content: center;
    background-color: #fff7df;
}

.plan-title {
    color: #e6ad00;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
}

.plan-title svg {
    width: 30px;
    height: 30px;
}

button.btn.plan-price-btn {
    background: #3a933a!important;
    color: #fff !important;
    border-radius: 18px;
    padding: 7px 30px;
    border: 1px solid #3a933a!important;
    font-size: 14px;
    font-weight: 600;
}

button.btn.plan-price-btn a {
    color: #fff !important;
}

.main-area {
    /* margin-top: 50px; */
    height: calc(100% - 50px);
}

.card-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.card-left-content p {
    font-size: 15px;
    margin-bottom: 6px;
    color: #5D586C;
    font-weight: 500;
}

.card-left-content h5 {
    font-size: 22px;
    font-weight: 700;
    color: #5D586C;
}

.icon-box {
    background: #F4F3FE;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
}

.icon-box svg {
    width: 28px;
    height: 28px;
    color: #7367F0;
}

.bg-red {
    background: #FDF1F1;
}

.bg-red svg {
    color: #FF5B5B;
}

.bg-green {
    background: #EEFBF3;
}

.bg-green svg {
    color: #00C689;
}

.bg-yellow {
    background: #FFF7F0;
}

.bg-yellow svg {
    color: #FF9F43;
}

.card-header-select {
    margin-left: 20px;
}

.custom-card-header {
    display: flex;
    align-items: self-start;
    justify-content: space-between;
}

.custom-selecter {
    width: 140px;
}

.color-text {
    color: #7367F0;
    font-weight: 700;
}

.total-traffic .total-traffic-number {
    font-size: 38px;
    font-weight: 700;
    color: #5D586C;
}

.total-traffic .total-traffic-text {
    font-size: 15px;
    background-color: #DDF6E8;
    color: #00C689;
    font-weight: 600;
    padding: 5px 10px;
    border-radius: 4px;
}

.total-traffic-text {
    font-size: 18px;
    color: #5D586C;
    font-weight: 500;
    width: max-content;
}

.custom-card-body-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
}

.custom-card-footer {
    margin-top: 24px;
    border: 1px solid #DBDADE;
    border-radius: 6px;
    padding: 16px 20px;
}

.card-footer-item-number {
    font-size: 22px;
    color: #5D586C;
    font-weight: 600;
    margin: 4px 0px 10px 0px;
}

.progress {
    height: 6px;
    border-radius: 6px;
}

.card-footer-item-title {
    font-weight: 500;
    color: #5D586C;
}

.smartlist-title {
    font-size: 20px;
    margin-bottom: 10px;
    color: #5D586C;
    font-weight: 600;
}

.smartlist-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0px;
}

.list-item-left-content {
    display: flex;
    align-items: baseline;
}

.list-item-text {
    margin-left: 16px;
}

.list-item-text-title {
    font-size: 18px;
    color: #5D586C;
    font-weight: 600;
}

.list-item-text-subtitle {
    font-size: 15px;
    color: #A5A3AE;
}

.btn-copy svg {
    width: 24px;
    height: 24px;
    color: #767686;
}

.list-item-right-content {
    display: flex;
    align-items: center;
}

svg.recharts-surface {
    width: 100% !important;
}

.recharts-wrapper {
    width: 100% !important;
}

.bg001 .progress-bar {
    background-color: #7367F0;
}

.bg002 .progress-bar {
    background-color: #00CFE8;
}

g.recharts-layer.recharts-cartesian-axis.recharts-yAxis.yAxis,
g.recharts-cartesian-grid,
line.recharts-cartesian-axis-line,
line.recharts-cartesian-axis-tick-line {
    display: none;
}

.form-control {
    color: #5D586C;
}

.list-item-icon img {
    width: 30px;
    height: auto;
}

.total-traffic {
    display: inline-grid;
}

span.visit-detail-label {
    font-size: 14px;
}

span.visit-detail-value {
    font-size: 14px;
}

@media (max-width: 1199.99px) {
    .custom-card-body-inner {
        align-items: start;
        flex-direction: column;
    }
}

@media (max-width: 767px) {
    .ps-0 {
        padding-left: 0 !important;
        padding-right: 0px !important;
    }

    .pe-0 {
        padding-right: 0 !important;
        padding-left: 0px !important;
    }

    .pd-26 {
        padding-bottom: 8px;
    }
}

@media (min-width: 320px) and (max-width: 768px) {
    .card-header-select {
        display: none;
    }

    .dashboard-card-body {
        padding: 0px;
    }

    .custom-card-header {
        display: flex;
        justify-content: space-between;
    }

    .card-header-title .card-title {
        line-height: 1.6;
        font-weight: 500;
        font-size: 1.125rem;
        letter-spacing: 0.15px;
        margin-bottom: 6px;
    }

    .dashboard-card {
        border: none;
        background: #fff;
        padding: 24px 16px;
        margin-bottom: 14px;
    }
}

.positive-color {
    color: #e9faf5;
    background: #ace9c7;
}

.total-traffic .negative-color {
    color: #bb0101;
    background-color: #f8d7d7;
}