.paceloader {
    position: fixed;
    width: 100%;
    height: 100vh;
    background: #ffffff;
    z-index: 99999999999999999999;
    opacity: 0.9;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ring {
    height: 180px;
    width: 180px;
    border: 0px solid #ffffff;
    border-radius: 50%;
    position: absolute;
}

.ring:nth-child(1) {
    border-bottom-width: 8px;
    border-color: #0A07EA;
    animation: rotate1 2s linear infinite;
}

.ring:nth-child(2) {
    border-right-width: 8px;
    border-color: #0A07EA;
    animation: rotate2 2s linear infinite;
}

.ring:nth-child(3) {
    border-top-width: 8px;
    border-color: #0A07EA;
    /* border-image:circular-gradient(45deg, red , yellow); */
    animation: rotate3 2s linear infinite;
}

.ring1 {
    height: 180px;
    width: 180px;
    border: 0px solid #ffffff;
    border-radius: 50%;
    position: absolute;
}

.ring1:nth-child(1) {
    border-bottom-width: 8px;
    border-color: rgb(37, 25, 75);
    animation: rotate1 2s linear infinite;
}

.ring1:nth-child(2) {
    border-right-width: 8px;
    border-color: rgb(37, 25, 75);
    animation: rotate2 2s linear infinite;
}

.ring1:nth-child(3) {
    border-top-width: 8px;
    border-color: rgb(37, 25, 75);
    /* border-image:circular-gradient(45deg, red , yellow); */
    animation: rotate3 2s linear infinite;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999999999999;
}

@keyframes rotate1 {
    0% {
        transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
    }

    100% {
        transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
    }
}

@keyframes rotate2 {
    0% {
        transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
    }

    100% {
        transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
    }
}

@keyframes rotate3 {
    0% {
        transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
    }

    100% {
        transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
    }
}