/* Universal Approval Button Style */
.universal-approval {
    background-color: #28a745; /* Green for Universal Approval */
    color: white;
    border: none;
    margin-right: 10px; /* Add spacing */
}
button.delete-Cancel-bttn {
    background-color: #f64e60  !important;
    border: 1px solid #f64e60  !important;
    border-radius: 6px;
}
button.confirm-Delete-bttn {
    background-color: #00CFE8 !important;
    border: 1px solid #00CFE8 !important;
    border-radius: 6px;
}

/* Approved Icon Styles */
.approved-icon {
    color: white; /* White icon to match button color */
    margin-left: 5px; /* Add space between icon and text */
    font-size: 18px; /* Adjust size */
    margin: initial;
}

/* Rejected Icon Styles */
.rejected-icon {
    color: white; /* White icon for rejected */
    margin-left: 5px; /* Space between icon and text */
    font-size: 18px;
    margin: initial;
}

/* General Button Styling */
.delete-Cancel-bttn {
    background-color: #6c757d; /* Grey for cancel */
    color: white;
    border: none;
}

.confirm-Delete-bttn.Rejected {
    background-color: #dc3545; /* Red for rejected */
}
