body {
    background-color: #F8F7FA;
    color: #5D586C;
    font-family: 'Public Sans', sans-serif;
    overflow-x: hidden;
    font-display: swap;
}

:root {
    --btncolor: linear-gradient(45deg, #0b10e7, #2adf63) !important;
    --btnhovercolor: linear-gradient(45deg, #0b10e7, #2adf63) !important;
    --btncolor2: linear-gradient(90deg, #0e22e4 0.12%, #22a69c 49.44%, #2adc65 100.12%) !important;
}

a {
    text-decoration: none;
}

.sidebar-container {
    /* min-width: 236px; */
    position: fixed;
    height: -webkit-fill-available;
    height: 100%;
}

.main-area .sidebar-container {
    /* min-width: 236px; */
    position: fixed;
    height: -webkit-fill-available;
    height: calc(100vh - 50px);
}

.pro-sidebar {
    width: 242px !important;
}

.main-container {
    margin: 6px 6px 0 250px;
    max-width: calc(100% - 250px);
    width: -webkit-fill-available;
    width: 100%;
}

.custom-pt-20 {
    padding-top: 20px;
}

.row {
    margin: 0px;
}

.bg-yellow-dark {
    background-color: #CFBA00;
}

.bg-green-dark {
    background-color: #7367F0;
}

.bg-red-dark {
    background-color: #FF0000;
}

.bg-blue-dark {
    background-color: #0080FF;
}

.bg-yellow-dark {
    color: #CFBA00;
}

.bg-green-dark {
    background-image: var(--btncolor);
    border: none;
    transition: 0.3s;
}

.bg-red-dark {
    color: #FF0000;
}

.bg-blue-dark {
    color: #0080FF;
}

.text-yellow-dark {
    color: #CFBA00 !important;
}

.text-blue-dark {
    color: #7367F0 !important;
}

.text-red-dark {
    color: #FF0000 !important;
}

.main-container.new-class-name {
    margin: 10px 10px 0px 86px;
    transition: 0.3s;
    max-width: 100%;
}

.Vanity-display-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
}

.Sidebar-Modal-end.lg-width .modal-dialog {
    right: 0;
    position: fixed;
    margin: auto;
    max-width: 84% !important;
    height: 100%;
    width: 100%;
}

.form-check .form-check-input {
    float: left;
    margin-left: -1.2em;
}

@media (max-width: 1199.99px) {
    .main-container {
        margin: 12px 12px 0px 272px;
    }

    .main-container.new-class-name {
        margin: 12px 12px 0px 92px;
    }

    .Vanity-display-flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
    }
}

@media (max-width: 991.98px) {
    .main-container {
        width: -webkit-fill-available;
        margin: 12px 12px 0px 12px;
        max-width: 100%;
    }
}

@media (max-width: 575.98px) {
    .main-container {
        margin: 12px 8px;
    }

    .pnotify.pnotify-fade-normal.pnotify.pnotify-move {
        transition: opacity .25s linear, left .4s ease, top .4s ease, right .4s ease, bottom .4s ease;
        right: 9px !important;
    }
}

@media (max-width: 479.98px) {
    .Vanity-display-flex {
        flex-direction: column;
        align-items: flex-start;
    }

    .Vanity-display-flex .button-group {
        margin-top: 16px;
    }
}




.input-field--input2 {
    color: #273144;
    font-size: 18px;
    outline: none;
    resize: none;
    overflow: hidden;
    height: auto;
    width: 100%;
    border: none;
    margin: 0;
    background-color: initial;
    color: #273144;
    font-weight: 600;
}

.input-field--CUSTOM2 {
    width: 100%;
    border: 1px solid #ced4da;
    border-radius: 0.3rem;
    position: relative;
    cursor: pointer;
    margin-bottom: 1rem;
    padding: 5px;
}

.sc-bZQynM.hkrGSj {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 9999;
}

.paceloader {
    position: fixed;
    width: 100%;
    height: 100vh;
    background: #ffffff;
    z-index: 999;
    opacity: 0.8;
}

.loading {
    position: fixed;
    width: 100%;
    height: 100vh;
    background: #ffffff;
    z-index: 999;
    opacity: 0.8;
}

.jaMWCU {
    display: flex !important;
    position: relative;
    align-items: center;
    height: 100vh !important;
    justify-content: center;
    color: #FF0000;
    width: auto !important;
}

.jaMWCU div::after {
    content: " ";
    display: block;
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #FF0000 !important;
    margin: -3px 0px 0px -3px;
}

.form-switch {
    padding-left: 1.5em !important;
}

.mini-page-list-inner-item.flex-template-list .mini-page-list-inner-item-content {
    padding: 20px 14px;
}


/* .form-group {
    margin-bottom: 16px;
    position: relative;
}

input {
    position: relative;
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, .37);
    border-radius: 4px;
    background-color: transparent;
    margin: 0px auto;
    padding: 6px 4px 4px 14px;
    height: 40px;
    outline: none !important;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    transition: all .2s ease-in-out;
}

label {
    position: absolute;
    top: 20px;
    left: 12px;
    text-align: left;
    display: inline-block;
    padding: 0 4px;
    height: 14px;
    line-height: 14px;
    font-size: 14px;
    font-weight: 400;
    background: #fff;
    color: rgba(0, 0, 0, 0.5);
    margin: 0px auto;
    cursor: text;
    transition: all .15s ease-in-out;
}

input:hover,
input:focus {
    border: 1px solid #000;
}

input:valid+label,
input:focus+label {
    top: -6px;
    color: #000;
    font-weight: bold;
}

.gl-form-asterisk {
    background-color: inherit;
    color: #e32b2b;
    padding: 0;
    padding-left: 3px;

}

.gl-form-asterisk:after {
    content: "*";
} */



/* .pnotify {
    position: absolute !important;
} */

/* .pnotify-container.brighttheme-container.brighttheme-success.pnotify-shadow {
    display: flex;
} */




[aria-label][tooltip-position] {
    position: relative;
    letter-spacing: 0.1rem;
}

[aria-label][tooltip-position]::before,
[aria-label][tooltip-position]::after {
    --scale: 0;
    position: absolute;
    font-size: 14px;
    transition: transform 100ms;
    transition-timing-function: linear;
}

[aria-label][tooltip-position]:hover::before,
[aria-label][tooltip-position]:hover::after {
    --scale: 1;
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.45, 1.93);
}

[aria-label][tooltip-position]::before {
    content: attr(aria-label);
    background-color: var(--tooltip-color);
    padding: 0.5em;
    border-radius: 0.3em;
    width: max-content;
    max-width: 200px;
    text-align: center;
    transform: scale(0);
}

[aria-label][tooltip-position]::after {
    content: "";
    border: var(--arrow-size) solid transparent;
}

[aria-label][tooltip-position]::before,
[aria-label][tooltip-position]::after {
    --tooltip-color: rgb(162, 0, 255);
    --arrow-size: 0.5rem;
    --scale: 0;

    z-index: 1;
    font-size: 14px;
    transform: translate(var(--translate-x), var(--translate-y)) scale(var(--scale));
}


/** Top */

[aria-label][tooltip-position="top"]::before {
    position: absolute;
    --translate-x: -50%;
    --translate-y: calc(-100% - var(--arrow-size));
    top: 0px;
    left: 50%;
    transform-origin: bottom center;
}

[aria-label][tooltip-position="top"]::after {
    --translate-x: -50%;
    --translate-y: calc(-1 * var(--arrow-size));
    top: 0px;
    left: 50%;
    border-top-color: var(--tooltip-color);
    transform-origin: bottom center;
}



/** Top */

[aria-label][tooltip-position="top"]::before {
    position: absolute;
    --translate-x: -50%;
    --translate-y: calc(-100% - var(--arrow-size));
    top: 0px;
    left: 50%;
    transform-origin: bottom center;
}

[aria-label][tooltip-position="top"]::after {
    --translate-x: -50%;
    --translate-y: calc(-1 * var(--arrow-size));
    top: 0px;
    left: 50%;
    border-top-color: var(--tooltip-color);
    transform-origin: bottom center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* .main-area {
    position: relative;
}

.main-area::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
} */

.bg-hide-hero img {
    filter: blur(8px);
    height: 100vh;
}

.blocked-content {
    background: #fff;
    padding: 16px;
    border-radius: 5px;
}

.Sidebar-Modal-end {
    z-index: 9999999999;
}

body>.pnotify.pnotify-positioned {
    position: fixed;
    z-index: 99999999999 !important;
}

body #canny-changelog-iframe {
    animation: none !important;
    background: white;
    border: 0px;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 10px;
    display: none;
    overflow: hidden;
    position: fixed !important;
    width: 850px !important;
    z-index: 999999999;
    height: 500px !important;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%);
}


.did-floating-input.dark-theam-input:-webkit-autofill,
.did-floating-input.dark-theam-input:-webkit-autofill:hover,
.did-floating-input.dark-theam-input:-webkit-autofill:focus {
    -webkit-text-fill-color: #ffffff;
    -webkit-box-shadow: 0 0 0px 1000px #192337 inset;
    transition: background-color 5000s ease-in-out 0s;
}

.pro-sidebar-layout::-webkit-scrollbar {
    width: 4px;
    /* Adjust the width of the scrollbar */
    height: 4px;
    /* Adjust the height of the scrollbar */
}

.pro-sidebar-layout::-webkit-scrollbar-track {
    background: #f4f3f6;
    -webkit-box-shadow: inset 0 0 6px rgb(244 243 246);
}

.pro-sidebar-layout::-webkit-scrollbar-thumb {
    background: #f4f3f6;
    /* Choose a handle color */
    border-radius: 10px;
    /* Optionally round the corners */
}

.pro-sidebar-layout::-webkit-scrollbar-thumb:hover {
    background: #ccc;
    /* Choose a handle hover color */
}

.pro-sidebar-layout:hover::-webkit-scrollbar-thumb {
    background: #ccc;
    /* Choose a handle hover color */
}



/* social page css  */

.fQzPXW {
    display: flex;
    flex-direction: column;
    border: 1px solid rgb(224, 224, 224);
    border-radius: 4px;
    /* margin-bottom: 8px; */
}

.channel-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    background: rgb(255, 255, 255);
    border-radius: 4px;
    box-shadow: rgb(174 174 174 / 27%) 0px 0px 0.25em, rgb(255 255 255 / 5%) 0px 0.25em 1em;
}

.channel-left-side {
    display: flex;
}

.social-icon {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    margin-right: 12px;
    border-radius: 100%;
    width: 50px;
    height: 50px;
    background: rgb(0, 0, 0);
}

.social-icon img {
    width: 50px;
    height: 50px;
}

.social-description {
    color: rgb(99, 99, 99);
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-top: 2px;
}

.jhvAfU {
    font-size: 12px;
    font-weight: 500;
    padding: 4px 8px;
    background: rgb(245, 245, 245);
    border-radius: 3px;
    margin-left: 8px;
    margin-bottom: 0;
    height: -webkit-fill-available;
}

.social-name h4 {
    font-size: 20px;
    font-weight: 900;
}

.connect-user-btn {
    font-size: 16px;
    font-weight: 600;
    padding: 12px 40px;
    background: var(--btncolor);
    color: rgb(255, 255, 255);
    border-radius: 6px;
    margin-left: 8px;
    margin-bottom: 0;
    border: none;
}

.channel-list {
    margin-top: 24px;
}

.Modal-Postresult .modal-dialog {
    min-width: 700px;
}

.Modal-Postresult .modal-header {
    padding: 26px 32px 0px;
}

.title-view {
    font-size: 20px;
    margin-bottom: 0;
}

.Modal-Postresult .modal-body {
    padding: 20px 32px;
}

.custom-text-area {
    border: none;
    font-size: 16px;
}

.title-view-sco {
    font-size: 20px;
    margin-bottom: 0;
}

.custom-text-area:focus {
    border: none !important;
    box-shadow: none;
}

.post-box {
    border: 1px solid #ced4da;
    border-radius: 6px 6px 0px 0px;
    /* height: 50%; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.image-upload-container {
    position: relative;
    padding: 16px 16px 8px;
}

.upload-images-list {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-shrink: 0;
    flex-wrap: wrap;
    z-index: 1;
    gap: 10px;
}

.image-container-view img {
    width: 120px;
    height: 120px;
    border-radius: 6px;
    object-fit: cover;
}

.image-container-view video {
    width: 120px;
    height: 120px;
    border-radius: 6px;
    object-fit: cover;
}


.file-input-container {
    border: 2px dashed #ccc;
    border-radius: 4px;
    padding: 0;
    text-align: center;
    cursor: pointer;
    width: 120px;
    height: 120px;
    display: flex;
}

.file-input-container:hover {
    border-color: #aaa;
}

.input-icon {
    display: block;
    margin: auto;
    font-size: 24px;
}

.file-input-text {
    color: #888;
    margin-top: 16px;
}

.file-input {
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: 2;
    width: 120px;
    height: 120px;
    cursor: pointer;
}

._closeButton_1c4j9_57 {
    width: 20px !important;
    height: 20px !important;
    line-height: 20px !important;
    font-size: 20px !important;
    color: #3d3d3d !important;
    position: absolute;
    top: -6px;
    right: -4px;
    border: 2px solid #fff !important;
}

.YsbxX {
    text-align: center;
    border-radius: 50%;
    text-decoration: none;
    border: none;
    padding: 0px;
    box-sizing: content-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    background: rgb(61, 61, 61);
}

._closeButton_1c4j9_57 svg {
    display: inline-block;
    flex-shrink: 0;
    width: 16px;
    height: 16px;
    vertical-align: unset;
    color: #fff;
}

.image-container-view {
    position: relative;
    width: 120px;
    height: 120px;
}

.post-extra-sec {
    border: 1px solid #ccc;
    border-top: none;
    padding: 10px;
}

.post-extra-sec-content {
    display: flex;
    align-items: center;
    gap: 10px;
}

.social-post-image img {
    width: 100px;
    height: 100px;
    border-radius: 6px;
    object-fit: cover;
}

.channel-list-box {
    text-align: center;
}

.channel-list-box-text p {
    margin-bottom: 0;
    font-size: 15px;
    font-weight: 700;
    margin-top: 8px;
}

.channel-list- {
    display: flex;
    align-items: center;
    gap: 24px;
    flex-wrap: wrap;
}

/* .channel-list-::-webkit-scrollbar {
    width: 4px;
}

.channel-list-::-webkit-scrollbar-track {
    background: #28292f;
    -webkit-box-shadow: inset 0 0 6px rgb(40 41 47);
}

.channel-list-::-webkit-scrollbar-thumb {
    background: #28292f;
    border-radius: 10px;
}

.channel-list-::-webkit-scrollbar-thumb:hover {
    background: #ccc;
} */

.channel-list-box-icon {
    cursor: pointer;
}

.channel-list-box-icon img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
}

.channel-list-box.active .channel-list-box-icon img {
    border: 2px solid #0f28d8;
    border-radius: 50%;
    height: 52px;
    width: 52px;
    padding: 2px;
}

.customize-btn {
    background: transparent;
    border: 1px solid #0f28d8;
    color: #0f28d8;
    padding: 8px 16px;
    margin-right: 10px;
}

.customize-btn:hover {
    background: #0f28d8;
    color: #fff;
}

.nav-item-customize {
    display: flex;
    gap: 5px;
}

.nav-item-customize .nav-link {
    display: flex;
    align-items: center;
}

.nav-item-customize .nav-link {
    background-color: #ffffff;
    border-radius: 0;
    color: #000;
    font-weight: 700;
}

.nav-item-customize .nav-link.active {
    border-bottom: 2px solid #0f28d8;
    background-color: #f4f4f4;
    color: #0f28d8;
}

.nav-item-customize .nav-link .tab-icon {
    margin-right: 5px;
}

.post-container-customize .post-box {
    min-height: 300px;
}

.cGGiyr {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    font-weight: 600;
    font-size: 15px;
    color: rgb(52, 62, 71);
    width: 110px;
    padding-right: 10px;
}

/* .flex-page-tabs-scroll li.nav-item {
    width: 50%;
    text-align: center;
} */


.post-header-box {
    border: 1px solid #ccc;
    padding: 12px 16px;
    border-bottom: 0;
}

.post-header {
    display: flex;
    align-items: center;
    gap: 10px;
}

.post-header-img img {
    width: 30px;
    height: 30px;
}

.post-content-box {
    border: 1px solid #ccc;
    padding: 10px 16px;
}

.post-content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.post-content-img {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: 9rem;
    height: 9rem;
    border-radius: 4px;
    border: 1px solid rgb(184, 184, 184);
    background: rgb(245, 245, 245);
}

.post-content-img img {
    height: auto;
    width: 9rem;
    max-height: 9rem;
    max-width: 9rem;
    min-height: 7rem;
    min-width: 7rem;
    object-fit: cover;
}

.post-footer-box {
    background: #f5f5f5;
    padding: 10px 16px;
    border: 1px solid #ccc;
    border-top: 0;
}

.post-footer-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footer-left-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 50%;
}

.inner-tabs-body {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
    gap: 16px;
}

.post-view-box {
    width: 49%;
}

.post-footer-name {
    font-size: 14px;
}

.post-footer-number {
    font-weight: 900;
}

.epr_-6npj90 {
    position: absolute !important;
    bottom: 17%;
    z-index: 999;
}

.emoji-box img {
    position: relative;
}

.description-sec {
    margin: 16px 0;
    background: #fff;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 4px;
    padding: 10px 16px;
}

button.btn-new-approved {
    background-image: linear-gradient(45deg, #0f2cd6, #27ca71);
    color: #fff;
    border: none;
    border-radius: 4px;
}

.hide-sec-btn {
    text-align: -webkit-right;
    margin-bottom: 10px;
}

button.close-sec-btn {
    font-size: 20px;
    background: #000;
    color: #ffffff;
    border: none;
    border-radius: 50%;
    padding: 0;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

textarea.form-control {
    min-height: calc(7.5em + .75rem + calc(var(--bs-border-width) * 2));
}

.delete-btn {
    background: #f44336;
    border: none;
    height: 44px;
    padding: 0px 18px;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
}

.delete-btn:hover {
    background: #f44336;
}

.schedule-tab-design .post-view-box {
    width: 100%;
    margin-bottom: 14px;
}

.no-data-found {
    text-align: center;
}

.facebook-profile {
    position: relative;
}

.bdyGBn {
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 22px;
    height: 22px;
    border-radius: 100%;
    background: rgb(24, 119, 242);
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateX(2px) translateY(0px);
    border: none;
    font-size: 12px;
    border: 2px solid #fff;
}

.bdyGBn svg {
    color: #ffffff;
}

.channel-right-side {
    display: flex;
}

.bPqQjn {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    border-left: 1px solid rgb(224, 224, 224);
    margin-left: 20px;
}

.bPqQjn .transparent-btn {
    cursor: pointer;
    background: rgb(255, 255, 255) !important;
    border: none;
    height: 40px;
    width: 60px;
}

.bPqQjn .transparent-btn svg {
    color: rgb(24, 119, 242);
    font-size: 20px;
}

.accordion-body-content {
    padding: 16px;
    background: rgb(255, 255, 255);
    border-radius: 0 0 4px 4px;
    /* box-shadow: rgb(174 174 174 / 27%) 0px 0px 0.25em, rgb(255 255 255 / 5%) 0px 0.25em 1em; */
    border: 1px solid rgb(224, 224, 224);
}

.fQzPXW.active {
    border-bottom: 0;
    border-radius: 4px 4px 0 0;
}

.accordion-title {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 20px;
}

.connected-channel-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    margin-bottom: 14px;
    padding: 14px;
    border-radius: 8px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.connected-left-side {
    display: flex;
    align-items: center;
    gap: 20px;
}

.user-name-id {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 2px;
}

.platform-name {
    margin-bottom: 0;
}

.connected-sign svg {
    width: 20px;
    height: 20px;
    color: #3a933a;
}


/***************  NIHAL CSS  *************/

.custom-input {
    width: 50%;
}

.custom-input img {
    width: 40px;
    height: 40px;
}