/* Ensure the modal has a high z-index */
.fixed {
    position: fixed;
    z-index: 9999; /* Set a high z-index */
}

.bg-black.bg-opacity-30 {
    background-color: rgba(0, 0, 0, 0.3);
}

.flex.items-center.justify-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.p-2 {
    padding: 0.5rem;
}

.hover\:bg-gray-100:hover {
    background-color: #f7f7f7;
}

.rounded {
    border-radius: 0.375rem;
}

/* Optional: Ensure modal content stays on top */
.bg-white {
    z-index: 10000; /* Ensure modal content stays above the overlay */
}


