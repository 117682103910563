/* Container for the text area */
.text-area-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0;
    /* Light grey background */
    height: auto;
    border-radius: 10px;
    padding: 10px;
    border: none;
}

/* Styling for the textarea */
.text-area {
    width: 100%;
    /* Set the width to 100% for full responsiveness */
    max-width: 1000px;
    /* Optional: max width for large screens */
    height: 500px;
    padding: 15px;
    /* Inner padding */
    font-size: 16px;
    /* Font size */
    border: 2px solid #ccc;
    /* Border color */
    border-radius: 8px;
    /* Rounded corners */
    background-color: #fff;
    /* White background */
    resize: none;
    /* Prevent resizing */
    transition: all 0.3s ease;
    /* Smooth transition for interactions */
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
    /* Subtle shadow with more emphasis below */
    /* Add shadow below the textarea */
}

/* Focus effect for the textarea */
.text-area:focus {
    outline: none;
    /* Remove default focus outline */
    border-color: #4CAF50;
    /* Change border color on focus */
    box-shadow: 0 0 12px rgba(76, 175, 80, 0.5);
    /* Green focus shadow */
}

/* Title/Label for the Preamble section */
.preamble-title {
    font-size: 24px;
    font-weight: 600;
    color: #333;
    margin-bottom: 10px;
    text-align: center;
}

/* Optional additional styling for button or interaction */
.update-button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s;
}

/* Hover effect for the button */
.update-button:hover {
    background-color: #45a049;
}