.bg-color-nav {
    background-color: #142330;
}

.header-section {
    position: fixed;
    width: 100%;
    top: 40px;
    display: flex;
    justify-content: center;
    z-index: 1000;
}

.nav-bar-width {
    width: calc(100% - 200px);
    height: 80px;
    border-radius: 50px;
    color: #fff;
}

.inner-padding {
    padding: 0 50px 0 50px !important; 
}

.navbar-brand img {
    width: 170px;
}

.navbar-expand-lg .navbar-nav .nav-link-items {
    color: #fff;
    font-family: 'Space Grotesk', sans-serif;
}

.nav-btn-color {
    background-image: var(--btncolor) !important;
    color: #fff !important;
    border-radius: 50px;
    font-weight: bold !important;
    font-size: 16px !important;
    line-height: 24px;
    text-align: center;
    padding: 12px 40px !important;
    font-family: 'Space Grotesk', sans-serif;
}

.nav-btn-liner {
    color: #fff !important;
    font-family: 'Space Grotesk', sans-serif;
    line-height: 32px;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    margin: 0px 30px;
}

.navbar-toggler {
    background-color: #ffffff !important;
    border-color: #ffffff !important;
    width: 45px;
    padding: 0 !important;
    height: 40px;
}

.navbar-collapse{
    flex-grow: 0 !important;
}

@media (max-width: 991px) {
    .header-section {
        top: 0px;
    }

    .nav-bar-width {
        width: 100%;
        height: 80px;
        border-radius: 0px;
    }

    .inner-padding {
        padding: 0 18px 0 18px !important;
    }

    .navbar-brand img {
        width: 140px;
    }
}


@media (min-width: 992px) and (max-width: 1199px) {
    .nav-bar-width {
        width: calc(100% - 140px);
    }

    .inner-padding {
        padding: 0 30px 0 30px !important;
    }

    .navbar-brand img {
        width: 150px;
    }
}

.sec-center {
    position: relative;
    max-width: 100%;
    z-index: 200;
    margin-left: 24px;
}

.top-menu:checked,
.top-menu:not(:checked) {
    position: absolute;
    left: -9999px;
    opacity: 0;
    pointer-events: none;
}

.dark-light:checked+label,
.dark-light:not(:checked)+label {
    position: fixed;
    top: 40px;
    right: 40px;
    z-index: 20000;
    display: block;
    border-radius: 50%;
    width: 46px;
    height: 46px;
    cursor: pointer;
    transition: all 200ms linear;
    box-shadow: 0 0 25px rgba(255, 235, 167, 0.45);
}

.dark-light:checked+label {
    transform: rotate(360deg);
}

.dark-light:checked+label:after,
.dark-light:not(:checked)+label:after {
    position: absolute;
    content: "";
    top: 1px;
    left: 1px;
    overflow: hidden;
    z-index: 2;
    display: block;
    border-radius: 50%;
    width: 44px;
    height: 44px;
    background-color: #102770;
    background-image: url("https://assets.codepen.io/1462889/moon.svg");
    background-size: 20px 20px;
    background-repeat: no-repeat;
    background-position: center;
    transition: all 200ms linear;
    opacity: 0;
}

.dark-light:checked+label:after {
    opacity: 1;
}

.dark-light:checked+label:before,
.dark-light:not(:checked)+label:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    overflow: hidden;
    z-index: 1;
    display: block;
    border-radius: 50%;
    width: 46px;
    height: 46px;
    background-color: #48dbfb;
    background-image: url("https://assets.codepen.io/1462889/sun.svg");
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: center;
    transition: all 200ms linear;
}

.dark-light:checked+label:before {
    background-color: #000;
}

.light-back {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: #fff;
    overflow: hidden;
    background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/1462889/pat-back.svg");
    background-position: center;
    background-repeat: repeat;
    background-size: 4%;
    height: 100%;
    width: 100%;
    transition: all 200ms linear;
    opacity: 0;
}

.dark-light:checked~.light-back {
    opacity: 1;
}

.dropdown:checked+label,
.dropdown:not(:checked)+label {
    position: relative;
    transition: all 200ms linear;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    text-align: center;
    border: none;
    cursor: pointer;
    margin-bottom: 0;
}

.dark-light:checked~.sec-center .for-dropdown {
    background-color: #102770;
    color: #ffeba7;
    box-shadow: 0 12px 35px 0 rgba(16, 39, 112, 0.25);
}

.dropdown:checked+label:before,
.dropdown:not(:checked)+label:before {
    position: fixed;
    top: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 100%;
    z-index: -1;
    cursor: auto;
    pointer-events: none;
}

.dropdown:checked+label:before {
    pointer-events: auto;
}

.dropdown:not(:checked)+label .uil {
    font-size: 24px;
    margin-left: 10px;
    transition: transform 200ms linear;
}

.dropdown:checked+label .uil {
    transform: rotate(180deg);
    font-size: 24px;
    margin-left: 10px;
    transition: transform 200ms linear;
}

.section-dropdown {
    position: absolute;
    padding: 5px;
    top: 25px;
    left: 0px;
    width: 191px;
    display: block;
    z-index: 2;
    opacity: 0;
    pointer-events: none;
    transform: translateY(20px);
    transition: all 200ms linear;
    border-radius: 8px;
    box-shadow: -6px 9px 15px rgb(0 0 0 / 8%);
    background: #ffffff;
}

.dark-light:checked~.sec-center .section-dropdown {
    background-color: #fff;
    box-shadow: 0 14px 35px 0 rgba(9, 9, 12, 0.15);
}

.dropdown:checked~.section-dropdown {
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0);
}

.section-dropdown:before {
    position: absolute;
    top: -20px;
    left: 0;
    width: 100%;
    height: 20px;
    content: "";
    display: block;
    z-index: 1;
}

.user-image {
    height: 32px;
    background: #333;
    border-radius: 50%;
    width: 32px;
    overflow: hidden;
}

.user-image img.img-responsive,
.user-img img {
    height: 100%;
    object-fit: cover;
}

span.user-name {
    font-size: 15px;
    color: var(--black);
    text-transform: capitalize;
    letter-spacing: 0.1px;
    font-weight: normal;
}

span.user-name {
    padding: 0 15px;
    text-decoration: none;
}

.sec-center.show .profile-btn-view svg {
    transform: rotate(180deg);
}


.memu-drop-item {
    line-height: 22px !important;
    padding: 8px 17px !important;
    color: #292929 !important;
    font-size: 15px !important;
    font-weight: normal !important;
    transition: all 200ms linear !important;
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start !important;
}

.memu-drop-item svg {
    font-size: 24px !important;
    padding-right: 8px;
    padding-top: 2px !important;
    font-weight: normal;
}

.mobile-nav.navbar-nav {
    flex-direction: unset;
}

.memu-drop-item:hover {
    background-color: #f4f4f4;
}

.mobile-nav-view {
    display: none;
}

.nav-custom1 {
    display: flex;
    flex-direction: inherit;
}

.profile-btn-view {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.sec-center.show .section-dropdown {
    display: block;
    opacity: 1;
    z-index: 999;
    pointer-events: auto;
}

@media(max-width: 991px) {
    .mobile-nav-view {
        display: block;
    }

    .desktop-nav {
        display: none;
    }

    .me-48 {
        margin-right: 0px;
    }

    .navbar-collapse.show {
        position: fixed;
        top: 0;
        width: 75%;
        height: 100%;
        left: 0;
        right: 0;
        background: #000;
        padding: 20px;
    }

    .nav-btn-liner {
        width: auto;
        margin: 0 !important;
        text-align: left;
    }

    .nav-btn-color {
        width: fit-content;
    }
}

@media(max-width: 479.98px) {

    .icon-btn {
        font-size: 17px;
        font-weight: 900;
        text-align: left;
        padding: 0 8px;
        position: relative;
        color: #000;
        display: flex;
        align-items: center;
    }

    .me-48 {
        margin-right: 0px;
    }

    .nav-btn-liner {
        color: #fff !important;
        font-family: 'Space Grotesk', sans-serif;
        line-height: 32px;
        font-weight: bold;
        font-size: 14px;
        text-align: center;
        margin: 0px 30px;
    }

    .nav-btn-color {
        background-image: var(--btncolor) !important;
        color: #fff !important;
        border-radius: 50px;
        font-weight: bold !important;
        font-size: 14px !important;
        line-height: 24px;
        text-align: center;
        padding: 10px 30px !important;
        font-family: 'Space Grotesk', sans-serif;
    }
}