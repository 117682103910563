.Back-btn-icon {
    font-size: 40px;
    margin-right: 20px;
    padding: 0px;
    border: none;
    background: none;
    color: #5d586c;
    margin-bottom: 10px;
}

.Back-btn-text {
    overflow: hidden;
}

.Back-btn {
    padding-left: 12px;
    color: #5d586c;
    margin-bottom: 20px;
}

.Back-btn-text h5 {
    font-size: 18px;
    font-weight: 600;
}

.Back-btn-text h6 {
    font-size: 14px;
    font-weight: 500;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.Smart-Link-Editing .tab-content-header {
    margin-left: 0px;
}

.Template-card-image {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
}

.Template-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 8px;
    background-color: #E8E8E9;
    border-radius: 6px;
    margin-bottom: 20px;
}

.Template-card-name h5 {
    font-size: 16px;
    font-weight: 600;
    color: #000;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.Template-card-name h6 {
    font-size: 14px;
    font-weight: 500;
}

.Smart-Link-Editing .tab-content-body {
    margin-top: 26px;
    border-radius: 6px;
    min-height: 700px;
}

.three-dot-dropdown {
    background-color: transparent;
    color: #5D586C;
    border: none;
    font-size: 20px;
}

.three-dot-dropdown::after {
    display: none !important;
}

.Update-GeoTargeting-list {
    padding: 12px;
    box-shadow: 0px 4px 18px rgb(75 70 92 / 10%);
    border-radius: 6px;
}

.geo-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.GeoTargeting-list-table ul {
    list-style: none;
    padding-left: 0px;
}

.GeoTargeting-list-table ul li {
    margin-bottom: 20px;
}

.GeoTargeting-image {
    width: 40px;
    height: auto;
    margin-right: 16px;
}

.GeoTargeting-list-table-left-text h5 {
    font-size: 18px;
    font-weight: 600;
}

.GeoTargeting-list-table-left-text p {
    font-size: 14px;
    font-weight: 500;
    margin-right: 16px;
    margin-bottom: 0px;
}

.GeoTargeting-list-icon {
    font-size: 24px;
    color: #7367f0;
}

.btn-editing {
    color: #7367f0 !important;
    background-color: transparent !important;
    font-size: 24px;
    padding: 0px 8px;
    border: none;
}

.Duration-RangeSlider .rangeslider__handle::after {
    display: none;
}

.Duration-RangeSlider .rangeslider__handle {
    box-shadow: none !important;
    background-color: #7367f0 !important;
    border: 4px solid #fff !important;
}

.Duration-RangeSlider .rangeslider__fill {
    background-color: #7367f0 !important;
}

.password-design input.form-control {
    border-right: 0px;
}

.password-design button.eye-btn {
    border: 1px solid #ccc !important;
    border-radius: 0px 5px 5px 0px;
    border-left: none !important;
    color: #000 !important;
    background-color: transparent !important;
}

.link-cloaking-form-group {
    position: relative;
}

.link-cloaking-form-group input {
    padding-top: 36px;
}

.link-cloaking-form-group .positions01 {
    position: absolute;
    top: 0px;
    left: 12px;
}

.tab01 .nav-pills .nav-item a {
    color: #5D586C;
    padding: 8px 30px;
    border-radius: 5px;
    font-weight: 700;
    background-color: #e6e5eb;
    margin-right: 20px;
}

.tab01 .nav-pills .nav-item a.active {
    background-color: #7367f0;
    color: #fff;
}

.tab01 .tab-content {
    margin-bottom: 26px;
}

.file-design label.lkjDVC {
    max-width: 100% !important;
    height: auto !important;
    padding: 26px;
    display: block;
    text-align: center;
    border: 2px dashed #000;
}

.file-design .dmajdp {
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.file-design .dmajdp span.file-types {
    max-width: 100%;
}

.theme-design-img {
    margin-right: 20px;
}

.preview-design-text h5 {
    font-size: 18px;
    font-weight: 600;
    color: #5D586C
}

.preview-design.color-blue .preview-design-text h5 span {
    color: #7367f0;
}

.preview-design {
    /* display: flex; */
    justify-content: space-between;
    width: auto;
    align-items: center;
    /* padding: 21px 24px; */
    border-radius: 16px;
}

.preview-design-text {
    margin: 0px 10px;
}

.preview-design.color-blue {
    background-color: #c3cffb;
}

.preview-design-img {
    background: #fff;
    border-radius: 10px;
    padding: 10px;
    width: 80px;
    height: 80px;
}

.Theme-design-text h5 {
    font-size: 14px;
    font-weight: 600;
    color: #5D586C
}

.Theme-design-text h5 span {
    color: #7367f0;
}

.Theme-design {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 15px;
    background-color: #E8E7F5;
    border-radius: 12px;
    margin-bottom: 16px;
}

.Theme-design-img {
    background: #fff;
    border-radius: 10px;
    padding: 10px;
    width: 50px;
}

.Theme-design button.bg-green-dark {
    padding: 6px 16px;
}

.theme01 .Theme-design {
    background-color: #FFE0D2;
}

.theme01 button.bg-green-dark {
    background-color: #FF6D42 !important;
}

.theme01 .Theme-design-text h5 span {
    color: #FF6D42;
}

.theme02 .Theme-design {
    background-color: #EAD7B5;
}

.theme02 button.bg-green-dark {
    background-color: #bc7900 !important;
}

.theme02 .Theme-design-text h5 span {
    color: #B87E21;
}

.theme03 .Theme-design {
    background-color: #C3CFFB;
}

.theme03 button.bg-green-dark {
    background-color: #4777FC !important;
}

.theme03 .Theme-design-text h5 span {
    color: #4777FC;
}

.password-bottom {
    background-color: #D9D9D9;
}

.password-bottom-center {
    background-color: #fff;
    max-width: 360px;
    padding: 50px 30px;
    text-align: center;
    width: 100%;
}

.page404 {
    color: #7367f0;
}

.page404-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    padding: 10px 20px;
}

.page404-brand-logo img {
    width: auto;
    height: 50px;
}

.page404-socials-img {
    width: 30px;
    height: auto;
    margin-right: 20px;
}

.page404-body {
    text-align: center;
}

.page404-content h5 a,
.page404-email h5 a {
    text-decoration: none;
    color: #7367f0;
}

button.delete-Cancel-bttn {
    background-color: #7367f0 !important;
    border: 1px solid #7367f0 !important;
    border-radius: 6px;
}

button.confirm-Delete-bttn {
    background-color: #EA5455 !important;
    border: 1px solid #EA5455 !important;
    border-radius: 6px;
}

.page404-footer .page404-socials .page404-socials-img:nth-child(n+3) {
    margin-right: 0px;
}

.Theme-design {
    width: 100%;
    cursor: pointer;
}

.mobile-view-show {
    padding: 0px !important;
    margin-top: 16px;
}

.accordion-body {
    padding: 16px 0px;
}

.tab-list-header .accordion-button {
    padding: 10px 16px;
}

.mobile-view-show {
    display: none;
}

.form-select {
    color: #6c757d;
}

.GeoTargeting-list-left h5 {
    font-size: 18px;
}

.GeoTargeting-list-left p {
    font-size: 14px;
}

.row002 {
    display: flex;
    justify-content: flex-start;
    gap: 16px;
    overflow-x: auto;
    flex-direction: row;
}

.link-cloaking-body {
    border-radius: 6px;
    box-sizing: border-box;
    padding: 16px;
    margin-bottom: 16px;
    box-shadow: 0px 4px 18px rgb(75 70 92 / 10%);
    border-radius: 6px;
    background-color: #fff;
}

.input-field--CUSTOM {
    width: 100%;
    border: 1px solid #ced4da;
    border-radius: 0.3rem;
    position: relative;
    cursor: pointer;
    margin-bottom: 1rem;
    padding: 1.1rem 1rem 0 0.9rem;
}

.input-field--CUSTOM .input-field--static-value {
    color: #273144;
    font-size: 16px;
    color: #000;
    display: inline-block;
    margin-top: -.1rem;
    font-weight: 600;
}

.input-field--CUSTOM .input-field--input-wrapper {
    position: relative;
    bottom: .2rem;
    overflow: hidden;
}

.input-field--input {
    color: #273144;
    font-size: 18px;
    outline: none;
    resize: none;
    overflow: hidden;
    height: 3.1rem;
    width: 100%;
    border: none;
    padding: 0 0 22px;
    margin: 0;
    background-color: initial;
    color: #273144;
    font-weight: 600;
}

.input-field--label {
    margin-bottom: 8px;
}

.transparent.remove-sec {
    display: none;
}

.Template-card-delete-btn {
    position: absolute;
    top: 5%;
    right: 7%;
    display: none;
}

.qr-template-view {
    position: relative;
}

.qr-template-view:hover .Template-card-delete-btn {
    display: block;
}

.Template-card-delete-btn-icon .btn {
    border-radius: 50px;
}

.preview-design-button .preview-design-img img {
    max-width: 100%;
    height: -webkit-fill-available;
    object-fit: cover;
}

@media (max-width: 1199.99px) {
    .page404-brand-logo {
        width: 110px;
    }

    .page404-content h5 a,
    .page404-email h5 a {
        font-size: 15px;
    }

    .tab01 .nav-pills .nav-item a {
        padding: 4px 14px;
        margin-right: 8px;
        font-weight: 600;
    }
}

@media (max-width: 767.99px) {
    .tab01 .nav-pills .nav-item a {
        font-size: 12px;
    }

    .Smart-Link-Editing .tab-content-body {
        min-height: 570px;
    }

    .page404-brand-logo {
        width: 80px;
    }

    .page404-content h5 a,
    .page404-email h5 a {
        font-size: 12px;
    }
}

@media (max-width: 575.99px) {
    .file-design label.lkjDVC {
        padding: 10px;
        min-width: 100px;
    }

    .preview-design {
        width: 100%;
        padding: 10px;
        border-radius: 10px;
    }

    .Theme-design {
        width: 100%;
        max-width: 320px;
    }
}

@media (max-width: 478.99px) {
    .page404-header {
        flex-direction: column;
    }

    .preview-design,
    .Theme-design {
        width: 100%;
        padding: 10px;
        max-width: 260px;
        border-radius: 10px;
    }

    .preview-design-img,
    .Theme-design-img {
        padding: 3px;
        border-radius: 6px;
        width: 40px;
    }

    .preview-design-text h5,
    .Theme-design-text h5 {
        font-size: 10px;
    }

    .Theme-design button.bg-green-dark {
        padding: 4px 10px;
    }

    .rangeslider__labels {
        display: none;
    }

    .password-bottom-center {
        padding: 26px 10px;
    }

    .GeoTargeting-list-table-left-text h5 {
        font-size: 15px;
    }

    .GeoTargeting-list-table-left-text p {
        font-size: 12px;
    }

    .form-label,
    .form-check-label {
        font-size: 14px;
    }
}

@media (max-width: 767px) {
    .mobile-view-hide {
        display: none;
    }

    .mobile-view-show {
        display: block;
    }

    .Back-btn {
        margin-bottom: 0px;
    }

    .Back-btn-icon {
        margin-right: 18px;
    }

    .Back-btn-text {
        overflow: hidden;
    }

    .Back-btn-text h6 {
        font-size: 14px;
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

@media (max-width: 575.99px) {
    .file-design label.lkjDVC {
        padding: 10px;
        min-width: 100px;
    }

    .preview-design {
        width: 100%;
        padding: 10px;
        border-radius: 10px;
    }

    .Theme-design {
        width: 100%;
        max-width: 320px;
    }
}

@media (max-width: 478.99px) {
    .page404-header {
        flex-direction: column;
    }

    .preview-design,
    .Theme-design {
        width: 100%;
        max-width: 100%;
        border-radius: 10px;
    }

    .preview-design-img,
    .Theme-design-img {
        padding: 3px;
        border-radius: 6px;
        width: 40px;
    }

    .preview-design-text h5,
    .Theme-design-text h5 {
        font-size: 10px;
    }

    .Theme-design button.bg-green-dark {
        padding: 4px 10px;
    }

    .rangeslider__labels {
        display: none;
    }
}

.VideoInput {
    max-width: 100% !important;
    height: auto !important;
    padding: 26px;
    display: block;
    text-align: center;
    border: 2px dashed #000;
}

.image-uploder-preview.active {
    display: block;
}

.video-uploder-preview.active {
    display: block;
}

.iFrame-uploder-preview.active {
    display: block;
}

.social-icons-list {
    display: none;
}

.social-icons-list.active {
    display: block;
}

.brand-upload {
    display: none;
}

.brand-upload.active {
    display: block;
}

.select-gradient {
    display: none;
}

.select-gradient.active {
    display: block;
}




.Position-img img {
    cursor: pointer;
}

.preview-box {
    border: 1px solid #ccc;
    border-radius: 16px;
    /* height: 800px; */
}

.preview-header {
    height: 60px;
    background-color: #3a4445;
    border-radius: 15px 15px 0px 0px;
    display: flex;
}

.three-dot {
    margin-left: 20px;
}

.three-dot span svg {
    color: #fff;
    font-size: 20px;
}

.three-dot span.dot-red svg {
    color: #ff0000;
}

.three-dot span.dot-yellow svg {
    color: #ffcc00;
}

.three-dot span.dot-green svg {
    color: #00ff00;
}

.preview-body {
    padding: 20px;
    height: calc(500px - 78px);
}

.preview-body-redirection {
    padding: 20px;
    /* height: calc(600px - 84px); */
}

.preview-body-password2 {
    padding: 20px;
    height: calc(600px - 78px);
}

.preview-body.box-bottom {
    display: flex;
    align-items: end;
}

.preview-body.box-bottom-left {
    display: flex;
    align-items: end;
}

.preview-body.box-top-left {
    display: flex;
    align-items: start;
}

.preview-body.box-top {
    display: flex;
    align-items: start;
}

.preview-body.box-top-right {
    display: flex;
    align-items: start;
    justify-content: flex-end;
}

.preview-body.box-right-bottom {
    display: flex;
    align-items: end;
    justify-content: flex-end;
}

.preview-design.color-blue .preview-design-btn .preview-btn-color {
    color: #fff;
    padding: 8px 34px;
    border-radius: 6px;
    background: #4777fc;
}

.preview-design.color-orange .preview-design-text h5 span {
    color: #FF6D42;
}

.preview-design.color-orange {
    background-color: #FFE0D2;
}

.preview-design.color-orange .preview-design-btn .preview-btn-color {
    color: #fff !important;
    padding: 8px 34px;
    border-radius: 6px;
    background: #FF6D42 !important;
}


.preview-design.color-brown .preview-design-text h5 span {
    color: #B87E21;
}

.preview-design.color-brown {
    background-color: #ebddc4;
}

.preview-design.color-brown .preview-design-btn .preview-btn-color {
    color: #fff !important;
    padding: 8px 34px;
    border-radius: 6px;
    background: #bc7900 !important;
}

.image-uploder-preview img {
    width: 100%;
    height: 418px;
    /* object-fit: cover; */
    margin-bottom: 5px;
}

.skip-btn .btn {
    padding: 12px 24px;
    border-radius: 0px;
}


.skip-btn .btn svg {
    color: #fff;
    font-size: 20px;
    margin-left: 6px;
}

.form-btn-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.video-uploder-preview video {
    height: 418px;
    object-fit: cover;
}

.skip-timer {
    color: #fff;
    font-size: 18px;
    margin-bottom: 2px;
}



/* new css start here  */

.select-brand {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 18px;
    text-transform: capitalize;
}

.brand-image-preview img {
    width: 200px;
    height: auto;
}

.preview-web-header {
    margin: 0;
    padding: 10px 20px;
}

.preview-footer {
    margin: 0;
    padding: 0px 20px 10px;
    border-radius: 0px 0px 14px 14px;
}

.preview-web-header.left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.preview-web-header.right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.preview-web-header.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.brand-logo img {
    width: auto;
    height: 40px;
}

.footer-brand-logo {
    margin-bottom: 16px;
}

.footer-brand-logo img {
    width: auto;
    height: 40px;
}

.preview-footer.left {
    text-align: left;
}

.preview-footer.right {
    text-align: right;
}

.preview-footer.center {
    text-align: center;
}

.web-footer-text {
    font-size: 16px;
    color: #5D586C;
    margin: 0;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.preview-design iframe {
    width: 100%;
    height: 100%;
}

.acc-content-body {
    padding: 0px 16px;
}

.accordion-item:not(:first-of-type) {
    border-top: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}

.preview-design-button {
    display: flex;
    justify-content: space-between;
    width: auto;
    align-items: center;
    padding: 11px 13px;
    border-radius: 16px;
}

.preview-box-password {
    border: 1px solid #ccc;
    border-radius: 16px;
    /* height: 550px; */
}

.preview-body-password {
    padding: 20px;
    height: calc(550px - 222px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.preview-box-call-action {
    border: 1px solid #ccc;
    border-radius: 16px;
    height: 500px;
}

.full-screen-btn {
    color: #fff !important;
    height: 40px;
    border-radius: 4px;
}

.full-screen-btn svg {
    color: #fff;
    font-size: 15px;
    margin-right: 6px;
}

.modal-center-show .modal-dialog.modal-dialog-centered {
    min-width: 60%;
}

.modal-center-show .modal-dialog.modal-dialog-centered .modal-body {
    background-color: #c7c7c7;
}

.show-preview-header {
    display: flex;
    background: #fff;
    box-shadow: 0 2px 18px 0 rgba(129, 162, 182, .2);
    padding: 16px 0px;
    justify-content: center;
    align-items: center;
    /* gap: 30px; */
}

.show-preview-item {
    border-right: 1px solid #ccc;
    padding: 0px 15px;
}

.show-preview-item:last-child {
    border-right: none;
}

.show-preview-item svg {
    font-size: 30px;
}

.full-screen-btn-1 {
    background-color: transparent !important;
    padding: 0px !important;
    border: none !important;
}

.full-screen-btn-1:hover {
    background-color: transparent !important;
    color: #7367f0;
}

.show-preview-body {
    margin-top: 20px;
}

.preview-box-background.view-mobile {
    height: 690px;
    position: relative;
    transform: translateX(0);
    transition-duration: .3s;
    transition-property: transform;
    transition-timing-function: cubic-bezier(.25, 1, .5, 1);
    width: 350px;
    border: none !important;
}

.preview-box-background.view-mobile::before {
    border-radius: 46px 46px 0 0;
    margin-bottom: -7px;
}

.preview-box-background.view-mobile::before,
.preview-box-background.view-mobile::after {
    background: #fff;
    box-shadow: -1px 2px 3px -3px rgba(0, 0, 0, .2);
    content: "";
    display: inline-block;
    height: 62px;
    width: 100%;
}

.preview-box-background.view-mobile::after {
    border-radius: 0 0 46px 46px;
    margin-top: -3px;
}

.preview-box-background.view-mobile .preview-box {
    box-shadow: -15px 8px 20px -10px rgba(0, 0, 0, .2);
    height: 566px;
    max-height: 566px;
    width: 350px;
    border-radius: 0;
    overflow: auto;
    border: none;
    border-left: 6px solid #fff;
    border-right: 6px solid #fff;
}

.preview-box-background.view-mobile .preview-box .preview-header {
    border-radius: 0;
}

.preview-box-background.view-mobile .preview-box::-webkit-scrollbar {
    display: none;
}

.show-preview-content-2 {
    display: flex;
    justify-content: center;
}

.preview-box-background.view-tablet .oEUMCt,
.preview-box-background.view-mobile .oEUMCt {
    background-color: #eff1f2;
    border-radius: 1.5px;
    height: 3px;
    left: 50%;
    position: absolute;
    top: 23px;
    transform: translateX(-50%) translateY(-50%);
    width: 24px;
}

.preview-box-background.view-tablet .oEUMCterd,
.preview-box-background.view-mobile .oEUMCterd {
    background-color: #eff1f2;
    border-radius: 50px;
    height: 36px;
    left: 50%;
    position: absolute;
    bottom: -5px;
    transform: translateX(-50%) translateY(-50%);
    width: 36px;
}

.preview-box-background.view-tablet {
    height: 864px;
    position: relative;
    transform: translateX(0);
    transition-duration: .3s;
    transition-property: transform;
    transition-timing-function: cubic-bezier(.25, 1, .5, 1);
    width: 700px;
    border: none !important;
}

.preview-box-background.view-tablet::before {
    border-radius: 46px 46px 0 0;
    margin-bottom: -7px;
}

.preview-box-background.view-tablet::before,
.preview-box-background.view-tablet::after {
    background: #fff;
    box-shadow: -1px 1px 1px -1px rgba(0, 0, 0, .2);
    content: "";
    display: inline-block;
    height: 62px;
    width: 100%;
}

.preview-box-background.view-tablet::after {
    border-radius: 0 0 46px 46px;
    margin-top: -3px;
}

.preview-box-background.view-tablet .preview-box {
    box-shadow: -15px 8px 20px -10px rgba(0, 0, 0, .2);
    height: 740px;
    max-height: 740px;
    width: 700px;
    border-radius: 0;
    overflow: auto;
    border: none;
    border-left: 12px solid #fff;
    border-right: 12px solid #fff;
}

.preview-box-background.view-tablet .preview-box .preview-header {
    border-radius: 0;
}

.preview-box-background.view-tablet .preview-box::-webkit-scrollbar {
    display: none;
}

.preview-box-background.view-mobile .preview-box .preview-body-redirection {
    padding: 8px;
    height: calc(600px - 180px);
    /* display: flex; */
}

.preview-box-background.view-mobile .preview-box .preview-body-redirection .preview {
    margin: auto;
}

.preview-box-background.view-tablet .preview-box .preview-body-redirection {
    padding: 8px;
    height: calc(740px - 180px);
    /* display: flex; */
}

.preview-box-background.view-tablet .preview-box .preview-body-redirection .preview {
    margin: auto;
}

.preview-box-background.view-mobile .preview-box .preview-body-redirection .image-uploder-preview img {
    width: 100%;
    height: 260px;
    /* object-fit: cover; */
    margin-bottom: 5px;
}

.preview-box-background.view-desktop .preview-box-call-action .preview-body {
    padding: 20px;
    height: calc(640px - 78px);
}

.preview-box-background.view-tablet .preview-box-call-action .preview-body {
    padding: 20px;
    height: calc(740px - 78px);
}

.preview-box-background.view-mobile .preview-box-call-action .preview-body {
    padding: 20px;
    height: calc(566px - 28px);
}

.preview-box-background.view-mobile .preview-box-password .preview-body-password {
    padding: 20px;
    height: calc(566px - 162px);
}

.preview-box-background.view-tablet .preview-box-password .preview-body-password {
    padding: 20px;
    height: calc(740px - 162px);
}

.preview-box-background.view-desktop .preview-box-password .preview-body-password {
    padding: 20px;
    height: calc(640px - 155px);
}

.preview-box-background.view-desktop {
    height: 100%;
    position: relative;
    transform: translateX(0);
    transition-duration: .3s;
    transition-property: transform;
    transition-timing-function: cubic-bezier(.25, 1, .5, 1);
    width: 100%;
    border: none !important;
}

.preview-box-background.view-desktop::before {
    border-radius: 46px 46px 0 0;
    margin-bottom: -8px;
}

.preview-box-background.view-desktop::before,
.preview-box-background.view-desktop::after {
    background: #fff;
    box-shadow: 0px 0px 20px -10px rgba(0, 0, 0, .2);
    content: "";
    display: inline-block;
    height: 62px;
    width: 100%;
}

.preview-box-background.view-desktop::after {
    border-radius: 0 0 46px 46px;
    margin-top: -3px;
}

.preview-box-background.view-desktop .preview-box {
    box-shadow: -15px 8px 20px -10px rgba(0, 0, 0, .2);
    height: 100%;
    max-height: 100%;
    width: 100%;
    border-radius: 0;
    overflow: auto;
    border-left: 20px solid #fff;
    border-right: 20px solid #fff;
}

.preview-box-background.view-desktop .preview-box .preview-header {
    border-radius: 0;
}

.preview-box-background.view-desktop .preview-box::-webkit-scrollbar {
    display: none;
}

.preview-box-background.view-mobile .preview-box-call-action {
    border: none;
    border-radius: 0;
    background-color: #e5e5e5;
    height: 566px;
    border-left: 6px solid #fff;
    border-right: 6px solid #fff;
}

.preview-box-background.view-tablet .preview-box-call-action {
    border: none;
    border-radius: 0;
    background-color: #e5e5e5;
    height: 740px;
    border-left: 12px solid #fff;
    border-right: 12px solid #fff;
}

.preview-box-background.view-desktop .preview-box-call-action {
    border: none;
    border-radius: 0;
    background-color: #e5e5e5;
    height: 640px;
    border-left: 20px solid #fff;
    border-right: 20px solid #fff;
}

.preview-box-background.view-mobile .preview-box-password {
    border: none;
    border-radius: 0;
    background-color: #e5e5e5;
    height: 566px;
    border-left: 6px solid #fff;
    border-right: 6px solid #fff;
}

.preview-box-background.view-tablet .preview-box-password {
    border: none;
    border-radius: 0;
    background-color: #e5e5e5;
    height: 740px;

    border-left: 12px solid #fff;
    border-right: 12px solid #fff;
}

.preview-box-background.view-desktop .preview-box-password {
    border: none;
    border-radius: 0;
    background-color: #e5e5e5;
    height: 640px;
    border-left: 20px solid #fff;
    border-right: 20px solid #fff;
}

.preview-box-background.view-tablet .preview-body-password {
    padding: 20px;
    height: calc(550px - 0px);
}

.preview-box-background.view-mobile .preview-body-password {
    padding: 20px;
    height: calc(550px - 140px);
}


.page-img-404 {
   margin: 30px 0px 40px 0px;
}

.btn-spinner {
    width: 130px;
}

.btn-spinner .spinner-border {
    width: 1.5rem;
    height: 1.5rem;
}

.input-clear-btn {
    position: absolute;
    right: 55px;
    top: 1px;
    cursor: pointer;
    z-index: 1;
    color: #c10000 !important;
    font-size: 24px;
    transition: .3s ease-in-out;
    padding: 0;
    border: none !important;
}

.GeoTargeting-list-edit {
    display: flex;
}

.long-url-table-icon {
    display: flex;
    align-items: center;
}

.targeting-table-pagination table {
    width: 100%;
    vertical-align: middle;
}


@media (max-width: 767px) {
    .preview-box {
        border: 1px solid #ccc;
        border-radius: 16px;
        height: 650px;
    }

    .video-uploder-preview video {
        height: 300px;
        object-fit: cover;
    }

    .preview-body {
        padding: 16px;
        height: calc(650px - 219px);
    }

    .image-uploder-preview img {
        width: 100%;
        height: 305px;
        /* object-fit: cover; */
    }

    .preview-design iframe {
        width: 100%;
        height: 295px;
    }

}