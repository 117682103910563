body.dark {
    background: #111827;
    color: #fff !important;
}

.dark #header .pro-sidebar-inner {
    background-color: #192337;
}

.dark .pro-sidebar>.pro-sidebar-inner>.pro-sidebar-layout .pro-sidebar-footer {
    border-top: 1px solid rgba(173, 173, 173, 0.2);
    border-right: 1px solid rgba(173, 173, 173, 0.2);
    background: #192337;
}

.dark .dropdown .dropdown-toggle {
    background-color: #192337 !important;
    border-color: #373737 !important;
    color: #fff !important;
}

.dark .dashboard-card {
    background: #192337;
}

.dark #header .pro-sidebar-inner .pro-sidebar-layout .active {
    background: #0e18ca66 !important;
    border-radius: 6px;
}

.dark li.pro-menu-item.active .pro-inner-item {
    color: #ffffff !important;
}

.dark li.pro-menu-item.active span.pro-icon {
    color: #ffffff;
}

.dark #header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item,
.dark #header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper {
    color: #b3b3b3;
}

.dark h5.card-title {
    color: #fff;
}

.dark .card-subtitle {
    color: #fff;
}

.dark .total-traffic .total-traffic-number {
    color: #fff;
}

.dark .total-traffic-text {
    color: #fff;
}

.dark .custom-card-footer {
    border: 1px solid;
    border-color: rgb(65 69 107);
}

.dark .card-footer-item-title,
.dark .card-footer-item-number,
.dark .smartlist-title,
.dark .list-item-text-title,
.dark .Domain-table-content h4 {
    color: #fff;
}

.dark .graph-container span,
.dark .Back-btn-icon,
.dark .Back-btn-text {
    color: #fff;
}

.dark .Domain-table ul li,
.dark .tab-content-body,
.dark .API-key-list ul li,
.dark .brand-list ul li {
    background-color: #192337;
    /* background: #192337; */
    box-shadow: 0px 0px 11px -3px #192337;
}

.dark .nav-pills .technician-tab-link {
    color: #b3b3b3;
}

.dark .nav-pills .technician-tab-link:hover {
    color: #fff;
}

.dark .user-profile-box {
    background: #111827;
}

.dark .nav-pills .technician-tab-link.active,
.nav-pills .show>.technician-tab-link,
.nav-pills .technician-tab-link:hover {
    color: #fff;
}

.dark .did-floating-input,
.dark .did-floating-select,
.dark .did-floating-textarea,
.dark .form-control,
.dark .form-select {
    background: #192337;
    border: 1px solid #373737;
    color: #ffffff !important;
}

.dark .did-floating-label {
    color: #ffffff;
    background: #192337;
}

.dark .did-floating-input:focus,
.dark .did-floating-select:focus,
.dark .did-floating-textarea:focus,
.dark .form-control:focus {
    background-color: #192337;
    border: 1px solid #373737;
    color: #ffffff;
}

.accordion-item:not(:first-of-type) {
    border-top: none !important;
}

.accordion-button:not(.collapsed) {
    box-shadow: none !important;
}

.dark .Create-Template-Accordion .accordion-header button.accordion-button {
    background-color: #192337;
    border: 1px solid #373737;
    color: #ffffff;
}

.dark .Create-Template-Accordion .accordion-header button.accordion-button svg {
    color: #ffffff;
}

.dark .Create-Template-Accordion .accordion-header button.accordion-button::after,
.dark .accordion-button::after {
    background-image: url(../images/down.png);
}

.dark .did-floating-select:not([multiple]):not([size]) {
    background-image: url(../images/down-arrow-small.png);
}

.dark .form-label,
.dark .form-check-label {
    color: #fff;
}

.dark .user-profile-box svg {
    color: #fff;
}

.dark .accordion-button {
    background-color: #192337;
    color: #ffffff;
}

.dark .accordion-item {
    border: 1px solid #373737;
}

.dark .accordion-item:not(:first-of-type) {
    border-top: 1px solid #373737 !important;
}

.dark .accordion-button:not(.collapsed) {
    color: #ffffff;
    background-color: #ffffff1a;
    border-color: #373737;
}

.dark .password-design button.eye-btn {
    color: #fff !important;
    border-color: #373737 !important;
}

.dark .rangeslider__labels .rangeslider__label-item {
    color: #ffffff;
}

.dark .Domain-table-content h5 {
    color: #fff;
}

.dark .Vanity-card {
    background: #ffffff1a;
}

.dark .mini-page-list-inner-item {
    background-color: #192337;
    color: #ffffff;
}

.dark .total-click h6 {
    color: #fff;
}

.dark .publish_btn_container,
.dark .cFOcQM {
    background-color: #192337;
    color: #fff;
}

.dark .RightSide_fixed_bar {
    width: 95px;
    background-color: #ffffff1a;
    color: rgb(99, 99, 99);
}

.dark .RightSide_fixed_bar_list_item_link.oALEn.nav-link.active {
    background-color: rgb(25 35 55) !important;
    color: #fff !important;
    border-radius: 0px;
}

.dark .oALEn {
    height: 72px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 6px;
    background: #29303d;
    border: none;
    width: 95px;
    font-size: 12px;
    position: relative;
    color: #b3b3b3;
}

.dark .themes_sec_header,
.dark .section-inner-title h3 {
    color: #fff;
}

.dark .cvtPmc {
    background: #192337;
    margin: 0px 16px 16px;
    padding: 16px;
    border: 1px solid #373737;
    border-radius: 4px;
}

.dark .cDIdp div button svg,
.dark .ePxaWM,
.dark .image-input span.text,
.dark .fcYUrQ,
.dark .header-format-title,
.dark .gvofes {
    color: #fff;
}

.dark .icons_list_left svg,
.dark button.delete_btn {
    color: #fff;
}

.dark .Sidebar-Modal-end .custum-modal-body {
    background: #192337;
}

.Sidebar-Modal-end .modal-header {
    border-bottom: none !important;
}

.dark .modal-footer {
    background: #192337;
    border: 1px solid #373737;
}

.dark .input-field--input2 {
    color: #fff;
}

.dark .modal-content {
    background: #192337;
}

.dark .modal-dialog.modal-dialog-centered .modal-footer {
    background: #192337;
    border: 1px solid #373737;
}

.dark input {
    color-scheme: dark;
}

.dark .Update-GeoTargeting-list {
    background: #ffffff1a;
}

.dark .Domain-table ul li.qr-template-list-bg {
    background-color: #ffffff1a;
}

.dark .metrics-title-inner {
    color: #fff;
}

.dark .did-floating-label-content ol li,
.dark .did-floating-label-content b {
    color: #fff;
}

.dark .API-key-list ul li.API-key-list-item-bg {
    background-color: #ffffff1a;
}

.dark .API-key-list ul li.API-key-list-item-bg .API-key-list-item-left h3 {
    color: #fff;
}

.dark .API-key-list ul li.API-key-list-item-bg h5 {
    color: #fff;
}

.dark .setting-profile-content h3,
.dark .setting-profile-content h6 {
    color: #fff;
}

.dark .campain-view-header,
.dark .campain-view-header-subtitle p,
.dark .campain-time p,
.dark button.download-csv-btn,
.dark .campain-view-header h6 {
    color: #fff !important;
}

.dark button.btn.btn-default.btn-remove {
    color: #fff;
    background-color: #7367f0;
    border-color: #7367f0;
}

.dark .password-bottom-text h5 {
    color: #000;
}

.dark .smartlink-view-card {
    background-color: #192337;
}

.dark .tab-content-body .nav-tabs .nav-item .nav-link {
    color: #fff;
}

.dark .campain-view-header-title h3,
.dark .campain-view-header p {
    color: #fff;
}

.dark .choose-template-list-inner-item-content-title .page-title {
    color: #5D586C;
}

.dark .mini-page-list-inner-item.flex-template-list {
    background-color: #101726;
    color: #ffffff;
}

.dark small.text-muted.mt-3.form-text {
    color: #fff !important;
}

.dark .form-control::placeholder {
    color: #ffffff;
    opacity: 1;
    /* Firefox */
}

.dark .form-control::-ms-input-placeholder {
    /* Edge 12 -18 */
    color: #ffffff;
}


.dark .btn-copy svg, .dark .password-footer p {
    color: #ffffff;
}

.dark .password-footer {
    background: #111827;
}

.dark .custom-table th {
    background-color: #192337 !important;
}

.dark .custom-table tbody tr:nth-child(even) {
    background-color: #111828;
}

.dark .custom-table tbody tr:hover {
    background-color: #111828;
}

.dark .custom-table th, .dark .custom-table td {
    border: 1px solid #373737 !important;
}

.dark span.visit-detail-label,
.dark span.visit-detail-value {
    color: #A5A3AE;
}

.dark .btn-close {
    background-image: url(../images/close.png);
    opacity: 1;
}

.dark .Sidebar-Modal-end .modal-header .btn-close {
    background-image: url(../images/close-dark.png);
    opacity: 1;
}

.dark .sidebar-custom-drop.dropdown .sidebar-custom-drop-btn {
    color: #ffffff !important;
}

.dark .templates_list .form-check-label {
    color: #000;
}

.dark .templates_list .form-control {
    background-color: #fff;
    border: 1px solid #dee2e6;
    color: #000 !important;
}

.dark .templates_list .did-floating-label {
    color: #000;
    background-color: #fff;
}

.dark .details-box-smartlink, .dark .card-date {
    color: #ffffff;
}

.dark .auto_brand_card_body, .dark .w0Ne1v {
    background-color: #192337;
}

.dark .ai_chat_box, .dark .chat__input__input .chat-input {
    background: #101726;
}

.dark .chat__input__input .chat-input::placeholder {
    color: #ffffff;
}

.dark .effect-16 {
    color: #ffffff;
}

.dark .success-msg-text {
    color: #5D586C;
}

.dark .mini_title {
    color: #28cb73;
}