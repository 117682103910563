@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap');

body {
    margin: 0;
    font-family: 'Space Grotesk', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.body-section {
    background-color: #111827;
    color: #ffffff;
}

.header-section {
    transition: 0.9s;
}

.hero-section {
    padding: 260px 0px 160px;
}

.header-section.onscroll-class {
    display: none;
}

.hero-image img {
    width: 100%;
}

.hero-section {
    background-image: url("../Images/Hero-bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
    width: 100%;
}

.hero-title h1 {
    font-size: 57px;
    font-style: normal;
    font-weight: 700;
    line-height: 80px;
    font-family: 'Space Grotesk', sans-serif;
    margin-bottom: 30px;
}

.hero-description p {
    color: #94A3BB;
    font-family: Space Grotesk;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.hero-btn-group .btn-color {
    border-radius: 50px;
    background: var(--btncolor);
    width: 210px;
    height: 56px;
    color: #FFF;
    font-family: Space Grotesk;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 66px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
}

.hero-btn-group .btn-outline {
    width: 141px;
    height: 56px;
    font-family: Space Grotesk;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 66px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border: 1px solid #0e22de;
    background-clip: padding-box;
    border-radius: 50px;
    background: -webkit-linear-gradient(#ffffff, #ffffff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: 0.3s;
}

.hero-btn-group .btn-outline:hover {
    border: 1px solid #FC9B1C;
}

/* .hero-btn-group .btn-outline::after {
    content: '';
    position: absolute;
    top: -5px;
    bottom: -5px;
    left: -5px;
    right: -5px;
    background: linear-gradient(to bottom right, #22c1c3, #fdbb2d);
    z-index: -1;
    border-radius: 10px;
} */

.hero-btn-group {
    display: flex;
    gap: 30px;
    margin-top: 30px;
}

.images-content-wrap {
    position: relative;
}

.img-smartlink {
    position: absolute;
    right: -40px;
    bottom: 25%;
    z-index: 1;
    height: -moz-fit-content;
    height: fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.img-qrcode {
    position: absolute;
    right: -70px;
    top: 10%;
    z-index: 1;
    height: -moz-fit-content;
    height: fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.img-visitor {
    position: absolute;
    bottom: 9%;
    left: -7%;
    z-index: 1;
}

.img-visitor,
.img-qrcode {
    -webkit-animation: mover 3s infinite alternate;
    animation: mover 3s infinite alternate;
}

@-webkit-keyframes mover {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-30px);
    }
}

@keyframes mover {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-30px);
    }
}

.img-smartlink {
    animation: zoom-in-zoom-out 5s ease-out infinite;
}

@keyframes zoom-in-zoom-out {
    0% {
        transform: scale(0.8, 0.8);
    }

    50% {
        transform: scale(1, 1);
    }

    100% {
        transform: scale(0.8, 0.8);
    }
}


/* about section css start  */

.gradient-small-title h6 {
    font-family: Space Grotesk;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    background: -webkit-linear-gradient(#0b10e7, #24be79);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: 0.3s;
    position: relative;
    padding-left: 20px;
}

.gradient-small-title h6::before {
    content: '';
    position: absolute;
    width: 8px;
    height: 23px;
    top: 0;
    bottom: 0;
    left: 0px;
    right: 0;
    z-index: 1;
    background-image: linear-gradient(180deg, #0b13e6, #29d868);
    border-radius: 50px;
}

.main-title h2 {
    font-family: Space Grotesk;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 50px;
    color: #ffffff;
}

.description p {
    font-family: Space Grotesk;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #94A3BB;
}

.about-image img {
    height: 100%;
    width: 100%;
    transition: 0.3s;
}

.about-image img:hover {
    transform: scale(1.1);
}


.client-bg-box {
    background-image: url(../Images/Client.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100%;
    width: 100%;
}

.client-title h6 {
    font-size: 36px;
    font-family: Space Grotesk;
    font-style: normal;
    font-weight: 500;
    line-height: 50px;
    color: #ffffff;
    text-align: center;
    margin-bottom: 30px;
}

.client-bg-box {
    padding: 40px 10px;
}

.custom-padding {
    padding-bottom: 120px;
}

.slider-image {
    text-align: center;
}

.slider-image img {
    height: 25px;
    width: auto;
}


/* slider css start  */

.our-details-slider {
    margin-top: 50px;
}

.slider-box {
    border-radius: 9px;
    border: 1px solid #F79422;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 28px 18px;
    height: 100%;
}

.slider-icon {
    background-color: #0b10e7;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.slider-icon img {
    height: 40px;
    width: auto;
}

.slider-title h6 {
    color: #FFF;
    font-family: Space Grotesk;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 42px;
}

.slider-description {
    color: #FFF;
    font-family: Space Grotesk;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 24px 0px;
}

.slider-btn a {
    color: #FFF;
    font-family: Space Grotesk;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    padding: 0;
}

.slider-btn a img {
    height: 20px;
    width: auto;
}



.branding-image img {
    width: 100%;
}



.testimonial-box {
    background-color: #192337;
    border-radius: 10px;
    padding: 30px;
}

.rating-icon-list {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.rating-icon-list img {
    height: 20px;
}

.rating-icon-list svg {
    height: 20px;
    color: #0b12e6;
}

.testimonial-content {
    color: #FFF;
    font-family: Space Grotesk;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 30px;
}

.profile-image-home img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
}

.profile-details {
    display: flex;
    gap: 20px;
    align-items: center;
}

.profile-name h6 {
    color: #FFF;
    text-align: center;
    font-family: Space Grotesk;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.profile-name span {
    color: #FFF;
    text-align: center;
    font-family: Space Grotesk;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.contact-box {
    border-radius: 16px;
    /* background: linear-gradient(98deg, rgba(234, 76, 137, 0.20) 0.3%, rgba(77, 68, 198, 0.20) 100%);
    padding: 60px 0px; */
    text-align: center;
    /* margin-top: -120px; */
}

.contact-title {
    margin-bottom: 20px;
}

.contact-title h2 {
    color: #FFF;
    text-align: center;
    font-family: Space Grotesk;
    font-size: 64px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.contact-details {
    color: #FFF;
    text-align: center;
    font-family: Space Grotesk;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.join-button {
    margin-top: 40px;
}

.join-button .btn-color {
    border-radius: 50px;
    background: var(--btncolor);
    padding: 14px 40px;
    color: #ffffff;
    font-family: Space Grotesk;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    border: none;
}

.footer-section {
    /* background-image: url(../Images/footer.png); */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100%;
    width: 100%;
    background: #142330;
}

.custom-padding2 {
    padding-bottom: 240px;
}

.footer-box {
    text-align: center;
    padding-top: 90px;
}

.footer-logo {
    margin-bottom: 30px;
}

.footer-social ul {
    display: inline-flex;
    list-style: none;
    gap: 30px;
    padding: 0;
}

.footer-social ul li.footer-icon {
    width: 40px;
    height: 40px;
    border-radius: 6px;
    background: linear-gradient(180deg, #3B233C 0%, #23264B 100%);
    display: flex;
    justify-content: center;
}

.footer-social ul li.footer-icon a {
    color: #ffffff;
    font-size: 22px;
    transition: 0.3s;
}

.footer-social ul li.footer-icon a:hover {
    color: #0b0fe8;
    transform: scale3d(1.1);
}

.testimonial-box {
    margin-bottom: 20px;
}

.footer-end {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 50px;
    flex-wrap: wrap;
    gap: 10px;
}

.clearfix {
    clear: both;
}

.swiper-slide {
    height: auto !important;
}

.navbar-nav {
    flex-direction: row !important;
    gap: 10px;
}

/* responsive css start here   */

@media (max-width: 575px) {
    .header-section.onscroll-class {
        display: block;
    }

    .hero-section {
        padding: 160px 0px 120px;
    }

    .hero-title h1 {
        font-size: 50px;
        font-weight: 700;
        line-height: 56px;
        margin-bottom: 24px;
    }

    .images-content-wrap {
        margin-top: 50px;
    }

    .img-qrcode {
        right: 0;
        left: 0%;
        top: 10%;
    }

    .img-qrcode img {
        width: 200px;
    }

    .img-visitor img {
        width: 200px;
    }

    .img-smartlink img {
        width: 200px;
    }

    .img-smartlink {
        bottom: 42%;
    }

    .img-visitor {
        position: absolute;
        bottom: 0%;
        left: 0;
        z-index: 1;
    }

    .description p {
        font-size: 14px;
        line-height: 25px;
    }

    .main-title h2 {
        font-family: Space Grotesk;
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        line-height: 40px;
        color: #ffffff;
    }

    .client-bg-box {
        padding: 24px 10px;
    }

    .client-title h6 {
        font-size: 26px;
        line-height: 44px;
    }

    .custom-padding {
        padding-bottom: 90px;
    }

    .contact-box {
        border-radius: 16px;
        background: linear-gradient(98deg, rgba(234, 76, 137, 0.20) 0.3%, rgba(77, 68, 198, 0.20) 100%);
        padding: 30px 14px;
        text-align: center;
        margin-top: -60px;
    }

    .contact-title h2 {
        color: #FFF;
        text-align: center;
        font-family: Space Grotesk;
        font-size: 30px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .footer-section {
        height: 100%;
    }

    .branding-image {
        margin-top: 30px;
    }
}





@media (min-width: 576px) and (max-width: 767px) {
    .header-section.onscroll-class {
        display: block;
    }

    .hero-section {
        padding: 180px 0px 130px;
    }

    .hero-title h1 {
        font-size: 60px;
        font-weight: 700;
        line-height: 70px;
        margin-bottom: 24px;
    }

    .img-qrcode {
        right: 0;
        left: 0%;
        top: 10%;
    }

    .images-content-wrap {
        margin-top: 60px;
    }

    .img-qrcode img {
        width: 228px;
    }

    .img-visitor img {
        width: 228px;
    }

    .img-smartlink img {
        width: 228px;
    }

    .img-smartlink {
        bottom: 42%;
    }

    .img-visitor {
        position: absolute;
        bottom: 0%;
        left: 0;
        z-index: 1;
    }

    .custom-padding {
        padding-bottom: 100px;
    }

    .description p {
        font-size: 15px;
    }

    .branding-image {
        margin-top: 40px;
    }

    .contact-box {
        padding: 40px 20px;
        margin-top: -60px;
    }

    .contact-details {
        font-size: 14px;
    }

    .contact-title h2 {
        font-size: 40px;
    }
}


@media (min-width: 768px) and (max-width: 991px) {
    .hero-section {
        padding: 190px 0px 140px;
    }

    .hero-title h1 {
        font-size: 65px;
        line-height: 75px;
        margin-bottom: 30px;
    }

    .images-content-wrap {
        margin-top: 80px;
    }

    .img-qrcode {
        right: 0;
        left: 0%;
    }

    .img-qrcode img {
        width: 250px;
    }

    .img-visitor img {
        width: 250px;
    }

    .img-smartlink img {
        width: 250px;
    }

    .img-smartlink {
        bottom: 42%;
    }

    .img-visitor {
        position: absolute;
        bottom: 0%;
        left: 0;
        z-index: 1;
    }

    .custom-padding {
        padding-bottom: 120px;
    }

    .contact-box {
        padding: 60px 30px;
        margin-top: -60px;
    }

    .contact-title h2 {
        font-size: 50px;
    }

    .footer-section {
        height: 100%;
    }

    .branding-image {
        margin-top: 40px;
        text-align: center;
    }

    .branding-image img {
        width: 80%;
    }

    .about-image {
        text-align: center;
    }

    .about-image img {
        height: 100%;
        width: 80%;
        transition: 0.3s;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .hero-title h1 {
        font-size: 62px;
    }

    .img-qrcode img {
        width: 230px;
    }

    .img-qrcode {
        right: -18px;
        left: 0;
    }

    .img-visitor img {
        width: 230px;
    }
    
    .img-smartlink img {
        width: 230px;
    }

    .description p {
        font-size: 14px;
        line-height: 28px;
    }

    .testimonial-content {
        color: #FFF;
        font-family: Space Grotesk;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        margin-bottom: 30px;
        line-height: inherit;
    }

    .custom-padding2 {
        padding-bottom: 200px;
    }

    .contact-box {
        padding: 40px 60px;
    }

    .contact-title h2 {
        font-size: 50px;
    }

    .img-visitor {
        position: absolute;
        bottom: 0%;
        left: 0;
        z-index: 1;
    }

    .img-smartlink {
        bottom: 42%;
    }
}


@media (min-width: 1200px) and (max-width: 1399px) {
    .img-qrcode {
        right: 0;
        top: 0;
    }
}

@media (min-width: 1400px) and (max-width: 1500px) {
    .img-qrcode {
        right: -20px;
    }
}