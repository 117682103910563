.eventinfoblock .icons_list_item input{
    border-radius: 20px;
    border: 2px solid rgb(0, 255, 106);
}
.eventinfoblock .icons_list_item .select-wrapper .css-13cymwt-control{
    border: 2px solid rgb(0, 255, 106);
    border-radius: 20px;
}
.eventinfoblock .icons_list_item .form_block_group #basic-addon1{
    border: 2px solid rgb(0, 255, 106);
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}
.eventinfoblock .icons_list_item input:focus{
    border: 2px solid rgb(0, 81, 255);
}
.eventinfoblock .icons_list_item textarea{
    border-radius: 20px;
    border: 2px solid rgb(0, 255, 106);
}
.eventinfoblock .icons_list_item textarea:focus{
    border: 2px solid rgb(0, 81, 255);
}
.eventinfoblock .icons_list_item .icons_list_item_text_title{
    font-size:medium;
    font-weight: 600;
}
.eventinfoblock .icons_list_item .create-btn-wrapper button{
    border-radius: 5px;
    color: white;
    padding: 15px 0;
    font-size: medium;
}