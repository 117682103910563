.logo-icon {
    width: 250px;
    height: auto;
}

.color-text {
    color: #7367F0;
}

.login-head {
    color: #ffffff;
    font-size: 26px;
    font-weight: 700;
}

.Login-img {
    height: 100%;
    display: flex;
    align-items: center;
}

.custom-login-btn {
    background: var(--btncolor2);
    color: #fff !important;
    width: 100%;
    font-weight: 500;
    transition: 0.4s;
    border: none !important;
}

.referal-code-verify{
    /* background: linear-gradient(90deg, #0e22e4 , #22a69c , #2adc65); */
    background: var(--btncolor2) !important;
    width: 120px;
}

.Login-card {
    box-shadow: 0px 4px 18px rgba(75, 70, 92, 0.1);
    background-color: #142330;
}

.back-to-home {
    text-align: center;
}

.eye-btn {
    background-color: #0e23e4 !important;
    color: #fff;
    border: none;
    position: absolute;
    top: 0;
    right: 0;
    height: -webkit-fill-available;
}

/* .Login-section {
    margin: 60px 0px 0px;
} */

.Login {
    background: #111827;
    color: #ffffff;
}

.did-floating-input.dark-theam-input {
    background-color: #192337;
    border: 1px solid #373737;
    color: #ffffff;
}

.did-floating-label.dark-theam-lable {
    background-color: #192337;
    color: #fff;
    z-index: 1;
}

.did-floating-input.dark-theam-input:focus {
    background-color: #192337;
    border: 1px solid #373737;
    color: #ffffff;
}

.did-floating-input.dark-theam-input:focus .did-floating-label.dark-theam-lable {
    z-index: 1;
}



@media (max-width: 1199.99px) {
    .Login img.logo-icon {
        width: 200px;
    }

    .Login .form-outline.mb-4 {
        margin-bottom: 12px !important;
    }
}