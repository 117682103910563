.create-brand-tab {
    border-bottom: 1px solid #D9D9D9;
}

.create-brand-tab .nav-item,
.create-brand-tab>.nav-link {
    flex-grow: 1;
}

.create-brand-tab li button {
    color: #5D586C !important;
    border-bottom: 2px solid transparent !important;
    border-left: none !important;
    border-right: none !important;
    border-top: none !important;
    font-size: 15px;
    font-weight: 600;
    padding: 0px;
}

.create-brand-tab li {
    max-width: 150px;
}

.create-brand-tab li button.active {
    color: #7367F0 !important;
    border-bottom: 2px solid #7367F0 !important;
}

.createbrand-width .modal-dialog {
    min-width: 850px;
}

.createbrand-width .form-control-color {
    width: 100%;
}

.brand-button {
    display: flex;
    justify-content: end;
    margin-top: 24px;
}

button.Save-btn {
    color: #fff !important;
    background-color: #7367f0 !important;
    padding: 10px 20px;
    border: 1px solid #7367f0 !important;
}

button.Cancel-btn {
    color: #5D586C !important;
    background-color: #F9F9FA !important;
    padding: 10px 20px;
    border: 1px solid #F9F9FA !important;
}

.Vanity-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
}

.Vanity-title h4 {
    font-size: 18px;
    font-weight: 600;
    color: #5D586C;
}

.Vanity-card {
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 18px rgba(75, 70, 92, 0.1);
    margin-bottom: 16px;
    background-color: #fff;
}

.Vanity-card .Vanity-card-title h4 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0px;
}

.Vanity-card .Vanity-card-title p {
    color: #6b6b6b;
    margin-bottom: 0px;
    font-size: 15px;
}

.Vanity-card .Vanity-card-Status h5 {
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    background-color: #7367f0;
    padding: 2px 6px;
    border-radius: 4px;
}

.Vanity-card .Vanity-card-visits h4 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0px;
}

.Vanity-card .Vanity-card-visits p {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0px;
}

.preview-logo-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin-top: 20px;
}

.Vanity-card-title h5 {
    color: #fff;
    font-size: 25px;
    font-weight: 500;
    background-color: #7367f0;
    padding: 2px 6px;
    border-radius: 4px;
}
.butn {
    height: 48px;
}


@media (max-width: 991.98px) {
    .createbrand-width .modal-dialog {
        min-width: 650px !important;
    }
}

@media (max-width: 767.98px) {
    .createbrand-width .modal-dialog {
        min-width: 560px !important;
    }
}

@media (max-width: 575.98px) {
    .createbrand-width .modal-dialog {
        min-width: auto !important;
    }
}

@media (max-width: 479.98px) {
    .Vanity-card .Vanity-card-title p {
        margin-bottom: 16px !important;
    }

    .createbrand-width .modal-dialog {
        min-width: auto !important;
    }

    .create-brand-tab li button {
        font-size: 12px;
    }

    .create-brand-tab li button {
        font-size: 13px;
    }
}