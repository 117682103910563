.PricingTable {
    background-color: transparent !important;
}

.subscription-card {
    width: 300px;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    transition: box-shadow 0.3s ease;
}

.subscription-card:hover {
    box-shadow:
        0 5px 15px rgba(0, 0, 0, 0.1),
        0 0 5px rgba(255, 255, 255, 0.5),
        0 0 10px rgba(255, 255, 255, 0.5);
}