.sidebar-edit-qr .modal-dialog {
    right: 0;
    position: fixed;
    margin: auto;
    max-width: 800px !important;
    height: 100%;
    width: 100%;
}

.add-user-btn {
    background-image: var(--btncolor);
    border: none;
    height: 44px;
    padding: 0px 18px;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
}

.add-user-btn svg {
    margin-right: 7px;
    width: 20px;
    height: 20px;
    color: #fff;
}

.add-user-btn:hover {
    border: none;
}

.actions-btn {
    background: #7367F0 !important;
    border: 1px solid #7367F0 !important;
    height: 38px;
    padding: 0px 20px;
    font-size: 15px;
    font-weight: 500;
}

.actions-btn img {
    margin-right: 6px;
    color: #fff;
}

.actions-btn:hover {
    background: #7367F0;
    border: 1px solid #7367F0;
}

.table-card-header {
    justify-content: end;
    align-items: center;
    background: transparent;
    border: none;
}

.Sidebar-Modal-end .modal-header {
    background: linear-gradient(90deg, #0b10e7 0%, #2adf63 100%);
    color: #fff;
    padding: 14px 22px;
    border-radius: 0;
}

.Sidebar-Modal-end .modal-content {
    border-radius: 0px;
    height: 100%;
    overflow-y: auto;
}

.Sidebar-Modal-end .modal-dialog {
    right: 0;
    position: fixed;
    margin: auto;
    max-width: 700px !important;
    height: 100%;
    width: 100%;
}

.Sidebar-Modal-end .modal-title.h4 {
    font-size: 18px !important;
    font-weight: 500;
}

.Sidebar-Modal-end .modal-title.h4 svg {
    margin-right: 6px;
    width: 20px;
    height: 20px;
    color: #fff;
}

.Sidebar-Modal-end .modal-header .btn-close {
    background-color: #fff;
    padding: 10px;
    border-radius: 50%;
    opacity: 1;
    font-size: 12px;
}

.Sidebar-Modal-end .custum-modal-body {
    padding: 22px !important;
}

.Sidebar-Modal-end .modal-footer .btn {
    padding: 10px 20px;
    font-size: 15px;
    font-weight: 500;
    border-radius: 4px;
}

.Active-bttn {
    font-size: 16px;
    font-weight: 600;
    color: #fff !important;
    background-image: var(--btncolor);
    padding: 12px 20px;
    margin-right: 8px;
    border: none;
}

.Active-bttn:hover {
    background-color: #7367F0;
    color: #fff;
}

.Cancel-bttn {
    font-size: 16px;
    font-weight: 600;
    color: #fff !important;
    background-color: #ff0000 !important;
    /* width: 200px; */
    padding: 12px 20px;
    margin-right: 8px;
}

.image-preview {
    width: 120px;
    height: 120px;
    flex-shrink: 0;
    padding: 8px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fbfbfb;
    margin: 0px 16px 0px 0px;
    overflow: hidden;
}

.loading-screen {
    height: 100%;
    width: 100%;
    border: 3px dashed rgb(232, 238, 242);
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(111, 120, 127);
    text-transform: uppercase;
    font-size: 0.8rem;
}

.loading-screen img {
    width: 100%;
}

.block-upload {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.custom-file {
    position: relative;
    display: inline-block;
    width: 40%;
    height: calc(1.5em + .75rem + 2px);
    margin-bottom: 0;
}

.custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    margin: 0;
    overflow: hidden;
    opacity: 0;
}

.custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    overflow: hidden;
    font-weight: 400;
    line-height: 1.5;
    color: #fff;
    background-color: #007be7;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    text-align: center;
}

.user-profile-details-inner {
    display: flex;
}

.user-profile-name {
    margin-left: 18px;
}

.user-profile-name h4 {
    font-size: 26px;
    color: #6F6B7D;
    margin-bottom: 7px;
}

.user-profile-name p {
    font-size: 16px;
    color: #6F6B7D;
    margin-bottom: 7px;
}

.copy-btn {
    background: transparent;
    border: none;
    color: #8F85F3;
}

@media (max-width: 479.99px) {
    .add-user-btn {
        padding: 0px 16px;
    }
}