.main {
    /* background: linear-gradient(45deg, #3f7cf5, rgb(39 205 110)); */
    background: rgb(0, 15, 58);
    color: #fff;
}

.light-txt{
    color: hsla(0, 0%, 100%, .5);
}

.page-wrapper {
    min-height: 100dvh;
    padding-bottom: 4rem;
    display: flex;
    flex-direction: column;
}

.wide-page {
    min-width: 100dvw;
}

.nav-container {
    position: relative;
    width: 100%;
    max-height: 80px;
    padding: 0.75rem;
    display: flex;
    justify-content: space-between;
}

.nav-items ul {
    display: flex;
    align-items: center;
    gap: 3rem;
    list-style: none;
    margin: 0;
    padding: 0;
}

.nav-items ul li a {
    text-decoration: none;
    color: #fff;
    font-weight: 700;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

/* .page-container {
  flex-direction: column;
  align-items: center;
} */

.page-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* / max-width: 960px !important;/ */
}

.zm-container {
    padding: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}



.brand-container {
    max-width: 900px !important;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    /* // /margin-left: 270px;/ / margin-top: -80px;/ z-index: 1000; */
    position: relative;
    width: 100%;
    margin: 0 auto;
}

.brand-container .brand-logo{
    width: 150px !important;
    height: 100px;
    margin-top: -40px;
}
.brand-container .brand-logo img{
    width: auto;
    height: 100px;
    border-radius: 10px;
}

.brand-wrapper img {
    background: #fff;
}

.brand-info {
    margin: 0 auto;
}

.brand-desc {
    display: flex;
}
.brand-desc div{
    font-size: 18px !important;
}
.page-wrapper .card .card-body{
    background-color: rgba( 255, 255, 255, 0.04);
}

.dot-img{
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 100%;
    background-color: #fff;
}

.list-btns{
    background-color: rgba(255, 255, 255, 0.08);
    margin: 0;
    border-radius: 5px;
}

.list-btns.search{
    cursor: pointer;
    padding: 8px 8px;
    border-radius: 3px;
    color: rgba(255, 255, 255, 0.7);
}
.list-btns.search:hover{
    color: white;
    transition: color 0.6s ease;
}

.list-btns .tab-icon {
    cursor: pointer;
    padding: 3px 8px;
    border-radius: 3px;
    transition: background-color 0.9s ease, color 0.1s ease;
    color: rgba(255, 255, 255, 0.7);
}

.list-btns .tab-icon:hover {
    color: white;
}

.list-btns .tab-icon.active-tab {
    background-color: rgba(255, 255, 255, 0.3); 
    color: rgba(255, 255, 255, 0.9);
}

.card-wrapper{
   padding:  0.75rem 0.75rem 0.75rem 1rem;
}

.followers-icon .avatar:not(:first-child){
    margin-left: -13px;
}

.cover-container img {
    border-radius: 30px;
    max-width: 1024px !important;
    object-fit: cover;
    top: 0;
    left: 0;
}

@media (max-width: 820px) {
    .event-info-calendar {
        display: none;
    }
    .event-info-details {
        border: none;
    }
}

@media screen and (min-width: 1440px) and (max-width: 2560px) {
    .brand-container{
        max-width: 950px !important;
    }
    .brand-info{
        max-width: 950px !important;
    }
}

@media screen and (min-width: 990px) and (max-width: 1440px) {
    .brand-container{
        max-width: 820px !important;
    }
    .brand-info{
        max-width: 820px !important;
    }
    .cover-container img {
        max-width: 900px !important;
    }
}

@media screen and (min-width: 768px) and (max-width: 990px) {
    .brand-container{
        max-width: 660px !important;
    }
    .brand-info{
        max-width: 660px !important;
    }
    .cover-container img {
        max-width: 700px !important;
    }
    .event-info-details{
        border-right: none !important;
    }
}

@media screen and (min-width: 425px) and (max-width: 768px){
    .container {
        width: 100% !important;
        max-width: 100% !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .brand-container{
        max-width: 90% !important;
    }
    .brand-info{
        max-width: 90% !important;
    }
    .event-info-details{
        border-right: none !important;
    }
    .cover-container img {
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 0px !important;
        width: 100%;
        height: 60%;
        object-fit: cover;
    }
    .text-center .brand-container{
        margin-top: 170px;
    }
    .brand-container.d-flex{
        align-items: end !important;
        margin-top: 170px !important;
        margin-bottom: 30px !important;
    }
    .brand-logo img{
        margin-top: -30px !important;
        border-radius: 15px !important;
    }
    
}

@media screen and (max-width: 425px){
    .container {
        width: 100% !important;
        max-width: 100% !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .brand-container{
        max-width: 90% !important;
    }
    .brand-info{
        max-width: 90% !important;
    }
    .event-info-details{
        border-right: none !important;
    }
}

