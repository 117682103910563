#header {
    /* width: 260px; */
    height: 100%;
}

#header .closemenu {
    color: #000;
    position: absolute;
    right: 0;
    z-index: 9999;
    line-height: 20px;
    border-radius: 50%;
    font-weight: bold;
    font-size: 22px;
    top: 20px;
    cursor: pointer;
}

#header .pro-sidebar {
    width: 100%;
    min-width: 100%;
}

#header .pro-sidebar.collapsed {
    width: 80px !important;
    min-width: 80px;
}

#header .pro-sidebar-inner {
    background-color: white;
}

#header .pro-sidebar-inner .pro-sidebar-layout .logotext p {
    font-size: 20px;
    padding: 18px 18px;
    color: #000;
    font-weight: bold;
    margin-bottom: 0 !important;
}

#header .pro-sidebar-inner .pro-sidebar-layout ul {
    padding: 0 14px 0 14px;
}

#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
    color: #5D586C;
    margin: 4px 0px;
    font-weight: 500;
    font-size: 15px;
}

.pro-sidebar .pro-menu .pro-menu-item.active .pro-item-content {
    font-weight: 600;
}

#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper {
    background-color: transparent !important;
    color: #5D586C;
    border-radius: 3px;
}

#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper .pro-item-content {
    color: #000;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item>.pro-icon-wrapper .pro-icon svg {
    width: 22px;
    height: 22px;
}

#header .pro-sidebar-inner .pro-sidebar-layout .active {
    background: #F8F8F8 !important;
    border-radius: 6px;
}

#header .logo {
    padding: 20px;
}

.title-menu-section {
    font-size: 13px;
    padding: 14px 30px 14px 20px;
    color: #5D586C;
    font-weight: 500;
}

.pro-sidebar.collapsed .title-menu-section {
    display: none;
}

.pro-sidebar .pro-menu a:before {
    content: "";
    position: relative !important;
    left: 0;
    top: 0;
    width: 4px;
    height: 100%;
    background: #F8F8F8;
    border-radius: 6px 0px 0px 6px;
    display: none;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item {
    position: relative;
    display: flex;
    align-items: center;
    padding: 8px 35px 8px 5px;
    cursor: pointer;
}

.pro-sidebar .pro-menu a {
    text-decoration: none;
    color: #5D586C !important;
}

li.pro-menu-item.active .pro-inner-item {
    color: #7367f0 !important;
}

li.pro-menu-item.active span.pro-icon {
    color: #7367f0;
}

@media only screen and (max-width: 720px) {
    html {
        overflow-x: hidden;
    }
}

.logotext img.logo01 {
    padding: 18px;
    width: 180px;
    height: auto;
}

.logotext img.logo02 {
    padding: 18px;
    width: 72px;
    height: auto;
}

.sidebar-footer-notify,
.sidebar-footer-theme {
    font-size: 28px;
    color: #5D586C;
    background: var(--btncolor2);
    display: flex;
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 50%;
    border: none;
    margin-left: 10px;
    padding: 0px;
}

iframe.noticeable-trigger-view {
    margin-left: 20px;
}

.select-box-list {
    display: flex;
}

.select-box-list-item {
    margin: 0px 10px;
    width: 100%;
}

.closemenu-mobile {
    display: none;
}

.sidebar-custom-drop-btn {
    background: transparent !important;
    border: none !important;
    padding: 0 !important;
}


.logout-btn {
    background: transparent !important;
    border: none;
    padding: 0px;
}

.logout-btn:hover {
    background: transparent !important;
    border: none;
    padding: 0px;
}

a.sidebar-custom-drop-item.dropdown-item {
    color: #5D586C;
}

a.sidebar-custom-drop-item.dropdown-item svg {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

a.sidebar-custom-drop-item.dropdown-item:hover {
    background: #F8F8F8;
    border-radius: 6px;
    color: #5D586C;
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background-color: #ebebeb;
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #b3b3b3;
}

.sidebar-btn-drop {
    background: transparent !important;
    border: none !important;
    padding: 0 !important;
}

.sidebar-custom-drop.dropdown {
    width: auto;
}

.sidebar-custom-drop.dropdown .sidebar-custom-drop-btn {
    color: rgb(99, 99, 99) !important;
    border: 1px solid rgb(179, 179, 179);
    border-radius: 4px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    background-color: transparent !important;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
}

/* .sidebar-footer-notify {
    background-color: #ed1c24;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
} */

.sidebar-footer-notify svg {
    width: 20px;
    height: 20px;
    color: #ffffff;
}

#noticeable-icon svg {
    width: 20px;
    height: 20px;
    color: #fff;
}

.pro-sidebar>.pro-sidebar-inner>.pro-sidebar-layout .pro-sidebar-footer {
    border-top: 1px solid rgba(173, 173, 173, 0.2);
    border-right: 1px solid rgba(173, 173, 173, 0.2);
    background: #f8f8f8;
}

.checkbox-theame-change {
    opacity: 0;
    position: absolute;
}

.checkbox-label {
    background-color: #192337;
    width: 50px;
    height: 26px;
    border-radius: 50px;
    position: relative;
    padding: 5px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.checkbox-label .ball {
    background-color: #fff;
    width: 22px;
    height: 22px;
    position: absolute;
    left: 2px;
    top: 2px;
    border-radius: 50%;
    transition: transform 0.2s linear;
}

.checkbox-theame-change:checked+.checkbox-label .ball {
    transform: translateX(24px);
}

.sun-icon {
    color: #f39c12;
}

.moon-icon {
    color: #f1c40f;
}

.theme-switch {
    margin-left: 20px;
}

#noticeable-widget #icon svg {
    fill: blue !important;
    color: #1200d3 !important;
}

#icon svg {
    fill: blue !important;
    color: #1200d3 !important;
}

.selected-logo {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 5px;
}

.brand-logo {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 5px;
}

.country-option {
    display: flex !important;
    flex-direction: row;
    align-items: center;
}

.css-tr4s17-option {
    min-width: 0;
}

.brand-name {
    font-size: 15px;
    margin-bottom: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.brand-select .css-1n6sfyn-MenuList {
    max-height: 100px;
    height: 100%;
}

.brand-select .css-1n6sfyn-MenuList::-webkit-scrollbar {
    width: 3px;
}
@media (max-width:991.98px) {
    .sidebar-container {
        display: none;
    }

    .sidebar-container.new-class-name {
        display: block;
        z-index: 1;
        bottom: 0;
    }

    .sidebar-container.new-class-name::before {
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #000;
        opacity: 0.5;
        z-index: 100;
    }

    .closemenu {
        display: none;
    }

    .closemenu-mobile {
        position: absolute;
        right: 12px;
        z-index: 9;
        line-height: 20px;
        border-radius: 50%;
        font-weight: bold;
        font-size: 22px;
        top: 14px;
        cursor: pointer;
    }

    .closemenu-mobile {
        background: #fff;
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #1200d3;
    }

    .sidebar-container.class-none {
        display: none;
    }

    /* #header .pro-sidebar {
        height: calc(100vh - 0px);
    } */

    .stop.new-class-name {
        overflow: hidden;
    }
}