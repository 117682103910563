.table-card {
    padding: 20px;
}

h5.card-title {
    font-size: 20px;
    color: #6F6B7D;
    margin-bottom: 18px;
    font-weight: 600;
}

.Yes-Active-bttn {
    background-color: #7367F0 !important;
    color: #fff !important;
    padding: 3px 14px;
    border-radius: 50px;
    border: 1px solid #7367F0 !important;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
}

.Create-Template {
    margin-top: 20px;
}

.Create-Template-Accordion .accordion-item {
    margin-bottom: 10px;
    border: none;
}

.Create-Template-Accordion .accordion-header button.accordion-button {
    border: 2px solid #ccc;
    border-radius: 6px;
    padding: 10px 16px;
    font-size: 16px;
    font-weight: 500;
    color: #6F6B7D;
}

.Create-Template-Accordion .accordion-header button.accordion-button svg {
    font-size: 24px;
    margin-right: 10px;
    color: #000;
}

.Qr-pattern {
    width: 50px;
    height: 50px;
    padding: 2px;
    margin-bottom: 12px;
    border-radius: 0 !important;
}

.Qr-pattern.active {
    border: 2px solid #7367F0;
}

.hide-section {
    display: none;
}

.hide-section .active {
    display: block;
}

.Domain-table-action {
    margin: 10px 0px;
}

.qr-template-mobile-show {
    margin-top: 14px;
    padding: 0;
}

.qr-template-card {
    background: #fff;
    padding: 1.4rem;
}

.qrtemplate-code-item {
    padding: 0px 0px 16px;
}

.qr-code-details {
    margin-bottom: 26px;
}

.qr-template-mobile-show {
    display: none;
}

.card-content-header-right a {
    text-decoration: none;
}

.brand_table_view {
    width: 80%;
}

.Domain-table-action {
    margin: 10px 0px;
    text-decoration: none;
}

.accordion {
    --bs-accordion-bg: transparent;
}

.accordion-button {
    background-color: #fff;
}

.delete-modal-title {
    display: flex;
    align-items: center;
}

.qr-bg-col {
    background-color: #000;
    margin: 0 auto;
    padding: 20px;
    border-radius: 10px;
}

.canvas-title {
    margin-top: 14px;
    display: none;
}

.qr-bg-col .canvas-title {
    display: block;
}

.Qr-image canvas {
    width: 100%;
    max-width: initial;
    overflow: hidden;
}

.canvas-title h2 {
    color: #fff;
    font-size: 30px;
    font-weight: 600;
    text-align: center;
}

.canvas-title2 {
    margin-top: 8px;
    display: none;
}

.qr-bg-col2 .canvas-title2 {
    margin-top: 8px;
    display: block;
}

.canvas-title2 h2 {
    color: #fff;
    font-size: 30px;
    font-weight: 600;
    text-align: center;
}

.qr-bg-col2 {
    background-color: #000;
    margin: 0 auto;
    padding: 20px;
    border-radius: 10px;
}

.qr-bg-col3 .canvas-title3 {
    margin: 0 auto;
    width: 100%;
    margin-top: 20px;
    position: relative;
    display: block;
    text-align: center;
}

.qr-bg-col3 .canvas-title3:before {
    position: absolute;
    content: " ";
    width: 0;
    height: 0;
    display: block;
    border-right: 25px solid transparent;
    border-bottom: 25px solid #000;
    border-left: 25px solid transparent;
    top: -20px;
    right: 44%;
}

.qr-bg-col3 .canvas-title3 h2 {
    color: #fff;
    font-size: 30px;
    font-weight: 600;
    background-color: #000;
    width: auto;
    padding: 10px 20px;
    display: block;
}

.canvas-title3 {
    display: none;
}

.qr-bg-col3 canvas {
    border: 16px solid #000;
    border-radius: 8px;
}

.Qr-pattern img {
    max-width: 100%;
    height: 100%;
}

@media (min-width: 1290px) and (max-width: 1540px) {
    .brand_table_view {
        width: 75%;
    }
}

@media (min-width: 1160px) and (max-width: 1289px) {
    .brand_table_view {
        width: 70%;
    }
}

@media (max-width: 767px) {
    .table-card {
        padding: 10px;
    }
}

@media (max-width: 478.98px) {
    .Qr-pattern {
        width: 40px;
        height: 40px;
    }
}

@media (max-width: 425px) {
    .qr-mobile-hide {
        display: none;
    }

    .qr-template-mobile-show {
        display: block;
    }
}

@media (max-width: 478.98px) {
    .Qr-pattern {
        width: 40px;
        height: 40px;
        border-radius: 0 !important;
    }
}