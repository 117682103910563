

/* Common styles for all events */
.fc-event {
    margin: 9px 10px;
    padding: 0px 0px;
    border-radius: 4px;
    width: 200px;
    font-size: 14px;
    transition: transform 0.3s, z-index 0.3s, box-shadow 0.3s;
}

/* Styles for the custom event class */
.custom-event-class {
    border-color: transparent !important;
    background-color: transparent !important;
    border: none !important;
    width: 200px;
    height: 69px;
}

/* Timegrid (Week and Day view) specific styles */
.fc-timegrid-slot {
    min-height: 120px;
}

.fc-timegrid {
    min-height: 600px;
}

/* Event container styles for all views */
.fc-timegrid-event-harness,
.fc-daygrid-event-harness,
.fc-h-event {
    transition: z-index 0.3s;
}

/* Hover styles for event containers in all views */
.fc-timegrid-event-harness:hover,
.fc-daygrid-event-harness:hover,
.fc-h-event:hover {
    z-index: 1000 !important;
}

/* Hover styles for the events themselves in all views */
.fc-timegrid-event:hover,
.fc-daygrid-event:hover,
.fc-h-event:hover,
.custom-event-class:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

/* Additional styles for month view to ensure proper sizing */
.fc-daygrid-day-events {
    min-height: 1.5em;
}

/* Ensure events in month view don't overflow their containers */
.fc-daygrid-event {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Custom event content styles */
.custom-event-content {
    display: flex;
    align-items: center;
    padding: 5px;
    height: 100%;
}

.event-icon {
    margin-right: 8px;
}

.event-details {
    display: flex;
    flex-direction: column;
}

.event-time {
    font-size: 12px;
    font-weight: bold;
}

.event-title {
    font-size: 14px;
}

/* Modal styles */
.modal_post_details .post-box-design {
    width: 100%;
}

.modal_post_details .user-profile-img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.modal_post_details .post-header-details {
    display: flex;
    align-items: center;
}

.modal_post_details .post-header {
    display: flex;
    align-items: center;
}

.modal_post_details .post-header>div {
    margin-left: 10px;
}

.modal_post_details .image-grid-item img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.btn-action {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 0.75rem;
    /* border: 1px solid #ccc; */
    border-style: none;
    background-color: #f8f9fa;
    transition: background-color 0.2s;
}

.btn-action:hover {
    background-color: #e9ecef;
}

.btn-calendar{
    background: #ffa900 !important;
    color: white;
}
.btn-calendar:hover{
    color: white !important;
}
.btn-square {
    border-radius: 0 !important;
}

.btn-comment{
    background: blueviolet !important;
    color: white !important;
}