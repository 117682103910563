.Login {
    background-color: #fff;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Login__container {
    overflow-y: auto;
    padding: 50px 25px;
    border-radius: 0;
    box-shadow: 0px 0px 20px 0px #ccc;
}

.Login-image {
    max-width: 600px;
    height: auto;
}

.Login_content h1 {
    font-size: 36px;
    color: #9e2882;
    font-weight: 600;
}

.Login_content p {
    font-size: 20px;
    font-weight: 500;
}

.Login label.form-label,
.Login label.form-check-label,
.Login-signup p {
    font-size: 20px;
    font-weight: 600;
}

.Login input {
    font-size: 20px;
    border: 1px solid #959595;
}

.Login .form-check .form-check-input {
    margin-left: -24px;
    margin-right: 12px;
    border-radius: 50%;
    box-shadow: none;
}

.Login .Login-signup .form-check-input {
    border-radius: 50%;
    box-shadow: none;
}

.Login .form-check-input:checked {
    background-color: #9e2882;
    border-color: #9e2882;
}

.Login-forgot a {
    font-size: 20px;
}

.Login-forgot a,
.Login-signup a {
    color: #9e2882;
    font-weight: 700;
}

@media (max-width: 767.98px) {
    .Login__container {
        width: 100%;
        height: 100%;
        border-radius: 0;
    }
}

@media (max-width: 479.98px) {
    .Login__container {
        padding: 20px 10px;
    }

    .Login_content h1 {
        font-size: 28px;
    }

    .Login_content p {
        font-size: 16px;
    }
}

.accept_form_box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
}

.accept_form_box_inner {
    background-color: #fff;
    padding: 30px 25px;
    border-radius: 0;
    box-shadow: 0px 0px 20px 0px #ccc;
    width: 600px;
    height: auto;
    border-radius: 6px;
}

.form_title {
    font-size: 36px;
    color: #9e2882;
    font-weight: 600;
    margin-bottom: 30px;
    text-align: center;
}

.accept_btn {
    background-color: #9e2882 !important;
    border-color: #9e2882 !important;
    color: #fff !important;
    font-size: 16px;
    font-weight: 600;
    padding: 10px 20px;
    border-radius: 6px;
    width: 100%;
    margin-top: 20px;
    height: 55px;
}