.contact-form label.form-label {
    font-size: 20px;
    font-weight: 700;
}

.contact-form input, .contact-form textarea {
    font-size: 20px;
    border: none;
    border-bottom: 2px solid #9e2882;
    border-radius: 0;
    box-shadow: none;
}

.contact-form .form-control:focus{
    box-shadow: none;
    border-bottom: 2px solid #9e2882;
}