.content-generation-enabled {
    display: flex;
    -webkit-box-flex: 1;
    flex-grow: 1;
    height: 100%;
    overflow: hidden;
}

.cwfpBk {
    flex-basis: 16rem;
    width: 16rem;
    min-width: 16rem;
}

.fLziUH {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    box-sizing: border-box;
    background: rgb(255, 255, 255);
    border-right: 0.063rem solid rgb(224, 224, 224);
    height: 100%;
    -webkit-box-pack: start;
    justify-content: flex-start;
}

.social-tab-content {
    display: flex;
    flex-direction: column;
    -webkit-box-flex: 1;
    flex-grow: 1;
    height: 100%;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    background-color: #fff;
}

/* .hPOmAZ {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    -webkit-box-flex: 1;
    flex-grow: 1;
    height: 1px;
} */

.cJMQxa {
    padding: 24px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.tab-inner-dropdown-icon {
    margin-right: 8px;
    height: 16px;
    width: 16px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
}

.tab-inner-dropdown-icon svg {
    height: 18px;
    width: 18px;
    fill: rgb(184, 184, 184);
}

.tab-inner-dropdown-button {
    background: transparent;
    border: none;
    display: flex;
    padding: 10px;
    align-items: center;
    width: 100%;
}

.tab-inner-dropdown-button:hover {
    cursor: pointer;
    background-color: #fbfbfb;
}

.divider {
    border-bottom: 0.063rem solid rgb(224, 224, 224);
    margin: 12px 0px;
}

.tab-inner-dropdown-text {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
}

.social-tab-link {
    display: flex;
    align-items: center;
    -webkit-box-pack: start;
    justify-content: flex-start;
    padding: 10px;
}

.social-tab-link .channel-list-box-icon img {
    width: 32px;
    height: 32px;
}

.social-tab-link .channel-list-box-icon .bdyGBn {
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    background: rgb(24, 119, 242);
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateX(2px) translateY(0px);
    border: none;
    font-size: 12px;
    border: 2px solid #fff;
}

.social-tab-link .tab-user-name {
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    color: rgb(61, 61, 61);
}

.media-post-tab .social-tab-link {
    padding: 14px 6px;
}

.media-post-tab .social-tab-link.active {
    background-color: #2c3e50 !important;
}

.media-post-tab-top .social-tab-link {
    padding: 4px 40px 4px 8px;
}

.media-post-tab-top .social-tab-link.active {
    background-color: #a2b2c0 !important;
    border-radius: 0;
}

.media-post-tab-top .social-tab-link .user-profile-box {
    background: transparent;
}

.media-post-tab-top .social-tab-link .user-profile-box svg {
    color: #000;
}

.media-post-tab-top .social-tab-link.active .user-profile-box svg {
    color: #000;
}

.media-post-tab-top .social-tab-link .tab-user-name {
    margin-left: 4px;
    color: #000 !important;
    font-weight: 800;
    font-size: 16px;
}

.social-tab-link.active {
    background-color: #0d1be5 !important;
}

.social-tab-link .user-profile-box svg {
    color: #0d1be5;
}

.social-tab-link.active .user-profile-box svg {
    color: #0d1be5;
}

.social-tab-link.active .tab-user-name {
    color: #fff;
}

.social-tab-link:hover {
    cursor: pointer;
    background-color: #fbfbfb;
}

.posted-details {
    display: flex;
    align-items: baseline;
    gap: 8px;
    margin: 20px 0px;
}

.post-day {
    font-size: 22px;
    font-weight: 700;
}

.social-tab-link {
    margin: 10px 0px;
}


/* new css start  */

.user-profile-img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.user-name-text {
    font-size: 18px;
    font-weight: 700;
}

.post-date {
    margin-bottom: 0;
    font-size: 14px;
}

.post-header-details {
    padding: 16px;
    /* border-bottom: 1px solid rgb(224, 224, 224); */
}

.post-body {
    padding: 16px;
}

.post-box-design {
    width: 500px;
}

.image-grid-item img {
    width: 100%;
    height: 100%;
}

.social-image-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 10px;
}

.image-grid-item {
    position: relative;
}

.view-more {
    position: absolute;
    bottom: 0px;
    right: 0px;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 5px;
    width: 100%;
    height: 100%;
}

.view-more button {
    background: none;
    border: none;
    color: #333;
    cursor: pointer;
    width: 100%;
    height: 100%;
    font-size: 20px;
    font-weight: 700;
}

.view-more button:hover {
    color: #666;
}

.add-post-btn {
    background-color: #2c3e50 !important;
    border: none;
    height: 40px;
    padding: 0px 16px;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
}

button.btn.add-more-post-btn.me-0 {
    background: #dfe1e7 !important;
    width: 100%;
    font-weight: 500;
    color: #2c3e50 !important;
    height: 42px;
}

.remove-btn {
    background: #dfe1e7 !important;
    font-size: 18px;
    width: 30px;
    height: 30px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #2c3e50 !important;
}

.post-edit-btn {
    background-color: transparent !important;
    color: #0d1be5 !important;
    font-size: 24px;
    padding: 0;
    border: none;
}

.post-box-design {
    width: calc(45% - 10px);
    margin-bottom: 20px;
}

.bg-white {
    --bs-bg-opacity: 1;
    background-color: #f3f3f3 !important;
}


.ai-modal-new {
    position: fixed;
    right: 710px;
    background: #fff;
    height: 100%;
    top: 0%;
    width: 500px;
}

.ai-input-box {
    margin-bottom: 20px;
    position: relative;
}

.ai-input-box input {
    width: 100%;
    border: 0;
    padding: 7px 0;
    border-bottom: 1px solid #ccc;
    height: 70px;
}

.ai-input-box input:focus-visible {
    outline: none;
}

.effect-1~.focus-border {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-color: #4caf50;
    transition: 0.4s;
}

.effect-1:focus~.focus-border {
    width: 100%;
    transition: 0.4s;
}

button.ai-btn {
    display: flex;
    align-items: center;
    gap: 12px;
    background-image: linear-gradient(45deg, #6a22fc, #a766fe);
    border: none;
    height: 55px;
    width: 200px;
    justify-content: center;
    color: #fff;
    border-radius: 40px;
    font-size: 18px;
    font-weight: 800;
}

.ai-view-btn {
    border: 1px solid #ccc !important;
    color: #000 !important;
}

p.ai-result {
    margin: 20px 0px;
    font-size: 16px;
    color: #000;
}

button.ai-btn-insert {
    background-color: #0c1ae1;
    border: none;
    height: 42px;
    color: #fff;
    border-radius: 5px;
    font-size: 16px;
    width: 86px;
}


.loader-59 {
    width: 48px;
    height: 48px;
    display: inline-block;
    position: relative;
    transform: rotate(45deg);
}

.loader-59::before {
    content: '';
    width: 24px;
    height: 24px;
    position: absolute;
    left: 0;
    top: -24px;
    animation: animloader59 4s ease infinite;
}

.loader-59::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 24px;
    height: 24px;
    background: rgba(255, 255, 255, 0.85);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    animation: animloader56 2s ease infinite;
}


.media-modal-new {
    position: fixed;
    right: 710px;
    background: #fff;
    height: 100%;
    top: 0%;
    width: 50%;
    z-index: 9999;
}

.media-modal-new-event {
    position: fixed;
    right: 430px;
    background: #fff;
    height: 100%;
    top: 0%;
    width: 55%;
    z-index: 9999;
}

.media-modal-new-event {
    overflow-x: hidden;
    overflow-y: auto;
}

.media-modal-new-event::-webkit-scrollbar {
    display: none;
}

.media-modal-new-event {
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.media-modal-new {
    overflow-x: hidden;
    overflow-y: auto;
}

.media-modal-new::-webkit-scrollbar {
    display: none;
}

.media-modal-new {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.search-box {
    margin: 20px 0px;
    display: flex;
    align-items: center;
    gap: 20px;
    width: 100%;
}

.search-box-input {
    width: 100%;
}

.search-input {
    background-color: #fff;
    height: 55px;
    border: 1px solid #ccc;
    width: 100%;
    padding-left: 16px;
    border-radius: 4px;
}

.search-input:focus-visible {
    outline: none;
}

button.search-button {
    height: 55px;
    width: 170px;
    background: #1f90c0;
    color: #fff;
    border: 1px solid #1f90c0;
    font-size: 16px;
    font-weight: 800;
    border-radius: 4px;
}

.images-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.images-list .image-box {
    width: calc(100% / 3 - 10px);
    margin-bottom: 20px;
    cursor: pointer;
    height: fit-content;
}

.images-list .image-box.active {
    border: 4px solid #1f90c0;
}

.images-list .image-box img {
    width: 100%;
}

.videos-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.videos-list .video-box-media {
    width: calc(100% / 3 - 10px);
    margin-bottom: 20px;
    cursor: pointer;
    height: fit-content;
}

.videos-list .video-box-media.active {
    border: 4px solid #1f90c0;
}

.videos-list .video-box-media video {
    width: 100%;
    height: 100%;
}

.gifs-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.gifs-list .gif-box {
    width: calc(100% / 3 - 10px);
    margin-bottom: 20px;
    cursor: pointer;
    height: fit-content;
}

.gifs-list .gif-box.active {
    border: 4px solid #1f90c0;
}

.gifs-list .gif-box img {
    width: 100%;
}





.uploader {
    display: block;
    clear: both;
    margin: 0 auto;
    width: 100%;
    max-width: 600px;
}

.uploader label {
    float: left;
    clear: both;
    width: 100%;
    padding: 2rem 1.5rem;
    text-align: center;
    background: #fff;
    border-radius: 7px;
    border: 3px solid #eee;
    transition: all 0.2s ease;
    user-select: none;
}

.uploader label:hover {
    border-color: #454cad;
}

.uploader label.hover {
    border: 3px solid #454cad;
    box-shadow: inset 0 0 0 6px #eee;
}

.uploader label.hover #start i.fa {
    transform: scale(0.8);
    opacity: 0.3;
}

.uploader #start {
    float: left;
    clear: both;
    width: 100%;
}

.uploader #start.hidden {
    display: none;
}

.uploader #start i.fa {
    font-size: 50px;
    margin-bottom: 1rem;
    transition: all 0.2s ease-in-out;
}

.uploader #response {
    float: left;
    clear: both;
    width: 100%;
}

.uploader #response.hidden {
    display: none;
}

.uploader #response #messages {
    margin-bottom: 0.5rem;
}

.uploader #file-image {
    display: inline;
    margin: 0 auto 0.5rem auto;
    width: auto;
    height: auto;
    max-width: 180px;
}

.uploader #file-image.hidden {
    display: none;
}

.uploader #notimage {
    display: block;
    float: left;
    clear: both;
    width: 100%;
}

.uploader #notimage.hidden {
    display: none;
}

.uploader progress,
.uploader .progress {
    display: inline;
    clear: both;
    margin: 0 auto;
    width: 100%;
    max-width: 180px;
    height: 8px;
    border: 0;
    border-radius: 4px;
    background-color: #eee;
    overflow: hidden;
}

.uploader .progress[value]::-webkit-progress-bar {
    border-radius: 4px;
    background-color: #eee;
}

.uploader .progress[value]::-webkit-progress-value {
    background: linear-gradient(to right, #393f90 0%, #454cad 50%);
    border-radius: 4px;
}

.uploader .progress[value]::-moz-progress-bar {
    background: linear-gradient(to right, #393f90 0%, #454cad 50%);
    border-radius: 4px;
}

.uploader input[type="file"] {
    display: none;
}

.uploader div {
    margin: 0 0 0.5rem 0;
    color: #5f6982;
}

.uploader #messages {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.uploader .btn {
    display: inline-block;
    margin: 0.5rem 0.5rem 1rem 0.5rem;
    clear: both;
    font-family: inherit;
    font-weight: 700;
    font-size: 14px;
    text-decoration: none;
    text-transform: initial;
    border: none;
    border-radius: 0.2rem;
    outline: none;
    padding: 0 1rem;
    height: 36px;
    line-height: 36px;
    color: #fff;
    transition: all 0.2s ease-in-out;
    box-sizing: border-box;
    background: #454cad;
    border-color: #454cad;
    cursor: pointer;
}

.post-footer {
    border-top: 1px solid #ccc;
}

.post-footer-inner {
    padding: 16px;
    display: flex;
    align-items: center;
    gap: 30px;
}

.post-footer-inner svg {
    cursor: pointer;
    width: 24px;
    height: 24px;
}

.post-footer-inner div {
    display: flex;
    align-items: center;
    gap: 10px;
    align-items: center;
}

.post-footer-inner div p {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    color: #212121;
}

/*              Nihal Css Change 24/06/2024   start here         */


/* .all-post-list {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
} */



/* .tab-content {
    background: #f0f2f5;
}

.post-body {
    padding: 16px;
    margin-bottom: 1rem;
} */


.video-grid-item video {
    width: 100%;
    height: auto;
}


/*              Nihal Css Change 24/06/2024    end here        */



.modal_post_details .post-box-design {
    width: 100%;
}

.modal_post_details .post-box-design .image-grid-item,
.image-grid-item {
    background-color: #000;
    padding: 20px;

}

.modal_post_details .post-box-design .video-grid-item,
.video-grid-item {
    background-color: #000;
    padding: 20px;
}

.modal_post_details .post-box-design .image-grid-item img,
.image-grid-item img {
    height: 300px;
    width: 100%;
    object-fit: contain;
}

.image-grid-item img {
    height: 400px;
    width: 100%;
    object-fit: contain;
}

.video-grid-item video {
    height: 400px;
    width: 100%;
    object-fit: contain;
}

.post-footer-btn {
    padding: 0px 16px 16px 16px;
}

.save-post-btn {
    background-image: linear-gradient(to right, #02b653 0%, #02b653 50%) !important;
}

.save-post-btn:hover {
    background-image: linear-gradient(to right, #02b653 0%, #02b653 50%) !important;
}

.move-to-draft-btn {
    background-image: linear-gradient(to right, #834fe2 0%, #834fe2 50%) !important;
}

.move-to-draft-btn:hover {
    background-image: linear-gradient(to right, #834fe2 0%, #834fe2 50%) !important;
}

.post-box-design .swiper-button-prev,
.swiper-button-next {
    width: 36px !important;
    background: #fff;
    height: 36px !important;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000 !important;
}

.post-box-design .swiper-button-prev:after,
.swiper-button-next:after {
    font-size: 20px !important;
    color: #000;
}

.post-box-design .swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
    opacity: 0 !important;
}

.post-box-design .swiper-pagination-bullet {
    background-color: #ffffff !important;
    width: 10px !important;
    height: 10px !important;
}

.create_btn {
    height: 42px;
    padding: 0 16px;
    font-size: 14px;
    line-height: 24px;
    border-radius: 8px;
    background: #2c4bff;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    border: none;
    cursor: pointer;
    border-radius: 4px;
}

.dnd_header h3 {
    font-size: 18px;
    font-weight: 700;
    color: #000;
}

.dnd_post_image_grid {
    height: 120px;
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 4px;
}

.dnd_post_image_grid[data-media-count="1"] {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
}

.dnd_post_image_grid[data-media-count="2"] {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
}

.dnd_post_image_grid[data-media-count="3"] .dnd_image-grid-item:first-child {
    grid-row: 1 / span 2;
}

.dnd_image-grid-item {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.dnd_image-grid-item img {
    display: block;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
}

.dnd_video-grid-item {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.dnd_video-grid-item video {
    display: block;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
}

.dnd_post_image_grid[data-media-count="3"] .dnd_video-grid-item:first-child {
    grid-row: 1 / span 2;
}

.dnd_post_details {
    padding: 16px;
}

.dnd_post_title h4 {
    font-size: 18px;
    font-weight: 700;
    color: #000;
}

.dnd_post_caption {
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.dnd_post {
    position: relative;
}

.dnd_post:hover {
    cursor: pointer;
}

.dnt_button_overlay {
    display: none;
}

.dnd_post:hover .dnt_button_overlay {
    display: flex;
    align-items: flex-start;
    justify-content: right;
    position: absolute;
    top: 10px;
    right: 10px;
    width: 100%;
    z-index: 9999;
}

.dnd_delete_button {
    background-color: red !important;
    color: white;
    border-radius: 4px;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    padding: 0;
}

.dnd_calendar_button {
    background-color: #ffa900 !important;
    color: white;
    border-radius: 4px;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    padding: 0;
}

.dnd_delete_button svg {
    color: white;
    width: 20px;
    height: 20px
}

.publish_overlay_xuh23 {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 24px;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.5);
}

.delete_modal {
    width: 480px;
    padding: 24px;
    background-color: white;
    border-radius: 8px;
}

.delete_modal_header h3 {
    font-size: 18px;
    font-weight: 700;
    color: #000;
    margin-bottom: 16px;
}

.delete_modal_content p {
    font-size: 15px;
    color: #000;
}

.delete_modal_actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
}

.delete_modal_actions button {
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
}

.cancel_btn {
    background-color: #fff;
    color: #000;
}

.cancel_btn:hover {
    background-color: #f0f0f0;
}

.delete_btn_red {
    background-color: red;
    color: white;
}

.delete_btn_red:hover {
    background-color: darkred;
}

.add-btn-post {
    background-color: transparent;
    border: none;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #000;
    border-radius: 4px;
    padding: 0;
    font-size: 20px;
}

.add-btn-post:hover {
    background-color: #d4d4d4;
}

.dnd_header_actions {
    display: flex;
    align-items: center;
    gap: 8px;
}

.dnd_board::-webkit-scrollbar {
    width: 100%;
    height: 5px;
    background-color: #f5f5f5;
}

.dropdown-dnd {
    position: relative;
    display: inline-block;
}

.dropdown-menu-dnd {
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 999;
    border-radius: 4px;
    right: 0;
}

.dropdown-menu-dnd ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.dropdown-menu-dnd ul li {}

.dropdown-menu-dnd ul li button {
    border: none;
    background-color: transparent;
    font-size: 15x;
    color: #000;
    cursor: pointer;
    width: 100%;
    text-align: left;
    padding: 12px 16px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.dropdown-menu-dnd ul li button svg {
    width: 20px;
    height: 20px;
}

.dropdown-menu-dnd ul li button:hover {
    background-color: #f1f1f1;
}


.dnd_video-grid-item {
    position: relative;
}

.dnd_video-icon-overlay {
    position: absolute;
    bottom: 5px;
    left: 5px;
    color: black;
    font-size: 13px;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    background: #fff;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dnd_more-media {
    background-color: rgb(0 0 0);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
    font-weight: bold;
    position: absolute;
    bottom: 8px;
    right: 8px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

.dnd_more-media span {
    font-size: 15px;
}

.dnd_header_title span {
    font-size: 15px;
    font-weight: 700;
    background-color: #fff;
    color: #000;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.dnd_column::-webkit-scrollbar {
    width: 5px;
    background-color: #f5f5f5;
}

.dnd_column::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 0px;
}

.dnd_column::-webkit-scrollbar-track {
    background-color: #f5f5f5;
}

.dnd_top_icon {
    text-align: center;
    color: #000;
}

.add-new-idea-btn {
    width: 100%;
    padding: 10px;
    margin: 10px 0px;
    background-color: #f0f0f0;
    border: 1px dashed #ccc;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    font-size: 14px;
    color: #666;
    transition: all 0.3s ease;
}

.add-new-idea-btn:hover {
    background-color: #e0e0e0;
    color: #333;
}

.tab-inner-dropdown .tab-inner-dropdown-content {
    max-height: 500px;
    overflow-y: auto;
}

.post-lists .all-post-list {
    height: 70vh;
    overflow-y: auto;
}

.post-lists .all-post-list::-webkit-scrollbar {
    width: 5px;
    background-color: #f5f5f5;
}

.tab-inner-dropdown .tab-inner-dropdown-content::-webkit-scrollbar {
    width: 5px;
}

.tab-inner-dropdown .tab-inner-dropdown-content::-webkit-scrollbar-track {
    background-color: transparent;
}

.tab-inner-dropdown .tab-inner-dropdown-content::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 10px;
}

.tab-inner-dropdown .tab-inner-dropdown-content::-webkit-scrollbar-thumb:hover {
    background-color: #ccc;
}

.tab-inner-dropdown .tab-inner-dropdown-content {
    scrollbar-width: thin;
    scrollbar-color: #ccc transparent;
}