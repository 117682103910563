.page404{
    width: 100%;
    height: 100%;
    background-image: url(../../landingassets/Images/bg404.jpg);
}

.page404-box {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.page404-content {
    border-radius: 0;
    color: #fff;
    position: relative;
}

.page404-content .button-404 a {
    color: #18175d;
    background-color: #fff;
    border: 1px solid #fff;
    padding: 6px 30px;
    font-size: 22px;
    font-weight: 800;
    border-radius: 6px;
}

.page404-content h1 {
    font-size: 60px;
    font-weight: 700;
}

.page404-content h2 {
    font-size: 280px;
    font-weight: 600;
    z-index: 1;
}

.page404-img {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 55%;
}

.page404-img img {
    width: 150px;
    height: auto;
}

@media (max-width: 1199.99px ){
    .page404-content h1 {
        font-size: 40px;
    }
    .page404-content h2 {
        font-size: 250px;
    }
}

@media (max-width: 991.99px ){
    .page404-content h1 {
        font-size: 30px;
    }
    .page404-content h2 {
        font-size: 200px;
    }
    .page404-img img{
        width: 120px;
    }
}

@media (max-width: 767.99px ){
    .page404-content h1 {
        font-size: 20px;
    }
    .page404-content h2 {
        font-size: 150px;
    }
    .page404-img img{
        width: 100px;
    }
    .page404-content .button-404 a{
        font-size: 18px;
        padding: 4px 20px;
    }
}

@media (max-width: 575.99px ){
    .page404-content h1 {
        font-size: 18px;
    }
    .page404-content h2 {
        font-size: 120px;
    }
    .page404-img img{
        width: 80px;
    }
    .page404-content .button-404 a{
        font-size: 16px;
        padding: 4px 20px;
    }
}