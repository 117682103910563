.discover-events {
    background: linear-gradient(to bottom,
            #2f035c 10%,
            rgb(19, 21, 23) 90%);
    background-attachment: fixed;
    /* Keeps gradient fixed during scroll */
    background-size: cover;
}

.divide-hr {
    margin: 1.25rem 0;
    border: 0;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
}

.text-ellipsis {
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
    width: 200px;
}

.discover-events .light-fcolor,
.city-events .light-fcolor {
    color: hsla(0, 0%, 100%, .5);
}

.discover-events .container {
    max-width: 820px;
}

.discover-events .sub-main-txt {
    color: hsla(0, 0%, 100%, .79);
    font-size: 18px;
    font-weight: 400;
}

.discover-events .main-heading {
    color: #e2e2e2;
    font-size: 38px;
    font-weight: 800;
}

.discover-events .sub-heading {
    color: #e2e2e2;
    font-size: 24px;
    font-weight: 700;
}

.discover-events .list-cities {
    background-color: transparent !important;
    margin: 0;
    border-radius: 5px;
    font-size: 14px;
}

.discover-events .list-cities .tab-icon {
    cursor: pointer;
    padding: 3px 8px;
    border-radius: 3px;
    transition: background-color 0.5s ease, color 0.1s ease;
    color: rgba(231, 229, 229, 0.7);
}

.discover-events .tab-icon:hover {
    color: white;
    cursor: pointer;
}

.discover-events .list-cities .tab-icon.active-tab {
    background-color: rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 0.9);
}

.discover-events .city-name h3 {
    color: #e2e2e2;
    font-size: 16px;
    font-weight: 500;
}

.discover-events .city-name p {
    color: hsla(0, 0%, 100%, .79);
    font-size: 14px;
    font-weight: 400;
    margin-top: -7px;
    margin-bottom: 0px;
}

.discover-events .cities img {
    height: 2.5rem;
    width: 2.5rem;
    background-color: rgb(233, 189, 59);
    border-radius: 50%;
}

.discover-events .cities .col-md-3 {
    max-height: 60px !important;
}

.discover-events .discover-card {
    background-color: rgba(39, 9, 73, 1);
    border-radius: 10px;
    margin-bottom: 25px;
}

.discover-events .discover-card:hover {
    /* border: 1px solid rgba(255, 255, 255, 0.2); */
    outline: 0.1px solid rgba(255, 255, 255, 0.2);
    transition: all 0.4s ease-in-out;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: rgba(28, 30, 32, 1);
}

.discover-events .discover-card .card-body {
    padding: 1rem;
}

.discover-events .discover-card .card-body img {
    height: 60px;
    width: 60px;
    border-radius: 0.5rem;
}

.discover-events .discover-card .card-body .card-title {
    color: #e2e2e2;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 0px;
    margin-top: 10px;
}

.discover-events .discover-card .card-body .card-text {
    color: hsla(0, 0%, 100%, .79);
    font-size: 16px;
    font-weight: 400;
    margin-top: 10px;
}

.discover-events .calendar-card .card-body .line-1 img {
    height: 48px;
    width: 48px;
    border-radius: 0.5rem;
}

.discover-events .calendar-card .card-body .card-title {
    color: #e2e2e2;
    font-size: 20px;
    font-weight: 500;
    margin: 12px 0px 4px;
}

.city-events {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #ffffff;
}

.city-events .container {
    max-width: 960px;
    padding: 6rem 1rem 3rem 1rem;
}

.city-events .city-hero-container {
    background-image: url(https://images.lumacdn.com/cdn-cgi/image/format=auto,fit=cover,dpr=2,background=white,quality=75,width=1080/discovery/newdelhi-desktop.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 100vh;
    position: relative;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.city-events .city-events-banner .city-pic {
    background-color: rgba(255, 255, 255, 0.258);
    height: 3.5rem;
    width: 3.5rem;
    margin-bottom: 1.5rem;
    border-radius: 50%;
    backdrop-filter: blur(16px);
}

.city-events .city-events-banner .city-pic img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.city-events .city-events-banner .city-title {
    margin-bottom: 0.75rem;
}

.city-events .city-events-banner .city-title h2 {
    color: #e2e2e2;
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 2px;
}

.city-events .city-events-banner .city-title h1 {
    color: #ffffff;
    font-size: 52px;
    font-weight: 700;
    margin-bottom: 0px;
}

.city-events .city-events-banner .city-time {
    color: #e2e2e2;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0px;
}

.city-events .city-events-banner hr {
    border: 1px solid rgba(255, 255, 255, 0.6);
}


.custom-placeholder::placeholder {
    backdrop-filter: blur(16px) !important;
    color: rgba(255, 255, 255, 0.5);
    font-size: 16px;
}

.city-events .city-events-sec {
    background-color: #131517;
}

.city-events .city-events-sec .city-events-link {
    font-size: 14px !important;
    text-decoration: none !important;
    color: rgba(255, 255, 255, 0.64) !important;
}

.city-events .city-events-sec .city-events-link:hover {
    outline: 1px solid rgba(255, 255, 255, 0.64) !important;
    background-color: rgba(255, 255, 255, 0.5) !important;
    color: #131517 !important;
    transition: all 0.3s ease-in-out;
}

.city-events .city-events-sec .city-events-title {
    color: #ffffff;
    margin-top: 0px;
}

.city-events .city-events-sec svg {
    width: 0.875rem;
    height: 0.875rem;
}

.city-events .light-bg-btn {
    background-color: rgba(255, 255, 255, 0.064);
    padding: 7px 10px;
    border-radius: 10px !important;
    outline: none !important;
}

.city-events .small-btn {
    color: rgba(255, 255, 255, 0.64) !important;
    font-size: 16px !important;
    padding: 12px 10px;
    cursor: pointer !important;
}

.city-events .small-btn:hover {
    background-color: rgba(255, 255, 255, 0.5) !important;
    color: #131517 !important;
    transition: all 0.3s ease-in-out;
}

.city-events .city-events-sec .timeline .timeline-title-sec {
    position: sticky;
}

.city-events .city-events-sec .timeline .line {
    position: absolute;
    top: 10rem;
    bottom: 0;
    left: 2.7rem;
    border-left: 0.125rem dashed rgba(255, 255, 255, 0.08);
}

.city-events .city-events-sec .timeline .mid-dot {
    width: 0.5rem;
    height: 0.5rem;
    background-color: hsla(0, 0%, 100%, .32);
    border-radius: 50%;
}

.city-events .city-events-sec .timeline .timeline-card {
    border: 1px solid rgba(255, 255, 255, 0.08);
    background-color: rgba(255, 255, 255, 0.04);
    padding: 0.75rem 0.75rem 0.75rem 1rem;
    border-radius: 10px;
    cursor: pointer;

}

.city-events .city-events-sec .timeline .timeline-card .location-icon {
    width: 16px !important;
    height: 16px !important;
}

.city-events .city-events-sec .timeline .timeline-card .wrapper-img {
    width: 120px;
}

.city-events .city-events-sec .timeline .timeline-card .wrapper-img img {
    border-radius: 10px;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.city-events .city-events-sec .timeline .timeline-card .location-type .text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 300px;
}


/* Categorical-Events */


.categorical-events,
.events-nearby {
    background-color: #131517;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: white;
}

.categorical-events .container {
    max-width: 960px;
}

.categorical-events .header h1 {
    color: #ffffff;
    font-size: 46px;
    font-weight: 500;
    margin-bottom: 0px;
}

.categorical-events .header-img {
    width: 395px;
    height: 395px;
    right: 0;
}

.categorical-events .header-img img {
    width: 100%;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.08);
}

.categorical-events .lower-section .main-sec .sec-content {
    border-radius: 0.75rem;
    border: 1px solid rgba(255, 255, 255, 0.08);
    /* padding: 0rem 1.125rem 0.25rem 1.125rem !important; */
    background-color: rgba(255, 255, 255, 0.04);
}

.categorical-events .lower-section .main-sec .month {
    background-color: rgba(255, 255, 255, 0.08);
    padding: 0.5rem 1.125rem;
}

.categorical-events .lower-section .main-sec .month:first-child {
    border-top-left-radius: 0.65rem;
    border-top-right-radius: 0.65rem;
}

.categorical-events .lower-section .main-sec .month-event {
    padding: 0.5rem 0.9rem;
    margin: 2px 5px;
}

.categorical-events .lower-section .main-sec .month-event:hover {
    background-color: rgba(255, 255, 255, 0.08);
    border-radius: 10px;
    transition: all 0.3s ease-in-out;
}

.categorical-events .lower-section .main-sec .random-color {
    width: 20px;
    height: 7px;
    border-radius: 10px;
}

.categorical-events .lower-section .main-sec .month-event-left img {
    width: 48px;
    height: 48px;
    border-radius: 10px;
}

/* Events-Nearby */

.events-nearby .container {
    height: 100dvh;
}

.events-nearby .events-panel {
  width: 550px; /* Adjust width */
  overflow-y: auto; /* Changed to auto */
  /* overflow: hidden; */
  height: 100%; /* Added height: 100% */
  padding: 0px 10px;
}

.events-nearby .main-sec {
  height: 100vh; /* Ensure the main section spans the full viewport height */
  display: flex;
  overflow: hidden; /* Prevent main section from scrolling */
}


.events-nearby .events-panel .section-body .img-wrapper {
    height: 80px;
    width: 80px;
}

.events-nearby .events-panel .section-body .img-wrapper img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.events-nearby .events-panel .section-body {
    padding: 0.75rem 1rem;
}

.events-nearby .events-panel .section-body:hover {
    background-color: rgba(255, 255, 255, 0.08);
    border-radius: 10px;
    transition: all 0.3s ease-in-out;
}

.events-nearby .events-panel.custom-scroll {
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    /* For Firefox */
}

/* For Chrome, Edge, and Safari */
.events-nearby .events-panel.custom-scroll::-webkit-scrollbar {
    width: 8px;
}

.events-nearby .events-panel.custom-scroll::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.events-nearby .events-panel.custom-scroll::-webkit-scrollbar-thumb {
    background-color: #00adb5;
    border-radius: 8px;
    border: 2px solid rgba(0, 0, 0, 0.1);
}

.events-nearby .events-panel.custom-scroll::-webkit-scrollbar-thumb:hover {
    background-color: #007f8a;
}


.events-nearby .events-panel .section-body .right-body .head {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0px;
}

.events-nearby .events-panel .section-body .right-body .text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 200px;
}

.events-nearby .events-panel .section-body .right-body .avatars img {
    width: 16px;
    height: 16px;
    border-radius: 50%;
}

.events-nearby .events-panel .section-body .right-body .avatars img:not(:first-child) {
    margin-left: -8px;
}

.events-nearby .events-panel .section-body .right-body .badge.rounded-pill {
    background-color: #fd46464d !important;
    padding: 7px 12px;
    color: #fd4646;
}
.events-nearby .main-sec .map-section{
    width: 100% !important;
    margin: 0 0.5rem;
    padding:0.5rem;
    border: 1px solid rgba(255, 255, 255, 0.08);
    border-radius: 10px;
}


/* Discover Events Climate */

.categorical-events-climate .timeline .timeline-card {
    border: 1px solid rgba(255, 255, 255, 0.08);
    background-color: rgba(255, 255, 255, 0.04);
    padding: 0.75rem 0.75rem 0.75rem 1rem;
    border-radius: 10px;
    cursor: pointer;
}
.categorical-events-climate .timeline .timeline-card:hover{
    background-color: rgba(255, 255, 255, 0.08);
}
.categorical-events-climate .timeline .timeline-card .total-people-inner img:not(:first-child) {
    margin-left: -14px;
}

.categorical-events-climate .timeline .timeline-card .wrapper-img {
    width: 120px;
}

.categorical-events-climate .timeline .timeline-card .wrapper-img img {
    border-radius: 10px;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.categorical-events-climate .timeline .line {
    position: absolute;
    top: 10rem;
    bottom: 0;
    left: 2.7rem;
    border-left: 0.125rem dashed rgba(255, 255, 255, 0.08);
}

.city-events .city-events-sec .timeline .line {
    position: absolute;
    top: 1rem;
    bottom: 0;
    left: 0.2rem;
    border-left: 0.125rem dashed rgba(255, 255, 255, 0.08);
}