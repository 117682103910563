.flow-edit-content {
    box-sizing: border-box;
    cursor: inherit;
    display: grid;
    height: calc(100% - 58px);
    grid-template-columns: 67% 33%;
}

.flow-tab-item {
    border-bottom: 1px solid #ccc;
}

.flow-tab-item .nav-item {
    margin: 0px 30px;
}

.flow-tab-item .nav-item a svg {
    margin-right: 10px;
}

.flow-tab-item .nav-item a {
    background-color: transparent !important;
    color: rgb(91, 94, 99) !important;
    font-weight: 700 !important;
}

.flow-tab-item .nav-item a.active {
    background-color: transparent !important;
    color: #000 !important;
    border-bottom: 2px solid #000 !important;
    border-radius: 0 !important;
    font-weight: 700;
}

.flow-tab-item {
    justify-content: center;
}

.flow-edit-content-left-inner {
    box-sizing: border-box;
    position: relative;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    background-color: rgb(245, 245, 245);
}

.tab-header {
    background: #fff;
}

.publish-btn-view {
    background: rgb(37 97 208) !important;
    color: #fff !important;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 0;
    padding: 20px;
    max-height: 56px;
}

.publish-btn-view:hover {
    background: rgb(15, 64, 156) !important;
    color: #fff;
}

.publish-btn-view svg {
    margin-right: 10px;
    width: 20px;
    height: 20px;
}

.design-part {
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    overflow: auto;
    padding: 24px;
}

.design-fixed-btn-content-inner {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    height: 100%;
    width: 100%;
}

.design-fixed-btn-1 {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgb(252 165 29);
    border-radius: 4px;
    color: rgb(255, 255, 255) !important;
    background-color: rgb(252 165 29) !important;
    padding: 3px 24px;
    height: 44px;
    width: 100%;
    cursor: pointer;
}

.design-fixed-btn-1:hover {
    background-color: rgb(252 165 29) !important;
    color: rgb(255, 255, 255);
}

.design-fixed-btn-2 {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgb(160 42 136) !important;
    border-radius: 4px;
    color: rgb(255, 255, 255) !important;
    background-color: rgb(160 42 136) !important;
    padding: 10px 24px;
    height: 44px;
    width: 100%;
    cursor: pointer;
}

.design-fixed-btn-2:hover {
    background-color: rgb(2, 45, 161);
    color: rgb(255, 255, 255);
}

.space {
    padding: 0px 10px;
}

.design-fixed-btn-content {
    width: 600px;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    align-items: center;
    cursor: inherit;
    position: absolute;
    z-index: 2;
}

.heXNKM {
    margin-bottom: 68px;
}

.data-item-box {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px;
}

.user-profile-picker-box {
    background: #fff;
    padding: 16px;
    display: flex;
    justify-content: center;
    border-radius: 12px;
}

.image-picker .btn-light {
    width: 150px;
    height: 150px;
    border-radius: 50%;
}

.image-picker img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover !important;
}

.image-picker {
    position: relative;
}

.image-picker-icon svg {
    position: absolute;
    left: 53%;
    width: 40px;
    height: 40px;
    background-color: rgb(2, 45, 161);
    color: #fff;
    border-radius: 50%;
    padding: 5px;
}

.EvgJu {
    margin-bottom: 8px;
}

.conent-same-xxlp {
    padding: 16px;
    background: #fff;
    border-radius: 12px;
}

.custom-control {
    height: 50px;
    font-weight: 700;
}

.form-control:focus {
    color: #212529;
    background-color: #fff;
    border-color: #2c2c2c;
    outline: 0;
    box-shadow: none;
}

.connect-icon-list {
    display: inline-grid;
}

.connect-icon-list-btn {
    cursor: pointer;
    border: none;
    background-color: transparent;
    border-radius: 4px;
    height: 40px;
    width: 40px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
}

.connect-icon-list-btn:hover {
    background-color: rgb(2, 45, 161);
    color: #fff;
}

.connect-icon-list-btn svg {
    width: 22px;
    height: 22px;
}

.user-connect-with-box {
    display: flex;
    align-items: center;
}

.copy-link-btn {
    width: 60px;
    height: 60px;
    border: 1px solid #ccc;
    background: #fff;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    margin-left: 10px;
}

.copy-link-btn svg {
    width: 30px;
    height: 30px;
    color: rgb(73, 115, 233);
}

.eye-hide-show-btn {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: #000;
    border: 1px solid #ccc;
}

.eye-hide-show-btn:hover {
    background-color: #ccc;
    color: #000;
}

.name {
    display: flex;
    box-sizing: border-box;
    cursor: inherit;
    margin-left: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
    overflow: auto;
    flex-direction: column;
    flex: 1 1;
    color: #000;
    font-size: 17px;
    font-weight: 600;
}

.user-context-box {
    display: flex;
    align-items: center;
}

.jskLNf {
    margin-right: 24px;
}

.detail {
    display: flex;
    box-sizing: border-box;
    cursor: inherit;
    width: 100%;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
}

.input-outer-box {
    display: flex;
    box-sizing: border-box;
    cursor: inherit;
    margin: 4px 0px;
    padding: 8px;
    width: 100%;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    background-color: rgb(240, 242, 245);
    box-shadow: none;
    border: 1px solid rgb(222, 223, 224);
    border-radius: 6px;
}

.input-box-icon {
    margin-right: 10px;
}

.input-box-icon svg {
    width: 22px;
    height: 22px;
}

.input-box {
    box-sizing: border-box;
    cursor: inherit;
    display: block;
    width: 100%;
    max-width: 100%;
}

.input-box-inner {
    display: flex;
    box-sizing: border-box;
    cursor: inherit;
    height: 44px;
    max-width: 100%;
    width: 100%;
    position: relative;
    background-color: rgb(255, 255, 255);
    border-radius: 4px;
}

.custom-select {
    width: 35%;
    border-radius: .375rem 0 0 .375rem;
}

.custom-select:focus {
    border-color: #2c2c2c;
    outline: 0;
    box-shadow: none;
}

.custom-input {
    border-left: 0px;
    border-radius: 0 .375rem .375rem 0;
}

.delete-btn-view {
    padding: 0px;
}

.delete-btn-view svg {
    width: 22px;
    height: 22px;
}

.user-url-box {
    display: flex;
    align-items: center;
}

.xxl2 {
    appearance: none;
    background: transparent;
    width: 100%;
    line-height: 34px;
    outline: none;
    border: 1px solid rgb(222, 223, 224);
    border-radius: 4px;
    font-size: 16px;
    padding-left: 17px;
    padding-right: 0px;
    margin: 6px 0px;
}

.pxlr4.tab-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    padding: 24px;
    height: 710px;
}

.tab-content-inner-xl3 {
    max-width: 600px;
    width: 100%;
}





.flow-edit-content-right {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    /* height: calc(100vh - 114px); */
    border-width: 1px;
    border-color: rgb(222, 223, 224);
    border-left-style: solid;
}

.css-lm03f7 {
    width: 100%;
    height: 16px;
}

.phone-frame-placeholder {
    height: 263.75px;
    display: flex;
    position: relative;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
}

.phone-frame {
    width: 375px;
    height: 812px;
    position: absolute;
    border-radius: 32px;
    border: 12px solid black;
    user-select: none;
    overflow: hidden;
    box-sizing: content-box;
    transform: scale(0.824163);
}

.phone-frame-inner {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
}

.phone-frame-inner-content {
    position: relative;
    width: 100%;
    flex-direction: column;
    max-width: 100vw;
    min-height: 100%;
    -webkit-box-pack: justify;
    justify-content: space-between;
    border-radius: 20px;
    display: flex;
    box-sizing: border-box;
    cursor: inherit;
    height: 100%;
    overflow-y: auto;
    background-color: rgb(143, 143, 143);
}

.lnkIZU {
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    max-width: 500px;
    pointer-events: auto;
    padding: 0px 16px 16px;
    position: relative;
}

.upload-icon .btn svg {
    color: #fff;
    width: 26px;
    height: 26px;
}

.upload-icon {
    position: absolute;
    right: 2%;
    top: 10px;
}

.uploaded-image-inner {
    display: flex;
    box-sizing: border-box;
    cursor: inherit;
    width: 140px;
    height: 140px;
}

.uploaded-image-inner img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.uploaded-image {
    position: relative;
    margin-top: 50px;
}

.gva-DPR {
    margin-bottom: 16px;
}

.user-name {
    display: flex;
    align-items: center;
    justify-content: center;
}

.cMShSf {
    margin: 0px;
    cursor: inherit;
    font-size: 32px;
    font-weight: 600;
    line-height: 44px;
    text-align: center;
    color: rgb(255, 255, 255);
}

.populer-tag {
    box-sizing: border-box;
    cursor: inherit;
    margin-left: 4px;
    width: 20px;
    height: 20px;
}

.populer-tag img {
    width: 100%;
}

.connect-preview {
    max-width: 100%;
    position: relative;
    border: 2px solid transparent;
    border-radius: 12px;
    color: rgb(17, 17, 17);
    flex-direction: column;
    min-height: 86px;
    display: flex;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 30px 0px;
    padding: 8px;
    background: rgb(255, 255, 255);
    width: 100%;
    align-items: center;
    justify-content: center;
}

.frame-linner-btn-preview .btn {
    background: #fff;
    border-radius: 50%;
    height: 40px;
    width: 40px;
}

.connect-preview-title p {
    margin-bottom: 0px;
}

.preview-btn {
    display: flex;
    justify-content: center;
    align-items: center;
}

.preview-btn-view {
    border: 1px solid #ccc !important;
    color: #033ddd !important;
    font-weight: 600;
}


.preview-btn-view svg {
    margin-right: 8px;

}

.flowpage-design-tab-color {
    display: flex;
    gap: 10px;
}

.design-tab-list {
    list-style: none;
    padding: 0;
}

.design-tab-list .design-tab-list-item {
    margin-bottom: 8px;
}

.design-tab-list .design-tab-list-item .design-tab-list-item-inner {
    display: flex;
    box-sizing: border-box;
    padding: 6px 8px;
    width: 100%;
    height: 30px;
    justify-content: left;
    background: transparent;
    border: none;
    align-items: center;
    color: #111111;
}

.design-tab-list .design-tab-list-item .design-tab-list-item-inner svg {
    margin-right: 12px;
}

.design-tab-list .design-tab-list-item .design-tab-list-item-inner.active {
    color: rgb(3, 61, 221);
    background-color: rgb(225, 232, 251);
    border-radius: 4px;
}

.design-tab-list .design-tab-list-item .design-tab-list-item-inner:hover {
    color: rgb(3, 61, 221);
    background-color: rgb(225, 232, 251);
    border-radius: 4px;
}

.section-inner-title h3 {
    font-size: 16px;
    font-weight: 700;
    color: #000;
}

.section-inner-title h3 svg {
    margin-right: 8px;
    width: 16px;
    height: 16px;
}

.section-inner-title {
    margin-bottom: 16px;
}

.hex-color-picker {
    display: flex;
    background: rgb(218, 220, 221);
    padding: 3px;
    border-radius: 4px;
    align-items: center;
    margin-bottom: 16px;
}

.custom-color-picker {
    height: 40px;
    margin-right: 6px;
    width: 40px;
    border: none;
    background: transparent;
    padding: 0px;
}

.default-color-picker-list {
    display: flex;
    box-sizing: border-box;
    cursor: inherit;
    max-width: 600px;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    justify-content: flex-start;
}

.default-color-picker-list-item {
    width: 50px;
    height: 50px;
    margin: 8px 12px;
    cursor: pointer;
}

.default-color-picker-list-item-inner {
    width: 100%;
    height: -webkit-fill-available;
    border-color: transparent;
    border-radius: 50%;
}

.hwtPYZ {
    margin-top: 16px;
}

.cGOrBK {
    width: 100%;
    border-left: none;
}

.kJZNmo {
    margin-bottom: 24px;
}

.select-shade-list-item-inner {
    width: 48px;
    height: 48px;
    padding: 0;
    border: transparent;
    border-radius: 50%;
    margin-bottom: 8px;
}

.select-shade-list {
    display: flex;
    justify-content: space-between;
}

.select-shade-list-item-inner-color-5 {
    color: #fff;
}

.select-shade-list-item-inner-text-title {
    cursor: inherit;
    font-size: 14px;
    line-height: 17px;
    color: rgb(17, 17, 17);
    font-weight: inherit;
    margin: 8px 5px;
    text-transform: capitalize;
}

.select-shade-list-item {
    text-align: center;
}


.select-font-list {
    display: flex;
    gap: 20px;
}

.select-link-shape-list-item-inner {
    width: 150px;
    height: 70px;
    border: transparent;
    background: #a02a88;
}

.select-link-shape-list {
    display: flex;
    justify-content: space-between;
}

.round {
    border-radius: 12px;
}

.oval {
    border-radius: 50px;
}

.image-picker-background .btn-light {
    width: 200px;
    height: 400px;
    background: #ccc;
}

.image-picker-background img {
    width: 200px;
    height: 400px;
    object-fit: cover !important;
}

.css-bdjl4r {
    width: 200px !important;
    height: 400px !important;
}



.phone-frame-inner-content.color-change1 {
    background: #047C97;
}


.phone-frame.template_1 {
    border-color: #C3744F;
}

.phone-frame.template_1 .phone-frame-inner .phone-frame-inner-content {
    background: #047C97;
}

.phone-frame.template_1 .phone-frame-inner .phone-frame-inner-content::-webkit-scrollbar-track {
    display: none;
}

.phone-frame.template_1 .phone-frame-inner .phone-frame-inner-content::-webkit-scrollbar-thumb {
    display: none;
}

.phone-frame.template_2 {
    border: none;
}

.phone-frame.template_2 .phone-frame-inner .phone-frame-inner-content {
    background-image: url(../../assets//images//Templates//purple-blue.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.phone-frame.template_2 .phone-frame-inner .phone-frame-inner-content::-webkit-scrollbar-track {
    display: none;
}

.phone-frame.template_2 .phone-frame-inner .phone-frame-inner-content::-webkit-scrollbar-thumb {
    display: none;
}

.connect-preview-inner {
    display: flex;
    width: 100%;
    gap: 20px;
    align-items: center;
}

.connect-preview-icon img {
    width: 40px;
    height: 40px;
}

.connect-preview-title h5 {
    font-weight: 700;
    margin-bottom: 4px;
}

.phone-frame.template_3 .phone-frame-inner .phone-frame-inner-content {
    background-image: url(../../assets/images/Templates/form-bg.webp);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.phone-frame.template_3 .phone-frame-inner .phone-frame-inner-content::-webkit-scrollbar-track {
    display: none;
}

.phone-frame.template_3 .phone-frame-inner .phone-frame-inner-content::-webkit-scrollbar-thumb {
    display: none;
}

.social-btn .btn {
    width: 45px;
    height: 45px;
    background-color: #acc9cd !important;
    padding: 0px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.social-btn .btn svg {
    width: 25px;
    height: 25px;
    color: #fff;
}

.phone-frame.template_3 .user-description p {
    color: #fff;
    font-weight: 600;
    font-size: 16px;
}

.form-box {
    background: #acc9cd;
    padding: 30px 20px;
    border-radius: 10px;
    color: #fff;
}

.form-box .form-control {
    border-radius: 0px;
    background: #ffff;
    color: #000;
    font-weight: 600;
    font-size: 16px;
}

.form-box .form-control:focus {
    border-color: #000;
    box-shadow: none;
}

.form-box .form-title h5 {
    font-weight: 700;
    text-align: center;
    font-size: 22px;
}

.form-box .form-description p {
    font-size: 15px;
    text-align: center;
}

.submit-btn {
    border: 1px solid #fff;
    background: transparent;
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    width: 100%;
}

.submit-btn:hover {
    background: #fff;
    color: #000;
}

.connect-preview-3 {
    background: #acc9cd;
    width: 100%;
    padding: 26px 15px;
    display: flex;
    gap: 20px;
    border-radius: 10px;
    color: #fff;
    align-items: center;
}

.connect-preview-3 .role-icon img {
    width: 40px;
    height: 40px;
}


.phone-frame.template_4 .phone-frame-inner .phone-frame-inner-content {
    background: linear-gradient(138deg, rgba(226, 68, 68, 1) 0%, rgba(230, 103, 19, 1) 50%, rgba(141, 0, 255, 1) 100%);
}

.top-connect-preview a {
    color: #000;
    font-weight: 600;
    font-size: 16px;
    background-color: #fff;
    padding: 10px 20px;
    border-radius: 50px;
    text-decoration: underline;
}


.border-box {
    border: 2px solid #fff;
    padding: 20px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    gap: 20px;
    width: 100%;
}

.border-box .icon-content img {
    width: 30px;
    height: 30px;
}

.border-box-title h5 {
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 0px;
    color: #fff;
}

.iframe-box {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.iframe-box iframe {
    width: 100%;
    height: 250px;
    border: none;
}


.phone-frame.template_5 {
    border: none;
}

.phone-frame.template_5 .phone-frame-inner .phone-frame-inner-content {
    background: linear-gradient(180deg, rgba(250, 231, 178, 1) 0%, rgba(214, 189, 152, 1) 50%, rgba(167, 142, 118, 1) 100%);
}

.phone-frame.template_5 .phone-frame-inner .phone-frame-inner-content::-webkit-scrollbar-track {
    display: none;
}

.phone-frame.template_5 .phone-frame-inner .phone-frame-inner-content::-webkit-scrollbar-thumb {
    display: none;
}

.phone-frame.template_5 .user-name h2 {
    color: #4f3b00;
}

.phone-frame.template_5 .user-description p {
    color: #4f3b00;
    margin-bottom: 0px;
}

.product-box-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px 10px 0px 0px;
}

.product-box {
    width: 100%;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 3px;
}

.product-box-title {
    padding: 20px 0px 0px;
    text-align: center;
}

.product-box-title h5 {
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 5px;
}