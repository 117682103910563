.create-brand-tab {
    border-bottom: 1px solid #D9D9D9;
}

.create-brand-tab .nav-item,
.create-brand-tab>.nav-link {
    flex-grow: 1;
}

.create-brand-tab li button {
    color: #5D586C !important;
    border-bottom: 2px solid transparent !important;
    border-left: none !important;
    border-right: none !important;
    border-top: none !important;
    font-size: 15px;
    font-weight: 600;
}

.create-brand-tab li {
    max-width: 150px;
}

.create-brand-tab li button.active {
    color: #7367F0 !important;
    border-bottom: 2px solid #7367F0 !important;
}

.createbrand-width.auto_brand_modal .modal-dialog {
    min-width: 1400px ;
}

.createbrand-width .modal-dialog {
    min-width: 850px;
}

.createbrand-width .form-control-color {
    width: 100%;
}

.brand-button {
    display: flex;
    justify-content: end;
    margin-top: 24px;
}

button.Save-btn {
    color: #fff !important;
    background-color: #7367f0 !important;
    padding: 10px 20px;
    border: 1px solid #7367f0 !important;
}

button.Cancel-btn {
    color: #5D586C !important;
    background-color: #F9F9FA !important;
    padding: 10px 20px;
    border: 1px solid #F9F9FA !important;
}

.Vanity-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
}

.Vanity-title h4 {
    font-size: 18px;
    font-weight: 600;
    color: #5D586C;
}

.Vanity-card {
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 18px rgba(75, 70, 92, 0.1);
    margin-bottom: 16px;
}

.Vanity-card .Vanity-card-title h4 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0px;
}

.Vanity-card .Vanity-card-title p {
    color: #D2D0D6;
    margin-bottom: 0px;
    font-size: 15px;
}

.Vanity-card .Vanity-card-Status h5 {
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    background-color: #7367f0;
    padding: 2px 6px;
    border-radius: 4px;
}

.Vanity-card .Vanity-card-visits h4 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0px;
}

.Vanity-card .Vanity-card-visits p {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0px;
}

.brand-socials-input span#inputGroup-sizing-lg {
    background-color: #1136df;
    border: 1px solid #1136df;
}

.brand-socials-input span#inputGroup-sizing-lg svg {
    font-size: 24px;
    color: #fff;
}

.Vanity-card .Vanity-card-approved-Status h5 {
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    background-color: #28C76F;
    padding: 2px 6px;
    border-radius: 4px;
}

.Vanity-card .Vanity-card-pending-Status h5 {
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    background-color: #EA5455;
    padding: 2px 6px;
    border-radius: 4px;
}