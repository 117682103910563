.Member-list ul li {
    /* border: none !important;
    padding: 0px; */
    margin-bottom: 20px;
}

.Member-list .member-img {
    width: 90px;
    height: auto;
    margin-right: 30px;
}

.Member-list .member-content h3 {
    font-size: 15px;
    font-weight: 700;
    color: #5D586C;
}

.Member-list .member-content h4 {
    font-size: 14px;
    font-weight: 500;
    color: #A5A2AD;
}

.Member-list .member-content p {
    font-size: 16px;
    font-weight: 700;
    color: #7367f0;
    margin-bottom: 0;
}

.Member-list button.btn-edit,
.brand-action button.btn-edit {
    color: #7367f0 !important;
    background-color: transparent !important;
    font-size: 24px;
    padding: 0px 8px 3px;
    border: none;
}

.Member-list button.btn-delete,
.brand-action button.btn-delete {
    color: #f64e60 !important;
    background-color: transparent !important;
    font-size: 24px;
    padding: 0px 8px 3px;
    border: none;
}

.invited-btn {
    background: #CFBA00;
    border-color: #CFBA00 !important;
    color: #fff;
    padding: 2px 10px;
    border-radius: 6px;
    font-size: 14px;
}

.invited-btn:hover {
    background: #CFBA00 !important;
    border-color: #CFBA00 !important;
    color: #fff;
    padding: 2px 10px;
    border-radius: 6px;
}

.invited-btn p {
    margin-bottom: 0;
}

.join-btn {
    background-color: #7367f0;
    border-color: #7367f0 !important;
    color: #fff;
    padding: 2px 10px;
    border-radius: 6px;
    font-size: 14px;
}

.join-btn:hover {
    background-color: #7367f0 !important;
    border-color: #7367f0 !important;
    color: #fff;
    padding: 2px 10px;
    border-radius: 6px;
}

.join-btn p {
    margin-bottom: 0;
}

.resend-btn {
    background-color: #FF0000 !important;
    border-color: #FF0000 !important;
    color: #fff;
    padding: 2px 10px;
    border-radius: 6px;
    font-size: 14px;
}
.resend-btn-active {
    background-color: #00ff04 !important;
    border-color: #00ff84 !important;
    color: #fff;
    padding: 2px 10px;
    border-radius: 6px;
    font-size: 14px;
    margin-bottom: 0px;

}
.resend-btn-unactive {
    background-color: #ff6600 !important;
    border-color: #ff8400 !important;
    color: #fff;
    padding: 2px 10px;
    border-radius: 6px;
    font-size: 14px;
    margin-bottom: 0px;
}
.resend-btn:hover {
    background-color: #FF0000;
    border-color: #FF0000 !important;
    color: #fff;
    padding: 2px 10px;
    border-radius: 6px;
}

.resend-btn p {
    margin-bottom: 0;
}

.bg-blue-transprant {
    background: #d4d0ff;
    color: #7367f0;
}

.bg-red-transprant {
    background: #f9d9d9;
    color: #f64e60;
}

.pills-view {
    padding: 5px 14px;
    border-radius: 18px;
}

.pills-view p {
    font-size: 14px;
    font-weight: 500;
}

.nav-tabs {
    border-bottom: 1px solid #dee2e6;
    /* This adds the line below the tabs */
}

.nav-tabs .nav-link {
    color: #666;
    border: none;
    padding: 10px 20px;
    margin-bottom: -1px;
    /* This ensures the active tab's border overlaps the line */
}

.nav-tabs .nav-link.active {
    color: #7749F8;
    border-bottom: 2px solid #7749F8;
    background-color: transparent;
}

.custum-modal-body .tab-content {
    padding-top: 20px;
}

.member-status .form-check-input {
    width: 3em;
    height: 1.5em;
}

.member-status .form-check-input:checked {
    background-color: #7749F8;
    border-color: #7749F8;
}

/* Additional styles to match the image more closely */
.custum-modal-body {
    padding: 0;
}

.nav-tabs {
    padding: 0 15px;
}

.tab-content {
    padding: 15px;
}

.modal-header {
    border-bottom: none;
}

@media (max-width: 767.99px) {
    .Member-list .member-img {
        margin-right: 12px;
    }

    .Member-list .member-img {
        width: 60px;
        margin-right: 6px;
    }
}