.Metrics-title {
    background-color: transparent !important;
    border: none !important;
}

.Beck-btn-icon {
    background: transparent !important;
    border: none !important;
    font-size: 16px;
    font-weight: 500;
    color: #000 !important;
    transition: 0.4s;
    margin-bottom: 14px;
}

.Beck-btn-icon:hover {
    background: transparent !important;
    border: none !important;
    color: #000 !important;
}

.Beck-btn-icon svg {
    margin-right: 2px;
    width: 20px;
    height: 20px;
}

.vanity-tag h5 {
    margin-left: 8px;
    margin-right: 6px;
}

.vanity-tag h4 span {
    margin-left: -20px;
}








/* Add this CSS to your stylesheet */
.table-container {
    /* max-width: 1000px; */
    /* Adjust the width as needed */
    margin: 0 auto;
}

.custom-table {
    width: 100%;
    border-collapse: collapse;
    margin: 1em 0;
}

.custom-table th,
.custom-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.custom-table th {
    background-color: #f5f5f5;
}

.custom-table tbody tr:nth-child(even) {
    background-color: #f2f2f2;
}

.custom-table tbody tr:hover {
    background-color: #ddd;
}

.metrics-smartlink-data h4 {
    font-size: 22px;
    margin-bottom: 12px;
}

/* Add responsiveness for small screens */
@media screen and (max-width: 600px) {
    .custom-table {
        font-size: 14px;
    }

    .custom-table th,
    .custom-table td {
        padding: 4px;
    }

    .custom-table tbody tr {
        display: block;
        margin-bottom: 10px;
    }

    .custom-table tbody td::before {
        content: attr(data-label);
        font-weight: bold;
        display: inline-block;
        width: 50%;
        margin-right: 10px;
    }

    .custom-table tbody td:last-child {
        margin-bottom: 0;
    }
}