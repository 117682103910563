.ZWEFTR {
    display: flex;
    margin-bottom: 16px;
}

.page_builder_app {
    display: flex;
    height: 100vh;
    overflow: hidden;
}

.kzboard {
    /* background-color: rgb(217, 222, 247); */
    flex-grow: 1;
    -webkit-box-flex: 1;
}

.vertical-header {
    margin: 16px 0px 32px;
}

.horizontal-header {
    margin: 16px 0px 32px;
}

.vertical-header .builder_content_header_next {
    margin-top: 0px;
}


.bulider_left_header {
    margin-top: auto;
    padding: 8px;
    background-color: white;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    height: 60px;
}

.logo_side {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 8px 16px;
}

.jrQiLo {
    border-radius: 100%;
    width: 42px;
    height: 42px;
    object-fit: cover;
}

.dropdown_name h6 {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 4px;
    color: rgb(33, 33, 33);
    line-height: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.dropdown_name p {
    font-size: 12px;
    font-weight: 400;
    margin: 0px;
    color: rgb(117, 117, 117);
    line-height: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.publish_btn_container {
    background: #fff;
    padding: 11px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.publish-btn {
    color: white !important;
    border-radius: 4px;
    padding: 0px 16px;
    font-size: 14px;
    font-weight: 500;
    transition: all 0.2s ease 0s;
    height: 40px;
    border: none;
    background-image: var(--btncolor);
}

.publish-btn:hover {
    background-color: rgb(44, 75, 255);
    color: white;
}

.page_builder_design_body {
    height: 95%;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
}

.goWHMr {
    background: rgb(251, 212, 189);
}

.builder-box-design.goWHMr .builder_content .button_content_box button {
    background-color: rgb(239, 96, 14);
    color: #ffffff;
}

.builder-box-design.goWHMr .builder_content .text_content_box {
    background-color: rgb(245 245 245);
    color: rgb(35, 35, 35);
}

.builder-box-design {
    width: 414px;
    height: 90%;
    overflow: scroll;
    background: rgb(251, 212, 189);
    box-sizing: border-box;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 4px;
    border-radius: 8px;
    padding: 16px;
    display: flex;
    flex-direction: column;
}

.builder-box-design.color_white {
    background: #fff;
}

.builder-box-design.color_white .builder_content .button_content_box button {
    background-color: rgb(230, 179, 51);
    color: #ffffff;
}

.builder-box-design.color_pink {
    background: linear-gradient(175deg, rgb(255, 186, 229) 28%, rgba(255, 237, 79, 0) 106%);
}

.builder-box-design.color_pink .builder_content .button_content_box button {
    background-color: rgb(35, 35, 35);
    color: #fff;
}

.builder-box-design.color_green {
    background: rgb(211, 236, 218);
}

.builder-box-design.color_green .builder_content .button_content_box button {
    background-color: rgb(7, 134, 84);
    color: #fff;
}

.builder-box-design.color_beige {
    background: rgb(39, 6, 86);
}

.builder-box-design.color_beige .builder_content .button_content_box button {
    background-color: rgb(129, 73, 207);
    color: #fff;
}

.builder-box-design.color_beige .builder_content .tit_clor {
    color: #fff !important;
}

.builder-box-design.color_beige .builder_content .des_class {
    color: #fff !important;
}

.builder-box-design.color_beige .builder_content .social_item_list svg {
    color: #fff !important;
}

.builder-box-design.color_yellow {
    background: rgb(250, 222, 42);
}

.builder-box-design.color_yellow .builder_content .button_content_box button {
    background-color: rgb(224, 54, 79);
    color: #fff;
}

.builder-box-design.color_black {
    background: #000;
}

.builder-box-design.color_black .builder_content .button_content_box button {
    background-color: rgb(224, 54, 79);
    color: #fff;
}

.builder-box-design.color_black .builder_content .tit_clor {
    color: #fff !important;
}

.builder-box-design.color_black .builder_content .des_class {
    color: #fff !important;
}

.builder-box-design.color_black .builder_content .social_item_list svg {
    color: #fff !important;
}

.builder-box-design.color_blue {
    background: linear-gradient(rgb(207, 224, 255) 0%, rgb(230, 254, 241) 100%);
}

.builder-box-design.color_blue .builder_content .button_content_box button {
    background-color: rgb(61, 58, 246);
    color: #fff;
}

.builder-box-design.goWHMr::-webkit-scrollbar-track {
    background-color: transparent;
}

.builder-box-design.goWHMr::-webkit-scrollbar {
    display: none;
    width: 0 !important;
}

.header-bg-img {
    max-width: 100%;
    height: 200px;
    width: 100%;
    object-fit: cover;
    border-radius: 8px;
}

.builder_content_header_next {
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    margin-top: -50px;
}

.user-set-profile {
    width: 100px;
    height: 100px;
    border-radius: 100%;
    object-fit: cover;
}


.Qwedr {
    border: 4px solid rgb(251, 212, 189);
    background-color: rgb(251, 212, 189);
}

.tit_clor {
    color: rgb(35, 35, 35);
    margin: 12px 0px;
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
}

.des_class {
    color: rgb(35, 35, 35);
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    margin: 0px;
    text-align: center;
}

.social_item_list {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 14px;
    margin: 16px 0px;
}

.social_item_list svg {
    width: 30px;
    height: 30px;
    color: rgb(35, 35, 35);
}

.color_bg_text {
    background: #fff;
}

.image_with_text_content {
    border-radius: 8px;
    text-align: center;
}

.image_text_content {
    padding: 14px;
}

.brand_img_set img {
    width: 300px !important;
    height: 300px !important;
    object-fit: cover;
    border-radius: 8px 8px 0px 0px;
}

.text_content_box {
    background-color: #ffffff;
    color: #000;
    border-radius: 8px;
    padding: 22px;
    /* text-align: center; */
    margin-bottom: 16px;
}

.link-btn-view {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 8px;
    background-color: rgb(44, 75, 255);
    color: white;
    padding: 20px;
    font-size: 15px;
    font-weight: 500;
    transition: all 0.2s ease 0s;
}

.bg_aqw {
    background-color: rgb(7, 134, 84);
    color: white;
}

.youtube_video_box iframe {
    width: 100%;
    border-radius: 8px;
    height: 250px;
}







/* Path: src\PageBuilder\RightSide.css */


.bulider_right.fOCfTk {
    display: flex;
    box-shadow: rgba(0, 0, 0, 0.15) -3px 4px 4px;
}

.jKiTgm {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    /* -webkit-box-align: center;
    align-items: center; */
}

/* .jKiTgm button {
    border-bottom: 30px solid rgb(228 228 228);
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    height: 0px;
    width: 100px;
    transform: rotate(90deg) translate(0px, -15px);
    background: transparent;
    border-top: none;
    position: absolute;
    cursor: pointer;
    z-index: 100;
} */

.jKiTgm button {
    transform: rotate(90deg) translate(0px, -15px);
    background: transparent;
    border-top: none;
    position: absolute;
    cursor: pointer;
    top: 27px;
    z-index: 100;
    border: none;
}


.jKiTgm button svg {
    transform: rotate(90deg);
}

.RightSide_fixed_bar {
    width: 95px;
    background-color: white;
    color: rgb(99, 99, 99);
}

ul.RightSide_fixed_bar_list {
    list-style: none;
    height: 100vh;
    position: relative;
    padding: 0;
}

.oALEn {
    height: 72px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 6px;
    background: #fff;
    border: none;
    width: 95px;
    font-size: 12px;
    position: relative;
    color: rgb(61, 61, 61);
}

.RightSide_fixed_bar_list_item_link.oALEn.nav-link:hover {
    background-color: rgb(245, 245, 245) !important;
    color: rgb(61, 61, 61) !important;
    border-radius: 0px;
}

.RightSide_fixed_bar_list_item_link.oALEn.nav-link.active {
    background-color: rgb(245, 245, 245) !important;
    color: rgb(61, 61, 61) !important;
    border-radius: 0px;
}

.oALEn svg {
    width: 20px;
    height: 20px;
}

.cFOcQM {
    width: 450px;
    background-color: rgb(245, 245, 245);
    overflow: auto;
    padding-bottom: 8px;
    position: relative;
}

.xEfrZ {
    width: 0px;
    background-color: rgb(245, 245, 245);
    overflow: auto;
    padding-bottom: 8px;
    position: relative;
}


.themes_sec_header {
    width: 100%;
    height: 60px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    color: rgb(61, 61, 61);
    flex-shrink: 0;
}

.themes_sec_header h3 {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
    margin-left: 16px;
}

.templates_list_item {
    max-width: 100%;
    height: 114px;
    border-radius: 6px;
    padding: 2px;
    border: none;
    cursor: pointer;
    box-shadow: transparent 0px 0px 0px 4px;
    background-color: transparent;
}

.ThumbnailContainer {
    width: 100%;
    height: 100%;
    border-radius: 6px;
    background: rgb(255, 255, 255);
    color: transparent;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    justify-content: space-evenly;
    border: 1px solid rgb(224, 224, 224);
}

.rounded-3.Thumbnail {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: white;
    border: 1px solid rgb(224, 224, 224);
}

.button-box {
    height: 12px;
    width: 56px;
    background: rgb(230, 179, 51);
    border-radius: 8px;
}

.video-box {
    height: 48px;
    width: 56px;
    background: #fff;
    border-radius: 4px;
}

.change_color {
    background: rgb(245, 245, 245);
}

.theme-gallery {
    min-height: 256px;
    max-height: 256px;
    padding: 8px;
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    flex-flow: wrap;
    gap: 16px;
    cursor: pointer;
}

.templates_list {
    margin: 0px 16px 16px;
    padding: 8px;
    background: #fff;
    border-radius: 8px;
    border: 1px solid rgb(179, 179, 179);
}

.dohZLT {
    width: 100%;
    height: 100%;
    border-radius: 6px;
    background: linear-gradient(175deg, rgb(255, 186, 229) 28%, rgba(255, 237, 79, 0) 106%);
    color: transparent;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    justify-content: space-evenly;
}

.JqLIB {
    height: 12px;
    width: 56px;
    background: rgb(35, 35, 35);
    border-radius: 8px;
}

.htmJFC {
    width: 100%;
    height: 100%;
    border-radius: 6px;
    background: rgb(211, 236, 218);
    color: transparent;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    justify-content: space-evenly;
}

.eHEwqA {
    height: 12px;
    width: 56px;
    background: rgb(7, 134, 84);
    border-radius: 8px;
}

.gqRGFY {
    width: 100%;
    height: 100%;
    border-radius: 6px;
    background: rgb(251, 212, 189);
    color: transparent;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    justify-content: space-evenly;
}

.bWgPiP {
    width: 100%;
    height: 100%;
    border-radius: 6px;
    background: rgb(250, 222, 42);
    color: transparent;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    justify-content: space-evenly;
}

.fqVEao {
    height: 12px;
    width: 56px;
    background: rgb(224, 54, 79);
    border-radius: 60px;
}

.kQamYq {
    height: 12px;
    width: 56px;
    background: rgb(239, 96, 14);
    border-radius: 20px;
}

.gKQzFr {
    width: 100%;
    height: 100%;
    border-radius: 6px;
    background: rgb(1, 1, 1);
    color: transparent;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    justify-content: space-evenly;
}

.iCFlgu {
    height: 12px;
    width: 56px;
    background: rgb(247, 32, 64);
    border-radius: 1000px;
}

.kQbHFv {
    width: 100%;
    height: 100%;
    border-radius: 6px;
    background: linear-gradient(rgb(207, 224, 255) 0%, rgb(230, 254, 241) 100%);
    color: transparent;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    justify-content: space-evenly;
}

.eqpOAJ {
    height: 12px;
    width: 56px;
    background: rgb(61, 58, 246);
    border-radius: 4px;
}

.bnqqfO {
    width: 100%;
    height: 100%;
    border-radius: 6px;
    background: rgb(39, 6, 86);
    color: transparent;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    justify-content: space-evenly;
}

.eXJCbl {
    height: 12px;
    width: 56px;
    background: rgb(129, 73, 207);
    border-radius: 4px;
}

.show_more {
    margin: 8px;
}

.set_btn_colored {
    background: white;
    margin: 0px 16px 16px;
    padding: 16px;
    border: 1px solid rgb(179, 179, 179);
    border-radius: 4px;
}

.set_btn_colored_inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.dPYltt {
    color: rgb(184, 184, 184);
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    cursor: inherit;
}

.jMPzpH label {
    margin-bottom: 16px;
    color: rgb(99, 99, 99);
}

.font_family_content {
    margin: 0px 16px 16px;
}

.select-font-list-item-inner {
    border: 1px solid #ccc;
    height: 100px;
    background: #fff;
    border-radius: 5px;
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    width: 100px;
}

.select-font-list-item-inner-text-title {
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    color: #000;
}

.select-font-list {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}




/*  tab third styling */

button.add-block-button-wrapper {
    background: rgb(255, 255, 255);
    width: 100%;
    border: none;
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 12px 0px;
    justify-content: space-between;
}

.add-block-btn-icon {
    width: 50px;
    height: 50px;
    background-color: #ef600e21;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    margin-right: 20px;
}

.add-block-btn-icon svg {
    width: 25px;
    height: 25px;
    fill: #ef600e;
    color: #ef600e;
}

.add-block-btn-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
}

.add-block-btn-text-title {
    font-size: 15px;
    line-height: 16px;
    font-weight: 600;
    margin-bottom: 8px;
    color: #000;
}

.right_arrow svg {
    color: #000;
}

.add-block-btn-text-desc {
    margin-bottom: 0px;
    font-size: 14px;
    line-height: 16px;
    color: rgb(99, 99, 99);
}


.cDIdp {
    width: 100%;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 0px 16px;
    margin: 20px 0px;
}

.cDIdp div {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.cDIdp div button {
    border: none;
    background-color: transparent;
    margin-right: 8px;
    cursor: pointer;
    display: flex;
}

.cDIdp div button svg {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
}

.ePxaWM {
    color: rgb(61, 61, 61);
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
}

.cvtPmc {
    background: white;
    margin: 0px 16px 16px;
    padding: 16px;
    border: 1px solid rgb(179, 179, 179);
    border-radius: 4px;
}


/* Dropdown.css */
.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-toggle {
    padding: 10px 20px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    cursor: pointer;
    outline: none;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    display: none;
    list-style: none;
    padding: 0;
    margin: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-top: none;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.dropdown-menu li {
    padding: 10px 20px;
    cursor: pointer;
}

.dropdown-menu li:hover {
    background-color: #f0f0f0;
}

.dropdown.active .dropdown-menu {
    display: block;
}

.iuEvSC {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    margin: 0px 16px 16px;
}

.dropdown .dropdown-toggle {
    color: rgb(99, 99, 99) !important;
    border: 1px solid rgb(179, 179, 179);
    border-radius: 4px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    background-color: #ffffff !important;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dropdown-menu.social_dropdown_menu.show {
    top: 47px;
}

.dropdown {
    width: 50%;
}

.dropdown-menu.social_dropdown_menu {
    width: 100%;
    left: 0px;
    max-height: 177px;
    overflow: auto;
    padding: 8px 0px;
}

.social_item_inner {
    display: flex;
    gap: 10px;
    align-items: center;
    color: rgb(99, 99, 99);
}

.social_item_text_title {
    font-size: 15px;
    font-weight: 600;
    line-height: 16px;
}

.social_dropdown_menu .drop_social_item {
    padding: 10px 10px;
}

.empty_block {
    background: rgb(255, 255, 255);
    border: 1px solid rgb(184, 184, 184);
    box-sizing: border-box;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px;
    border-radius: 5px;
    height: 145px;
    margin: 0px 16px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
}

.icons_list_item_icon {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}

.icons_list_left svg {
    width: 16px;
    height: 16px;
    fill: rgb(99, 99, 99);
    margin-right: 6px;
}

button.delete_btn {
    background: no-repeat;
    border: none;
    padding: 0;
    color: rgb(99, 99, 99);
}

.form_block {
    position: relative;
}

.form_block input {
    margin: 8px 0px;
    width: 100%;
    padding: 9px 8px 8px;
    border: 1px solid rgb(179, 179, 179);
    border-radius: 4px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    color: rgb(99, 99, 99);
}

.link_target {
    position: absolute;
    top: 7px;
    right: 5px;
}

.add_image_grid {
    width: 100%;
    text-align: center;
    background-color: rgb(255, 255, 255);
    color: rgb(99, 99, 99);
    height: 40px;
    border: 1px solid rgb(184, 184, 184);
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
}



.image-input-container {
    display: flex;
    gap: 10px;
}

.image-input {
    display: inline-block;
    cursor: pointer;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    text-align: center;
}

.icon {
    margin-right: 5px;
}

.text {
    color: #333;
}

.preview_image_box {
    width: 50px;
    height: 50px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: rgb(224, 224, 224);
    padding: 8px;
}

.preview_image_box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.image-input span.text {
    color: #333;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
}








/* tab2 css code start here  */

.block_list {
    display: flex;
    gap: 20px;
    align-items: center;
}

.block_image_icon {
    width: 40px;
    height: 40px;
    border-radius: 4px;
}

.block_image_icon img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
}

.block_name h5 {
    font-size: 15px;
    line-height: 16px;
    font-weight: 700;
    color: rgb(61, 61, 61);
    margin-right: 5px;
    margin-bottom: 0;
}

.edit-block-content {
    border: 1px solid rgb(224, 224, 224) !important;
    display: flex;
    justify-content: space-between;
    height: 64px;
    align-items: center;
}

.block_btn {
    display: none;
}

.block_btn .block_btn_item {
    padding: 0px;
    margin: 0px 5px;
    color: #333;
    border: none !important;
}

.block_btn .block_btn_item.active {
    color: #333;
    border: none;
    background: none;
}

.edit-block-content:hover .block_btn {
    display: block;
}

.block_svg_icon {
    width: 40px;
    height: 40px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(224, 224, 224);
}

.sociallink-block {
    background-color: rgb(1 200 207 / 10%);
}

.sociallink-block svg {
    width: 20px;
    height: 20px;
    fill: rgb(1, 200, 207);
}

.buttonlink-block {
    background-color: rgb(255 193 7 / 10%);
}

.buttonlink-block svg {
    width: 20px;
    height: 20px;
    fill: rgb(255, 193, 7);
}

.youtubevideo-block {
    background-color: rgb(255 0 0 / 10%);
}

.youtubevideo-block svg {
    width: 20px;
    height: 20px;
    fill: #f00;
}

.text-block {
    background-color: rgb(233 54 242 / 10%);
}

.text-block svg {
    width: 20px;
    height: 20px;
    fill: #e033f0;
}

.imagegrid-block {
    background-color: rgb(240 2 118 / 10%);
}

.imagegrid-block svg {
    width: 20px;
    height: 20px;
    fill: #f00276;
}

.subheadding-block {
    background-color: rgba(134, 195, 33, 0.1);
}

.subheadding-block svg {
    width: 20px;
    height: 20px;
    fill: #86c321;
}

.opicity-change {
    opacity: 0.5;
}

.block-link {
    cursor: grab;
    color: #333;
}

.block_icon svg {
    width: 20px;
    height: 20px;
    fill: #333;
}

.gvofes {
    color: rgb(61, 61, 61);
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    cursor: inherit;
}


.fcYUrQ {
    color: rgb(61, 61, 61);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.cJUVbA {
    margin: 0px 16px 8px;
    font-weight: 500;
    overflow: visible;
}

.header-format-title {
    display: inline-block;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    color: rgb(61, 61, 61);
    margin: 4px 0px 8px;
}

.header-format-item {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
}

.format-image-btn {
    background: rgb(245, 245, 245);
    border-radius: 6px;
    border: none;
    padding: 0 !important;
    /* box-shadow: rgb(172, 185, 253) 0px 0px 0px 4px; */
}

.format-image-btn img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.add-block-btn {
    margin: 0px 16px;
    text-align: end;
}


.image-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 10px;
}

.image-item {
    overflow: hidden;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.image-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.image-grid-custom {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.imagegrid-item img {
    width: 120px;
    height: 120px;
    object-fit: cover;
}

.button_content_box {
    margin-bottom: 16px;
    position: relative;
}

.small_favicon {
    position: absolute;
    top: 11px;
    left: 3%;
}

.small_favicon img {
    width: 40px;
    height: auto;
}

.text_content_box h2 {
    font-size: 24px;
    font-weight: 700;
}

.cGOoFD {
    height: 530px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 4px;
    background-repeat: no-repeat;
    background-position: center center;
    padding: 20px;
    background-size: cover;
    /* background-image: url(../assets/images/giphy-downsized.gif); */
}

.addBgclass .tit_clor {
    color: #ffffff !important;
}

.addBgclass .des_class {
    color: #ffffff;
}

.format-image-btn.active {
    box-shadow: rgb(172, 185, 253) 0px 0px 0px 4px;
}

.horizontal-header .des_class {
    text-align: left !important;
}


.mobile-hide {
    display: block;
}

.mobile-show {
    display: none;
}

.fix_button {
    position: fixed;
    bottom: 92px;
    right: 22px;
}

.edit_builder_btn {
    background-color: #f00276;
    color: #fff;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 1px solid #f00276;
}

.edit_builder_btn svg {
    width: 26px;
    height: 26px;
}

.page_builder_modal {
    z-index: 11111111111;
}

.page_builder_modal .modal-dialog {
    bottom: 0;
    position: fixed;
    margin: auto;
    height: 90%;
    width: 100%;
}

.page_builder_modal .modal-content {
    border-radius: 24px 24px 0px 0px;
    height: 100%;
    overflow-y: auto;
}

.builder_mobile_navigation {
    display: block;
    position: fixed;
    bottom: 0;
    width: -webkit-fill-available;
    right: 0;
    left: 0;
    transform: translate(10px, 15px);
    z-index: 111;
}

.builder_mobile_navigation .RightSide_fixed_bar {
    width: 95%;
}

.builder_mobile_navigation .RightSide_fixed_bar ul.RightSide_fixed_bar_list {
    display: flex;
    width: 100%;
    justify-content: space-between;
    height: auto;
}

.cFOcQM::-webkit-scrollbar {
    width: 3px;
}

.page_builder_modal .modal-content::-webkit-scrollbar {
    display: none;
}

.row002::-webkit-scrollbar {
    display: none;
}

.company_name {
    text-align: center;
    color: #000;
    margin-bottom: 10px;
    font-family: 'Public Sans', sans-serif !important;
}

.company_name h5 {
    font-size: 18px;
}

.builder-box-design.color_beige .company_name {
    color: #fff;
}

.builder-box-design.color_black .company_name {
    color: #fff;
}

.jKiTgm button img {
    transform: rotate(270deg);
}

.publish_qr_code img {
    width: 60px;
    height: 60px;
}

button.btn.qr-download {
    background: #2c4bff;
    color: #fff;
    margin-left: 9px;
    padding: 4px 8px;
}

.preview-box-background.view-mobile .page_builder_design_body {
    height: 566px;
    z-index: -1;
    border-left: 6px solid #fff;
    border-right: 6px solid #fff;
}

.preview-box-background.view-mobile .page_builder_design_body .builder-box-design {
    height: 100%;
    border-radius: 0;
    box-shadow: none;
}

.preview-box-background.view-mobile .page_builder_design_body .builder-box-design .imagegrid-item img {
    width: 95px;
    height: 95px;
    object-fit: cover;
}

.preview-box-background.view-tablet .page_builder_design_body {
    height: 740px;
    z-index: -1;
    border-left: 12px solid #fff;
    border-right: 12px solid #fff;
}

.preview-box-background.view-tablet .page_builder_design_body .builder-box-design {
    height: 100%;
    border-radius: 0;
    box-shadow: none;
    width: 100%;
}

.preview-box-background.view-desktop .page_builder_design_body {
    height: 740px;
    z-index: -1;
    border-left: 24px solid #fff;
    border-right: 24px solid #fff;
    background-color: #eeeeee;
}

.preview-box-background.view-desktop .page_builder_design_body .builder-box-design {
    height: 100%;
    border-radius: 0;
    box-shadow: none;
    width: 100%;
}

.preview-box-background .page_builder_design_body.color_pink {
    background-image: linear-gradient(180deg, #fff5fb 28%, #ff7fcf 106%);
}

.preview-box-background .page_builder_design_body.color_green {
    background-image: linear-gradient(180deg, #e1f2e6 28%, #81c995 106%);
}

.preview-box-background .page_builder_design_body.goWHMr {
    background-image: linear-gradient(180deg, #fbd4bd 28%, #f59861 106%);
}

.preview-box-background .page_builder_design_body.color_beige {
    background-image: linear-gradient(180deg, #f0e7fd 28%, #2f0768 106%);
}

.preview-box-background .page_builder_design_body.color_yellow {
    background-image: linear-gradient(180deg, #fef6c3 28%, #fbe450 106%);
}

.preview-box-background .page_builder_design_body.color_black {
    background-image: linear-gradient(180deg, #f0e7fd 28%, #000 106%);
}

.preview-box-background .page_builder_design_body.color_blue {
    background-image: linear-gradient(180deg, #e6fef1 28%, #d7ebfb 106%);
}

.home-preview-body {
    height: 100vh !important;
}

.xmpr {
    height: 90%;
}

.favicon_image img {
    width: 60px;
    height: auto;
}

.testimonial-card {
    background: #ffffff;
    padding: 16px 10px;
    border-radius: 10px;
    margin-bottom: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.testimonial-card-inner {
    display: flex;
    align-items: flex-start;
    gap: 16px;
}

.user-img-review img {
    width: 50px;
    height: auto;
    border-radius: 50%;
}

.review_name {
    font-size: 18px;
    font-weight: 700;
}

.testimonial_review {
    font-size: 14px;
    margin-top: 5px;
    font-style: italic;
    color: #5d586c;
    font-weight: 500;
}

.contact-card {
    background: #000;
    padding: 16px 10px;
    border-radius: 10px;
    margin-bottom: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.contact-us-title {
    margin-bottom: 20px;
    font-size: 28px;
    color: #fff;
    font-weight: 700;
}

.contact-details-text {
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.cont-xys {
    display: flex;
    gap: 14px;
    margin-bottom: 16px;
    align-items: baseline;
}

.cont-icon svg {
    color: #fade2a;
    width: 22px;
    height: 22px;
}

.builder-box-design.color_black .contact-card {
    background-color: #fff;
    color: #333;
}

.builder-box-design.color_black .contact-us-title,
.builder-box-design.color_black .contact-details-text {
    color: #333;
}

.builder_content_header_next {
    z-index: 2;
}

.cGOoFD {
    position: relative;
}

.cGOoFD::before {
    content: "";
    background-color: #000;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.5;
    position: absolute;
}

.event-type-btn {
    background: #dde9f8;
    border: none;
    padding: 5px 10px;
    color: #45408e;
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 4px;
    font-weight: 600;
}

.event-type {
    position: relative;
}

.event-type-dropdown {
    position: absolute;
    top: 40px;
    background: #fff;
    border: none;
    color: #45408e;
    border-radius: 5px;
    font-weight: 600;
    display: block;
    right: 0;
    z-index: 1;
    width: 240px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 4px;
    border-radius: 4px;
}

.event-type-dropdown ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
}

.event-type-dropdown ul li .event-dropdown-btn {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 8px 14px;
    cursor: pointer;
    background: transparent;
    border: none;
    text-align: start;
    color: #a4a4a4;
}

.event-dropdown-btn-text-title {
    color: #3d3d3d;
    font-weight: 600;
}

.event-type-dropdown ul li .event-dropdown-btn:hover {
    background: #dde9f8;
}

.event-dropdown-btn-icon svg {
    width: 18px;
    height: 18px;
}

.form_block_group {
    margin: 8px 0;
}

.create-btn-wrapper {
    margin-top: 28px;
}

button.create-btn {
    background: #b62e2e;
    border: 1px solid #b62e2e;
    color: #fff;
    width: 100%;
    font-weight: 600;
    border-radius: 6px;
    height: 45px;
}

.event-type-box {
    background: #ffffffe0;
    color: #fff;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 5px 10px;
    width: max-content;
    border-radius: 6px;
}

.event-type-text {
    font-weight: bold;
    background-image: linear-gradient(to right, #7367f6, #28c76f, #ea5455);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;
    font-size: 14px;
}

.event-type-icon svg {
    width: 15px;
    height: 15px;
    color: #b62e2e;
}

.event-title {
    margin: 14px 0 1.25rem;
}

.event-title h2 {
    font-size: 28px;
    font-weight: 700;
}

.date-icon {
    border: 1px solid#ccc;
    width: 3rem;
    height: 3rem;
    margin: 0.125rem 0;
    text-align: center;
    border-radius: 6px;
    overflow: hidden;
}

.month-name {
    background: rgb(42 60 112);
    font-weight: 500;
    text-transform: uppercase;
    padding: 2px;
    color: #fff;
    font-size: 10px;
}

.day-name {
    font-size: 16px;
    font-weight: bold;
}

.event-date {
    font-weight: 700;
    font-size: 16px;
}

.event-time {
    font-size: 14px;
    line-height: 15px;
}

.event-date-inner {
    display: flex;
    align-items: center !important;
    gap: 16px;
}

.date-icon svg {
    width: 26px;
    height: 26px;
}

.event-xyt {
    font-size: 17px;
    font-weight: 800;
    border-bottom: 1px solid rgba(255,255,255,0.08) !important;
    padding-bottom: 6px;
    margin-bottom: 16px;
}

.event-section {
    color: #000;
    padding: 16px;
    border-radius: 12px;
    background-color: white;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    background-color: rgba(158, 189, 199, 0.288);
}


.builder-box-design.color_beige .event-section, .builder-box-design.color_black .event-section {
    color: #fff;
}

.hosted-img img {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    object-fit: cover;
}

.host-left {
    display: flex;
    align-items: center;
    gap: 10px;
}

.hosted-name {
    font-size: 16px;
    font-weight: 600;
}

.total-people-img img {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    border: 2px solid #fff;
    margin: -6px;
}

.total-people-inner {
    display: flex;
    align-items: center;
}

.btn-event-page {
    background: transparent;
    border: none;
    padding: 0;
    opacity: 0.8;
    margin-bottom: 10px;
}

.registration-header {
    font-size: 0.875rem;
    font-weight: 500;
    background: #ffffff94;
    padding: calc(0.5rem - 1px) calc(1rem - 1px);
    border-radius: 5px 5px 0px 0px;
    margin: calc(-0.75rem + 1px) calc(-1rem + 1px);
    margin-bottom: 16px;
}

.Registration-sec {
    margin-top: 16px;
    border: 1px solid #e8f2f6;
    border-radius: 5px;
    padding: 0.70rem 0.9rem;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.content.jsx-3830652295 {
    gap: 0.75rem;
}

.jsx-1768947162.one {
    gap : 0.75rem;
}

.info-rows.one.jsx-1768947162 {
    margin: -0.5rem -1rem 0.25rem -1rem;
    padding: 0.5rem 1rem 0.75rem 1rem;
    border-bottom: 1px solid #e8f2f6;
}

.bg-avtar {
    width: 30px;
    height: 30px;
    border-radius: 1000px;
    background-position: center;
    background-size: cover;
    background-color: #ffffff;
    position: relative;
    background-image: url(../assets/images/avatar_34.avif);     
}

.one-click {
    background: #ffffff;
    border: 1px solid #e8f2f6;
    border-radius: 5px;
    margin-top: 12px;
}

/* page builder responsive css start here  */

@media (max-width: 440px) {
    .theme-gallery {
        grid-template-columns: repeat(3, 1fr);
    }

    .default-color-picker-list {
        display: flex;
        box-sizing: border-box;
        cursor: inherit;
        max-width: 600px;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 10px;
    }

    .default-color-picker-list-item {
        margin: 0 !important;
    }

}



@media (max-width: 767px) {
    .mobile-hide {
        display: none;
    }

    .mobile-show {
        display: block;
    }

    .cFOcQM {
        width: 100% !important;
        margin-bottom: 80px;
    }

    .builder-box-design {
        width: 100%;
    }

}

@media (min-width: 768px) and (max-width: 991px) {
    .builder-box-design {
        width: 85%;
    }

    .cFOcQM {
        width: 300px;
    }

    .theme-gallery {
        grid-template-columns: repeat(2, 1fr);
    }
}


@media (min-width: 992px) and (max-width: 1220px) {
    .cFOcQM {
        width: 300px;
    }

    .theme-gallery {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 992px) and (max-width: 1070px) {
    .builder-box-design {
        width: 100%;
    }
}

/* .__se__t-shadow {
    text-shadow: -.2rem -.2rem 1rem #fff, .2rem .2rem 1rem #fff, 0 0 .2rem #999, 0 0 .4rem #888, 0 0 .6rem #777, 0 0 .8rem #666, 0 0 1rem #555
} */