.file-preview-container {
    max-height: 300px;
    overflow-y: auto;
    margin-top: 1rem;
}

.file-list {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.file-preview-item {
    background-color: #f8f9fa;
    transition: background-color 0.2s ease;
}

.file-preview-item:hover {
    background-color: #e9ecef;
}

.preview-thumbnail {
    border-radius: 4px;
}