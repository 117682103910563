.remove-img-event {
    position: relative;
    margin: 5px;
    height: 110px;
    width: 110px;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
}

.remove-img-event img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
}

.remove-btn {
    position: absolute;
    top: -8px;
    right: -8px;
    background-color: #fff;
    color: #fd1111bf;
    border: 2px solid #fd1111bf;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: transform 0.2s ease, background-color 0.3s ease;
}

.remove-btn:hover {
    transform: scale(1.1);
    background-color: #fd1111bf;
    color: #fff;
}

.gallery {
    padding: 10px;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 8px;
}


.gallery-header {
    border-bottom: none;
    background: linear-gradient(90deg, #0b10e7 0%, #2adf63 100%);
    color: #fff;
    padding: 14px 22px;
    border-radius: 0;
}

.gallery-body-model {
    position: relative;
    flex: 1 1 auto;
}