.testimonial-slider {
  width: 100%;
  max-width: 400px;
  /* Adjust the max-width as needed for your design */
  margin: 0 auto;
  text-align: center;
}

.testimonial {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-bottom: 20px;
}

.testimonial-image {
  width: 100%;
  max-width: 200px;
  /* Adjust the max-width for the image as needed */
  border-radius: 50%;
  margin-bottom: 10px;
}

.comment {
  font-size: 14px;
  margin-bottom: 10px;
}

.name {
  font-size: 16px;
  font-weight: bold;
}

.controls {
  display: flex;
  justify-content: space-between;
}

.prevClick {
  float: left;
  margin-top: 150px;
  margin-left: -40px;
  border-radius: 50%;
  border: 1px solid #ccc;
}

.nextClick {
  float: right;
  margin-top: 150px;
  margin-right: -40px;
  border-radius: 50%;
  border: 1px solid #ccc;

}