.astral-ai-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
  }
  
  .astral-ai-button {
    padding: 10px 20px;
    border-radius: 50px;
    background-color: #4a90e2;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .astral-ai-chat {
    position: absolute;
    bottom: 50px;
    right: 0;
    width: 300px;
    height: 400px;
    background: #f9f9f9;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow-y: auto;
    padding: 10px;
  }
  