/* EventCard.css */

.event-card {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    width: 400px;
    margin: 20px auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.status-tag {
    background-color: #0073e6;
    color: white;
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 4px;
    display: inline-block;
    margin-bottom: 10px;
}

/* Dynamic status colors */
.status-tag.Upcoming {
    background-color: #28a745;
}

.status-tag.SoldOut {
    background-color: #dc3545;
}

.status-tag.LimitedSeats {
    background-color: #ffc107;
}

/* Form styles */
.event-card form label {
    display: block;
    font-weight: bold;
    margin: 10px 0 5px;
    color: #333;
}

.event-card form select,
form input {
    width: 100%;
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 10px;
    background-color: #fff;
}

/* Button styling */
.event-card form button {
    background-color: #0073e6;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
}

.event-card form button:hover {
    background-color: #005bb5;
}

/* Event information styling */
.event-info {
    margin-top: 20px;
}

.event-info h2 {
    font-size: 18px;
    color: #333;
    margin-bottom: 10px;
}

.event-info p {
    font-size: 14px;
    color: #555;
    margin: 5px 0;
}

/* Update Button Styling with Gradient */
.update-btn {
    background: linear-gradient(90deg, #1239ce, #28d46a);
    /* Gradient from left to right */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 15px;
    display: block;
    width: 30%;
    text-align: center;
    transition: background 0.3s ease;
    /* Smooth transition */
}

.update-btn:hover {
    background: linear-gradient(90deg, #28d46a, #1239ce);
    /* Reverse gradient on hover */
}
/* Responsiveness */
@media (max-width: 500px) {
    .event-card {
        width: 100%;
        padding: 15px;
    }
}