/* Default transition for hover animations */
.button-preview {
    transition: all 0.4s ease-in-out;
    /* Increased duration for smoother effect */
}

/* Grow effect */
.hover-grow:hover {
    transform: scale(1.1);
    /* Slightly larger scale for visible growth */
}

/* Shrink effect */
.hover-shrink:hover {
    transform: scale(0.9);
    /* Shrinks to 90% of the original size */
}

/* Shake effect */
@keyframes shake {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }

    10% {
        transform: translate(-1px, -2px) rotate(-1deg);
    }

    20% {
        transform: translate(-3px, 0px) rotate(1deg);
    }

    30% {
        transform: translate(3px, 2px) rotate(0deg);
    }

    40% {
        transform: translate(1px, -1px) rotate(1deg);
    }

    50% {
        transform: translate(-1px, 2px) rotate(-1deg);
    }

    60% {
        transform: translate(-3px, 1px) rotate(0deg);
    }

    70% {
        transform: translate(3px, 1px) rotate(-1deg);
    }

    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }

    90% {
        transform: translate(1px, 2px) rotate(0deg);
    }

    100% {
        transform: translate(1px, -2px) rotate(-1deg);
    }
}

.hover-shake:hover {
    animation: shake 1s ease-in-out;
    /* Increased duration for smoother shaking */
}

@keyframes fill-empty {
    0% {
        background-color: transparent;
    }

    100% {
        background-color: #4caf50;
    }
}

@keyframes grow-shrink {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes shake-animation {

    0%,
    100% {
        transform: translateX(0);
    }

    20%,
    60% {
        transform: translateX(-10px);
    }

    40%,
    80% {
        transform: translateX(10px);
    }
}

@keyframes pulsing {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.05);
    }

    100% {
        transform: scale(1);
    }
}

.hover-grow:hover {
    transform: scale(1.1);
    transition: transform 0.2s;
}

.hover-shake:hover {
    animation: shake-animation 0.5s;
}

.animation-pulsing {
    animation: pulsing 1.5s infinite;
}

.animation-shaking {
    animation: shake-animation 0.5s;
}


@keyframes shake-animation {

    0%,
    100% {
        transform: translateX(0);
    }

    20%,
    60% {
        transform: translateX(-10px);
    }

    40%,
    80% {
        transform: translateX(10px);
    }
}

.animation-shaking {
    animation: shake-animation 0.5s;
}


/* Fill/Empty Animation */
@keyframes fill-empty-animation {
    0% {
        background-color: transparent;
    }

    100% {
        background-color: #ff6347;
        /* Example fill color */
    }
}

.animation-fill-empty:hover {
    animation: fill-empty-animation 0.5s forwards;
}

/* Grow/Shrink Animation */
@keyframes grow-shrink-animation {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(1.1);
    }
}

.animation-grow-shrink:hover {
    animation: grow-shrink-animation 0.2s forwards;
}

/* Shake Animation */
@keyframes shake-animation {

    0%,
    100% {
        transform: translateX(0);
    }

    20%,
    60% {
        transform: translateX(-10px);
    }

    40%,
    80% {
        transform: translateX(10px);
    }
}

.animation-shaking:hover {
    animation: shake-animation 0.5s;
}