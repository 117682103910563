.comments-container {
  margin: 10px auto;
  padding: 20px;
  border-radius: 10px;
  /* background-color: #f9f9f9; */
  overflow-y: auto; 
}

.comments-container h2 {
  margin-top: 0;
  font-size: 24px;
  text-align: center;
  color: #333;
}
.read-more {
  cursor: pointer;
  color: #007bff;
  display: block;
  margin-top: 5px;
}


.comment-item {
  padding: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ddd;
}

.comment-item:last-child {
  border-bottom: none;
  width: 615px;
}

.comment-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.comment-header strong {
  font-size: 16px;
  color: #444;
}

.comment-header span {
  font-size: 12px;
  color: #888;
}

.comment-text {
  margin-bottom: 10px;
  color: #555;
  overflow-wrap: break-word; 
  white-space: pre-wrap; 
  word-break: break-word; 
}

.comment-actions {
  display: flex;
  gap: 10px;
  width: 100%;
}

.comment-actions button {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  padding: 5px;
  font-size: 14px;
}

.comment-actions button:hover {
  text-decoration: underline;
}

.comment-reply-list {
  padding-left: 20px;
  margin-top: 10px;
  border-left: 2px solid #eee;
}

.comment-form {
  margin-top: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
}

.comment-form textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: vertical; 
  font-size: 14px;
  max-height: 300px; 
  overflow-y: auto;
  position: relative;
}

.comment-form button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #21a885;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 14px;
  cursor: pointer;
  align-self: flex-end;
  position: absolute;
  right: 15px;
  bottom: 15px;
}

.comment-form button:hover {
  background-color: #5ad4dd;
}
.comment-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-between;
}

.user-info {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.profile-image {
  width: 40px; /* Adjust size as needed */
  height: 40px; /* Adjust size as needed */
  border-radius: 50%;
  margin-right: 10px;
}

.user-details {
  display: flex;
  flex-direction: column;
}

.username {
  font-size: 16px;
  color: #444;
}

.email {
  font-size: 12px;
  color: #888;
}

.date {
  font-size: 12px;
  color: #888;
}
.comments-container {
  margin: 10px auto;
  padding: 20px;
  border-radius: 10px;
  overflow: hidden; /* Prevent overflow in the container */
  position: relative; /* Enable absolute positioning for children */
}

.comments-list {
  max-height: 900px; /* Fixed height for the comments list */
  overflow-y: auto; /* Only this part will scroll */
  margin-bottom: 60px; /* Leave space for the comment form */
}

.comment-form {
  position: absolute; /* Fixed position for the form */
  bottom: 0px; /* Adjust distance from bottom */
  left: 20px; /* Adjust as needed */
  right: 20px; /* Adjust as needed */
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.comment-form textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: vertical; 
  font-size: 14px;
}

.comment-form button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #21a885;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 14px;
  cursor: pointer;
}

.comment-form button:hover {
  background-color: #5ad4dd;
}


.comments-container {
  margin: 10px auto;
  padding: 20px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  width: 681px;
}