.header-icon {
    font-size: 24px;
    margin-right: 8px !important;
}

h5.main-card-title {
    display: flex;
    align-items: center;
    font-weight: 600;
}

.Domain-table {
    margin-top: 20px;
    width: 100%;
}

.Domain-table ul {
    list-style: none;
    padding: 0px;
}

.channel-name-list {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
}

.Domain-table ul li {
    border-radius: 6px;
    box-sizing: border-box;
    padding: 25px;
    margin-bottom: 16px;
    box-shadow: 0px 4px 18px rgba(75, 70, 92, 0.1);
    border-radius: 6px;
    background-color: #fff;
}

.Domain-table-status h5 {
    margin-bottom: 0px;
    font-size: 15px;
    font-weight: 600;
    color: #28C76F;
    background-color: #ddffec;
    padding: 2px 8px;
    border-radius: 4px;
    margin-right: 12px;
}

.Domain-table-content h4 {
    font-size: 20px;
    font-weight: 600;
    color: #6D687A;
}

.Domain-table-content h5 {
    font-size: 15px;
    font-weight: 600;
    color: #6D687A;
}

.Domain-table-content h6 {
    margin-left: 20px;
    margin-bottom: auto;
}

.temp-table-content {
    flex-direction: row !important;
}

.temp-table-content h6 {
    margin-left: 20px;
    margin-bottom: auto;
}

.Instructions-content p span {
    font-weight: 600;
}

.Instructions-content {
    color: #5D586C;
}

.dflex003 {
    display: flex;
    align-items: center;
}

@media (max-width: 1199.99px) {
    .temp-table-content h6 {
        margin-left: 0px;
        margin-bottom: 0px;
    }
}

@media (max-width: 991.99px) {
    .temp-table-content {
        flex-direction: column !important;
    }
}

@media (max-width: 767.99px) {
    .Domain-table ul li {
        padding: 24px 10px;
        overflow-x: auto;
    }

    .dflex003 {
        flex-direction: column;
        align-items: flex-start;
    }

    .Domain-table-content h6 {
        margin-left: 0px;
    }

    h5.main-card-title {
        margin-left: 10px;
    }
}

@media (max-width: 575.99px) {
    h5.main-card-title {
        margin-left: 10px;
    }
}

@media (max-width: 479.98px) {
    h5.main-card-title {
        margin-left: 0px;
    }
}
