.event-dot {
    color: rgb(206, 43, 43) !important;
    border-radius: 50%;
}

.react-calendar {
    width: 490px;
    max-width: 100%;
    height: 310px;
    padding: 10px 5px !important;
    background-repeat: initial;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
    background: transparent !important;
    border: none !important;
    border-radius: 10px;
}

.react-calendar__month-view__days__day--weekend {
    color: #7e869b;
}

.react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
    color: #7e869b;
}

.react-calendar button {
    outline-color: initial;
    outline-style: none;
    outline-width: initial;
    margin: 0px;
    border: 0px;
    color: #7e869b;
}

.react-calendar__tile--active {
    background-repeat: initial;
    color: white;
    background: rgb(23, 118, 206);
    border-radius: 50%;
}

.react-calendar__tile--active:enabled {
    background-color: aqua;
    padding: 5px 10px;
}

.react-calendar__tile--now {
    background-repeat: initial;
    background: transparent;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile {
    background-color: transparent;
}

.react-calendar__tile:disabled {
    background-color: transparent;
    color: #7e869b;
    pointer-events: none;
}

.react-calendar button :disabled {
    background-color: transparent;
    color: #7e869b;
    pointer-events: none;
}



.specific-area [type=button]:not(:disabled),
.specific-area [type=reset]:not(:disabled),
.specific-area [type=submit]:not(:disabled),
.specific-area button:not(:disabled) {
    cursor: pointer;
    color: white;
}



@media (max-width: 992px) {
    .brand-container.event-info-calendar {
        position: relative;
        width: 100%;
    }

    .brand-container.tab-content {
        position: relative;
        margin-top: 20px;
        width: 100%;
    }

    .brand-container.tabs-container {
        display: flex;
        justify-content: space-around;
        margin-top: 20px;
    }
}

@media (max-width: 768px) {
    .brand-container.event-info-calendar {
        width: 100%;
        padding: 0 15px;
    }

    .brand-container.tab-content {
        width: 100%;
        text-align: center;
    }
}