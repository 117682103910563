:root {
    --100vh: 100vh;
}

.container_grid {
    display: grid;
    padding: 20px;
}

.container_grid.sidebar_box {
    grid-template-columns: 1fr 400px;
}

 
.auto_brand_card {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: flex-start;
    overflow: hidden;
    width: 100%;
}


.auto_brand_card_body {
    background: #fff;
    box-shadow: 0 12px 50px 26px rgba(0, 0, 0, .03);
    height: 100%;
    overflow: hidden;
}

.auto_brand_card_body {
    align-items: center;
    border-radius: 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: flex-start;
    max-height: calc(var(--100vh) - 40px);
    width: 100%;
}

.back_logo img {
    width: 120px;
    height: auto;
}

.back_icon svg {
    width: 18px;
    height: 18px;
}

.back_icon {
    position: relative;
}

.back_icon::after {
    content: "";
    display: block;
    width: 3px;
    height: 100%;
    background: #f4f3f6;
    right: -9px;
    position: absolute;
    top: 0;
}

.back-btn-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.auto_brand_header {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 40px;
}

.chat_body {
    height: calc(var(--100vh) - 75px);
    margin-top: -50px;
    max-width: 590px;
    width: calc(100% - 40px);
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.app__base {
    container-name: chat;
    container-type: inline-size;
    height: calc(var(--100vh) - 145px);
    overflow-x: visible;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.ai_chat {
    -ms-overflow-style: none;
    max-height: calc(100% - 50px);
    overflow-x: visible;
    overflow-y: auto;
    overflow-y: scroll;
    padding: 20px 0 120px 0;
    scrollbar-width: none;
}

.ai_chat_box {
    background: #f8f8fa;
    border-radius: 20px;
    min-height: 67px;
    transition: height .2s cubic-bezier(.33, 1, .68, 1);
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: 10px;
    padding: 21px 24px;
}

.D1N9DI {
    background: url("../assets/images/bot.png") no-repeat;
    height: 30px;
    margin-right: 12px;
    margin-top: -5px;
    min-width: 30px;
    background-size: cover;
}

.chat__message__dark {
    font-weight: 800;
}

.chat__messages {
    display: flex;
    flex-direction: column;
    gap: 8px;
    line-height: 24px;
}

.chat__input {
    display: flex;
    gap: 0;
    flex-direction: column;
    /* background-color: #ffffff; */
}

.chat__input__input {
    position: relative;
}

.chat__input__input .chat-input {
    background: #fff;
    border-radius: 20px;
    border: 1px solid #0B0FE8;
    outline: none;
    padding: 10px 20px;
    resize: none;
    height: 55px;
    width: 100%;
}

.chat__input__button {
    position: absolute;
    top: 5px;
    right: 10px;
}

.chat__input__button__send {
    color: #ffffff;
    background-image: linear-gradient(45deg, #0e23e4, #2adb67);
    border: none;
    height: 44px;
    width: 44px;
    border-radius: 10px;
}

.user-chat {
    flex-grow: 1;
}

.user_chat_box {
    display: flex;
    gap: 10px;
    padding: 30px 24px;
}

.user-profile img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
}

.w0Ne1v {
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    background: hsla(0, 0%, 100%, .35);
    border-radius: 30px;
    box-shadow: 0 12px 50px 26px rgba(0, 0, 0, .03);
    box-sizing: border-box;
    height: calc(var(--100vh) - 40px);
    margin-left: 20px;
    overflow: auto;
    padding: 46px 30px;
}

.mini_title {
    font-size: 18px;
    color: #0B0FE8;
    margin-bottom: 16px;
    font-weight: 700;
}

.title {
    font-size: 24px;
    font-weight: 700;
}

.blank {
    height: 20px;
}

.loader_gif img {
    width: 135px;
    height: auto;
    white-space: nowrap;
    overflow: hidden;
}

.effect-16 {
    border: 0;
    padding: 4px 0;
    border-bottom: 1px solid #0B0FE8;
    background-color: transparent;
    width: 100%;
}

.effect-16~.focus-border {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-color: #0B0FE8;
    transition: 0.4s;
}

.effect-16:focus~.focus-border,
.has-content.effect-16~.focus-border {
    width: 100%;
    transition: 0.4s;
}

.effect-16~label {
    position: absolute;
    left: 0;
    width: 100%;
    top: -18px;
    color: #aaa;
    transition: 0.3s;
    z-index: -1;
    letter-spacing: 0.5px;
    top: -20px;
    font-size: 12px;
    color: #0B0FE8;
    transition: 0.3s;
}

.effect-16:focus~label,
.effect-16:focus-visible~label {
    top: -20px;
    font-size: 12px;
    color: #0B0FE8;
    transition: 0.3s;
}

.effect-16:focus-visible {
    outline: none;
}

.input-effect {
    position: relative;
    margin-top: 18px;
}

.focus-border-textarea {
    position: absolute;
    bottom: 6px;
    left: 0;
    width: 0;
    height: 2px;
    background-color: #0B0FE8;
    transition: 0.4s;
    font-size: 12px;
    color: #0B0FE8;
    transition: 0.3s;
}

.effect-16:focus~.focus-border-textarea,
.has-content.effect-16~.focus-border-textarea {
    width: 100%;
    transition: 0.4s;
}

.effect-16 .rbt-input-multi {
    border: 0;
    padding: 4px 0;
    background-color: transparent;
    width: 100%;
}

.w0Ne1v::-webkit-scrollbar {
    width: 4px; /* Adjust the width of the scrollbar */
    height: 4px; /* Adjust the height of the scrollbar */
}

.w0Ne1v::-webkit-scrollbar-track {
    background: #f4f3f6;
    -webkit-box-shadow: inset 0 0 6px rgb(244 243 246);
}

.w0Ne1v::-webkit-scrollbar-thumb {
    background: #f4f3f6; /* Choose a handle color */
    border-radius: 10px; /* Optionally round the corners */
}

.w0Ne1v::-webkit-scrollbar-thumb:hover {
    background: #ccc; /* Choose a handle hover color */
}

.w0Ne1v:hover::-webkit-scrollbar-thumb {
    background: #ccc; /* Choose a handle hover color */
}

.image_list_logos img {
    width: 100%;
    height: auto; 
    margin: 1rem;
    padding: 1rem;
}

.scrollable-section {
    height: 400px;
    /* Set a fixed height */
    overflow-y: auto;
    /* Enable vertical scrolling */
    overflow-x: hidden;
    /* Hide horizontal scrollbar if not needed */
}

.scrollable-section::-webkit-scrollbar {
    width: 10px;
}

.scrollable-section::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.scrollable-section::-webkit-scrollbar-thumb {
    background: #888;
}

.scrollable-section::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.scrollable-section {
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;
}

.scrollable-section {
    -webkit-overflow-scrolling: touch;
}

.blah_blah{
    border: 2px solid;
    border-radius: 20%;
    border-style: dashed;
    border-color: rgb(59, 78, 248);
}

.image_list_logos:hover{
    filter: drop-shadow(0 0 2em rgb(68, 226, 120))
}

.success-msg-box {
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 10px;
    padding: 18px;
    border-left: 4px solid #3a933a;
}

.success-msg-inner {
    display: flex;
    align-items: baseline;
    gap: 16px;
}

.success-msg-icon svg {
    width: 24px;
    height: 24px;
    color: #3a933a;
}

.success-msg-bold {
    font-weight: 700;
    color: #3a933a;
    font-size: 18px;
}