.footer-sec {
    background-color: #771E62;
    padding: 50px 0px;
}

.footer-inner {
    text-align: center;
    color: #fff !important;
}

.footer-inner a {
    color: #fff;
}

.contact {
    padding: 6px 0px;
}

.footer-logo {
    margin-bottom: 24px;
}

.footer-bottom {
    margin-top: 40px;
}

.footer-bottom-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
}

.social-media-icons-list {
    display: flex;
}

.footer-icon-item a {
    background-color: #fff;
    color: #771E62;
    margin-left: 10px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    transition: 0.4s ease-in-out;
}

.footer-icon-item a:hover {
    background-color: #771E62;
    color: #fff;
    font-size: 32px;
}

.copyright,
.footer-text,
.contact a,
.support a {
    font-size: 18px;
    font-weight: 600;
}

a {
    text-decoration: none;
}

.footer-logo a {
    position: relative;
}

.footer-logo a::before {
    content: "";
    width: 0%;
    position: absolute;
    bottom: -20px;
    left: 0;
    height: 2px;
    background-color: #fff;
    transition: 0.4s;
}

.footer-logo a:hover::before {
    width: 100%;
}


@media screen and (max-width: 768px) {
    .footer-bottom-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #fff;
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }

    .copyright {
        margin-top: 14px;
        text-align: center;
    }

    .footer-bottom {
        margin-top: 30px;
    }

    .footer-end {
        display: flex;
        gap: 10px;
        align-items: center;
        margin-top: 50px;
        flex-wrap: wrap;
        justify-content: center;
    }
}